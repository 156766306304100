import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import bnnerr from '../../assets/images/raisebanner.jpg';

const BannerRaise = () => {
  return (
    <>
      <div className="mrgntp-hdr"></div>
      <div className="relbox">
        <div
          className="full-w bnnr-box bluebg-sqr py-5"
          style={{ backgroundImage: `url(${bnnerr})`, height: "90vh"}}
        >
          <Container style={{top: "35%", position: "relative"}}>
          <div className="d-flex relbox">
            <div className="bnr-hd  align-self-center text-white">
              <h2>Take your business to new heights</h2>
              <p>
                Immerse yourself in a wide network of Investors, Incubators and
                Advisors. All ready to help you throughout your fundraising
                journey
              </p>
              <Link
              to="https://auth.v2.roundone.ph/registration"
              style={{ textDecoration: 'none' }}
            >
              {/* <Button
                type="button"
                className="semi-bradius bg-light blu-t font-weight-bold px-2 py-1 border-0"
              >
                Apply to Raise
              </Button> */}
              <Button
                type="button"
                className="btn signbtn "
              >
                Apply to Raise
              </Button>
            </Link>
            </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default BannerRaise;

import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { useDispatch, useSelector } from 'react-redux';
import CampaignCard from 'components/common/CampaignCards';
import { useSearchParams } from 'react-router-dom';

import carouselimg from '../../assets/images/sample1.png';
import { getCampaign, getEducation } from 'store/reducers/home';
import NotFound from 'components/common/NotFound';
import _ from 'lodash';

const MarketLocation = () => {
  const { campaign } = useSelector((state) => state.home);
  let [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';

  useEffect(() => {
    dispatch(getCampaign(page, search));
  }, [searchParams]);

  useEffect(() => {
    dispatch(getEducation());
  }, []);

  const handleChangePage = (page) => {
    setSearchParams({ page: page });
    dispatch(getCampaign(page));
  };

  return (
    <>
      <Container>
        <div className="py-4">
          <small></small>
          <Row className="py-3">
            {_.isEmpty(campaign?.campaigns?.data) ? (
              <NotFound />
            ) : (
              campaign?.campaigns?.data?.map((data, key) => (
                <Col lg={4} md={4} sm={12} className="mb-4" key={key}>
                  <div className="carouselbx fulheight mx-3 p-3">
                    <CampaignCard data={data} />
                  </div>
                </Col>
              ))
            )}
          </Row>
          {!_.isEmpty(campaign?.campaigns?.data) && (
            <div className="mt-4">
              <div className="py-3">
                <span className="text-center">
                  {page} of {Math.ceil(campaign?.campaigns?.totalCount / 6)}
                </span>
              </div>
              <PaginationControl
                page={page}
                between={4}
                total={campaign?.campaigns?.totalCount}
                limit={6}
                changePage={(page) => handleChangePage(page)}
                ellipsis={1}
              />
            </div>
          )}
          {/* <div className='d-flex justify-content-center my-5'>
                        <Button type="button" className="blu-btn mx-2">View More</Button>
                        <Button type="button" className="blu-btn mx-2">Load All</Button>
                    </div> */}
        </div>
      </Container>
    </>
  );
};

export default MarketLocation;

import React from 'react';
import { Link } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

const ShareButton = ({ link }) => {
  const notify = () =>
    toast('Copied link to clipboard!', {
      icon: <i className="fas fa-share-alt text-primary mx-2" />,
    });
  return (
    <div className="d-flex justify-content-end top-icons-caro mt-2  ">
      <CopyToClipboard
        text={`${window.location.hostname}${link}`}
        onCopy={notify}
      >
        <Link href="#" className="shr-hvr">
          <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
        </Link>
      </CopyToClipboard>
    </div>
  );
};

export default ShareButton;

import React from 'react';

import { Row, Col, Image } from 'react-bootstrap';

import iconnone from '../../assets/images/newIcons/R1 icons new_flag.svg';
import iconntwo from '../../assets/images/newIcons/R1 icons new_bulb.svg';
import iconnthree from '../../assets/images/newIcons/R1 icons new_invest.svg';
import iconnfour from '../../assets/images/newIcons/R1 icons new_stairs.svg';
import iconnfive from '../../assets/images/newIcons/R1 icons new_gear.svg';
import iconnsix from '../../assets/images/newIcons/R1 icons new_conncet investors.svg';
import iconnsev from '../../assets/images/newIcons/R1 icons new_lock.svg';
import iconneig from '../../assets/images/newIcons/R1 icons new_envelope.svg';

const InvesmentsPlatform = () => {
    return (
        <div className='bg-white combinebox'>
            <div className='py-5'>
                <h2 className='hd-titles text-center'>A Startup & SME Investments Platform</h2>
                <p className='bleuc'>
                    Explore and directly invest in exciting companies from all over the country
                </p>
            </div>
            <Row className="layer-col py-3">
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnone} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Ready for Round One?</h3>
                    </div>
                    <p className="small">
                        Share in the vision of Filipino entrepreneurs by becoming an investor. Fund, support, and enable success today.
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconntwo} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Discover</h3>
                    </div>
                    <p className="small">
                        Find compelling growth stories and ventures, cutting-edge technology, or social entrepreneurship projects.
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnthree} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Invest</h3>
                    </div>
                    <p className="small">
                        Course campaign funds through a secure investment portfolio managed and safekept by our partner financial institutions.
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnfour} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Elevate</h3>
                    </div>
                    <p className="small">
                        Be at the forefront of nation-building by directly investing in the drivers of our economy and connecting to a dynamic business community
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnfive} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Understand the Investment Process</h3>
                    </div>
                    <p className="small">
                        Access a wealth of knowledge on our platform
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnsix} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Connect with Your Investments</h3>
                    </div>
                    <p className="small">
                        Participate in the discussion by reaching out and getting to know the teams behind the campaign
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconnsev} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Due Diligence</h3>
                    </div>
                    <p className="small">
                        Easily access information on fundraisers so you can make an informed investment decision
                    </p>
                </Col>
                <Col lg={3} md={3} sm={12} className="bx-ly">
                    <div className="top-insd-layer insd-img">
                        <Image src={iconneig} alt="" className='mb-2' />
                        <h3 className='font-weight-bold py-3'>Business Updates</h3>
                    </div>
                    <p className="small">
                        Stay tuned in as a shareholder, monitor investments, and recieve updates from companies you've invested in."
                    </p>
                </Col>
            </Row>
        </div>
    )
}

export default InvesmentsPlatform
import React, {useEffect} from 'react';
import MainLayout from 'layout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import CampaignHighLights from './CampaignHighLights';
import IntroBusiness from './IntroBusiness';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';
import OtherFundraiser from './OtherFundraiser';
import { getCampaign } from 'store/reducers/home';
import { getCampaignContent } from 'store/reducers/content';

const IssuerProfilePage = () => {
    let { guid } = useParams();
    const { pathname } = useLocation();

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getCampaign());
      dispatch(getCampaignContent(guid));
    }, [pathname]);

    return (
        <>
             
                <div className='mrgntp-hdr'></div>
                <CampaignHighLights />
                <IntroBusiness />
                <OtherFundraiser />
                <SubscribeNewsletter />
             
        </>
    )
}

export default IssuerProfilePage

import React from 'react';
import { Link } from 'react-router-dom';

import hsbg from '../../assets/images/raise-luhys-photo.jpg';
import { Container, Row, Col, Button } from 'react-bootstrap';

const HelpSucceed = () => {
  return (
    <>
      <div className="bgbox-hs" style={{ backgroundImage: `url(${hsbg})` }}>
        <Container className="py-5">
          <Row>
            <Col lg={6} md={6} sm={12}></Col>
            <Col lg={6} md={6} sm={12}>
              <div className="left-alignbx whitebg">
                <h3 className="hd-colr m-0">Let us help you succeed!</h3>
                <p className="py-3">
                  Our experienced team is here to support you throughout your
                  journey: from creating your campaign to promoting and
                  documenting it. Your dedicated investment associate will guide
                  you through the process and provide you with the right tools
                  for a successful campaign.
                </p>
                <p className="font-weight-bold">What are you waiting for?</p>
                <Link
                  to="https://auth.v2.roundone.ph/registration"
                  style={{ textDecoration: 'none' }}
                >
                  {/* <Button type="button" className="vmblu-btn">
                    Raise Now
                  </Button> */}
                  <Button type="button" className="btn signbtn">
                    Raise Now
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HelpSucceed;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import {
  requestCampaignContent,
  requestEventContent,
  requestNewsUpdate,
  requestEducationalContent,
  requestFaqsTopic,
  requestIssuerDetails,
  requestInvestmentSubs,
} from 'services/api/content';

const initialState = {
  content: [],
  issuer: [],
  subscription: [],
  fetching: false,
};

const content = createSlice({
  name: 'content',
  initialState,
  reducers: {
    initContent: (state) => {
      state.fetching = true;
      state.content = [];
      state.issuer = [];
      state.error = null;
    },
    successContent: (state, { payload }) => {
      state.content = payload;
      state.fetching = false;
      state.error = null;
    },
    successCampaign: (state, { payload }) => {
      state.issuer = payload.issuer;
      state.subscription = payload.subscription;
      state.fetching = false;
      state.error = null;
    },
    errorContent: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
  },
});

export const { initContent, successContent, successCampaign, errorContent } =
  content.actions;

export const getEventContent = (guid) => async (dispatch) => {
  dispatch(initContent());

  const { success, data, message } = await requestEventContent(guid);

  if (success) {
    dispatch(successContent(data));
  } else {
    dispatch(errorContent(message));
  }
};

export const getNewsContent = (guid) => async (dispatch) => {
  dispatch(initContent());

  const { success, data, message } = await requestNewsUpdate(guid);

  if (success) {
    dispatch(successContent(data));
  } else {
    dispatch(errorContent(message));
  }
};

export const getCampaignContent = (guid) => async (dispatch) => {
  dispatch(initContent());

  const { success, data, message } = await requestCampaignContent(guid);

  const issuerDetails = await requestIssuerDetails(data?.issuerAccountGuid);

  const subscriptionDetails = await requestInvestmentSubs(guid);

  if (success) {
    dispatch(successContent(data));
    dispatch(
      successCampaign({
        issuer: issuerDetails?.data,
        subscription: subscriptionDetails?.data,
      })
    );
  } else {
    dispatch(errorContent(message));
  }
};

export const getEducationalContent = (guid) => async (dispatch) => {
  dispatch(initContent());

  const { success, data, message } = await requestEducationalContent(guid);

  if (success) {
    dispatch(successContent(data));
  } else {
    dispatch(errorContent(message));
  }
};

export const getFaqsTopicContent = (guid) => async (dispatch) => {
  dispatch(initContent());

  const { success, data, message } = await requestFaqsTopic(guid);

  if (success) {
    dispatch(successContent(data));
  } else {
    dispatch(errorContent(message));
  }
};

export default content.reducer;

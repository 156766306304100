import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import _ from 'lodash';
import Binocular from '../../assets/images/newIcons/Binocular.png';
// import dot from '../../assets/images/dottedblue.png';
// import yellowcrv from '../../assets/images/pattern-graphic-yellow.png';
import phoneImg from '../../assets/images/news1.jpg';
import phoneImg2 from '../../assets/images/news2.jpg';
import corporate from '../../assets/images/corporate-people.jpg';

const RoundOneNews = () => {
  const navigate = useNavigate();
  const { news } = useSelector((state) => state.home);
  const [search, setSearch] = useState('');

  return (
    <div className="ronbgbx relbox">
      {/* <Image src={dot} alt="" className="dotblue" />
            <Image src={yellowcrv} alt="" className="ylw-graphic-two" /> */}
      <Container>
        <h2 className="hdsf-titles text-primary text-center pt-5 m-0">
          Round One News
        </h2>
        <div className="form-group ron-search py-5">
          <span className="fa fa-search form-control-feedback"></span>
          <input
            type="text"
            className="form-control"
            placeholder="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                return navigate(`/news?page=1&search=${search}`);
              }
            }}
          />
        </div>
        {_.isEmpty(news) ? (
          <div>
            <div className="py-5">
              <img src={Binocular} />
              <p className="font-weight-bold">
                There’s nothing to see here yet!
              </p>
            </div>
          </div>
        ) : (
          news?.map((data, key) =>
            key === 0 ? (
              <div className="bg-insd-ron mb-5 p-3" key={key}>
                <div
                  className="imgtop-ron"
                  style={{
                    backgroundImage: `url(${_.first(
                      data?.newUpdatesFiles
                    )?.s3FileName.replace(/\s/g, '%20')})`,
                  }}
                >
                  <div className="d-flex justify-content-end top-icons-caro m-3 ">
                    <Link href="#" className="shr-hvr">
                      <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                    </Link>
                  </div>
                </div>
                <div className="p-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <h5 className="blutwo m-0">{data?.title}</h5>
                  </div>
                  <div className="d-flex justify-content-center align-items-center">
                    <small className="font-weight-bold p-1">
                      by {data?.createdBy}
                    </small>
                    <small className="p-1">
                      {moment(data?.createdOn).format('MMMM Do YYYY')}
                    </small>
                  </div>
                  <p className="text-left txt-clamp">
                    {data?.content?.replace(/<[^>]+>/g, '')}
                  </p>
                  <Link
                    to={`/news/${data?.guid}`}
                    className="blu-t btn btn-link"
                  >
                    <small className="blutwo font-weight-bold">READ MORE</small>
                  </Link>
                </div>
              </div>
            ) : (
              <Row className="text-left bgnws mb-4" key={key}>
                <Col lg={3} md={3} sm={12}>
                  <div
                    className="left-imgnws my-3"
                    style={{
                      backgroundImage: `url(${_.first(
                        data?.newUpdatesFiles
                      )?.s3FileName.replace(/\s/g, '%20')})`,
                    }}
                  ></div>
                </Col>
                <Col lg={9} md={9} sm={12}>
                  <div className="d-flex flex-column right-detailsnws">
                    <b className="blu-t">{data?.title}</b>
                    <small className="text-secondary py-2">
                      {moment(data?.createdOn).format('MMMM Do YYYY')}
                    </small>
                    <div className="txt-clamp">
                      {data?.content?.replace(/<[^>]+>/g, '')}
                    </div>
                    <Link to={`/news/${data?.guid}`} className="blu-t">
                      Read More
                    </Link>
                  </div>
                </Col>
              </Row>
            )
          )
        )}
        {/* <Link to={`/news`} className="vmblu-btn mrg-btm btn">
          View All
        </Link> */}
      </Container>
      <Link to="/news" style={{ textDecoration: 'none' }}>
        <Button type="button" className="btn signbtn mt-5">
          View All
        </Button>
      </Link>
    </div>
  );
};

export default RoundOneNews;

import React, { useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import investimg from '../../assets/images/investbanner.jpg';

const BannerLearn = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') ?? null);
  const category = searchParams.get('category') ?? null;

  const handleSearchPage = () => {
    setSearchParams(
      _.omitBy({ page: 1, search: search, category: category }, _.isNil)
    );
  };

  return (
    <>
      <div className="mrgntp-hdr"></div>
      <div className="relbox">
        <div
          className="invstbnr lrnbnr"
          style={{ backgroundImage: `url(${investimg})` }}
        >
          <Container
            className="pb-5"
            style={{ top: '25%', position: 'relative' }}
          >
            <div className="widhdr text-center relbox">
              <h2 className="text-white">Round One Academy</h2>
              <p>
                Go through our archive of content curated to help you make the
                most out of your crowdfunding journey.
              </p>
            </div>
            <div className="srchboxlrn relbox drop-shadow">
              <div className="input-group">
                <input
                  type="text"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSearchPage();
                    }
                  }}
                  className="form-control semi-bradius bg-light"
                  placeholder="What are you looking for?"
                />
                <div className="input-group-append mx-2">
                  <Button
                    type="button"
                    onClick={handleSearchPage}
                    className="semi-bradius font-weight-bold srchbtn fulheight"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default BannerLearn;

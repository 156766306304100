import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import endphto from '../assets/images/iw-end-photo.jpg';

const Blues = () => {
    return (
        <div className="relbox">
            <div className="blubg-belowtwo">
                {/* <Container>
                    <Row className='relbox py-5'>
                        <Col lg={6} md={6} sm={12}></Col>
                        <Col lg={6} md={6} sm={12}>
                            <div className='d-flex align-items-end'>
                                <p>
                                    Maintain good relations with your investors by practicing good corporate governance and transparency.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container> */}
            </div>
            <Container className="wrap-yelo">
                <Row>
                    <Col lg={5} md={5} sm={12}>
                        <Image src={endphto} alt="" className='xblupicbx' />
                    </Col>
                    <Col lg={7} md={7} sm={12} className='d-flex align-items-end'>
                        <p className='mbtm-tblues text-white left-alignbx'>
                            Maintain good relations with your investors by practicing good corporate governance and transparency.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Blues
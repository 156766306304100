import React from 'react';
import Binocular from '../../../assets/images/newIcons/Binocular.png';

function NotFound() {
  return (
    <div>
      <div className="py-5">
        <img src={Binocular} />
        <p className="font-weight-bold">There’s nothing to see here yet!</p>
      </div>
    </div>
  );
}

export default NotFound;

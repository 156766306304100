import React from 'react';
import MainLayout from '../../layout/MainLayout';
import SubscribeNewsletter from '../../components/homepage/subscribe-newsletter';
import ContactContent from './ContactContent';
import BannerContact from './BannerContact';


const ContactUsPage = () => {
  return (
    <>
       
        <BannerContact />
        <ContactContent />
        <SubscribeNewsletter />
       
    </>
  )
}

export default ContactUsPage
import React from 'react';
import { Accordion, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const Faqs = () => {
  const { faqs, subFaqs, faqsTopic } = useSelector((state) => state.home);
  return (
    <>
      <Container>
        {faqs?.map((faqs, key) => (
          <div className="my-5 left-alignbx" key={key}>
            <h4 className="font-weight-bold ">
              {faqs?.faqCategoryName}
            </h4>
            <div className="fqbxx">
              <Accordion alwaysOpen>
                {subFaqs
                  ?.filter((sub) => sub?.faqCategoryId == faqs.faqCategoryId)
                  ?.map((sub, indexSub) => (
                    <Accordion.Item eventKey={indexSub} key={indexSub}>
                      <Accordion.Header>{sub?.faqSubCategoryName}</Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex flex-column fq-link">
                          {faqsTopic?.filter((topic) => topic?.faqSubCategoryId == sub.faqSubCategoryId).map((topic,indexTopic)=>(
                            <Link className='mb-2' to={`/faqs/${topic?.guid}`} key={indexTopic}>{topic?.faqTopicName}</Link>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </div>
        ))}
        {/* <div className='my-5 left-alignbx'>
                    <h4 className='font-weight-bold '>
                        FAQs (Getting Started)
                    </h4>
                    <div className='fqbxx'>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>About Crowdfunding</Accordion.Header>
                                <Accordion.Body>
                                    <div className='d-flex flex-column fq-link'>
                                        <Link href="#">What is Crowdfunding?</Link>
                                        <Link href="#">What are the types of Crowdfunding?</Link>
                                        <Link href="#">How is equity crowdfunding different from other ways of raising funds?</Link>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div> */}
        {/* <div className='fqbx'>
                        <div className='mt-5 hdr-fq'>
                            <h5 className='font-weight-bold text-primary m-0'>
                                About Crowdfunding
                            </h5>
                        </div>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>What is Crowdfunding?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Crowdfunding is the means by which a business  or a venture raises capital in small increments from a large pool of investors. This is typically done through a fundraising campaign on an online platform.
                                    </p>
                                    <p className='py-3'>
                                        You can learn more about the different types of crowdfunding here.
                                    </p>
                                    <div className='mt-3'>
                                        <div>
                                            <small className='font-weight-bold'>
                                                Tags
                                            </small>
                                            <div className="d-flex flex-wrap mt-2">
                                                <span className="smalltabs">Investment</span>
                                                <span className="smalltabs">Crowdfunding</span>
                                                <span className="smalltabs">Business</span>
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <small className='font-weight-bold'>
                                                Related Topics
                                            </small>
                                            <div className="d-flex flex-wrap mt-2">
                                                <span className="smalltabs">Investment</span>
                                                <span className="smalltabs">Crowdfunding</span>
                                                <span className="smalltabs">Business</span>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>What are the types of crowdfunding?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                    </p>
                                    <p className='py-3'>
                                        Examples: Funding Circle, Lending Club
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>How is equity crowdfunding different from other ways of raising funds?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                    </p>
                                    <p className='py-3'>
                                        Examples: Funding Circle, Lending Club
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div> */}
        {/* </div> */}
        {/* <div className='mb-5 left-alignbx'>
                    <h4 className='font-weight-bold'>
                        FAQs (Issuers)
                    </h4>
                    <div className='fqbxx'>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Lorem Ipsum</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                    </p>
                                    <p className='py-3'>
                                        Examples: Funding Circle, Lending Club
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className='mb-5 left-alignbx'>
                    <h4 className='font-weight-bold'>
                        FAQs (Investors)
                    </h4>
                    <div className='fqbxx'>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Lorem Ipsum</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                    </p>
                                    <p className='py-3'>
                                        Examples: Funding Circle, Lending Club
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
                <div className='mb-5 left-alignbx'>
                    <h4 className='font-weight-bold'>
                        FAQs (Partners)
                    </h4>
                    <div className='fqbxx'>
                        <Accordion alwaysOpen>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Lorem Ipsum</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                    </p>
                                    <p className='py-3'>
                                        Examples: Funding Circle, Lending Club
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div> */}
      </Container>
    </>
  );
};

export default Faqs;

import React from 'react';
import { Container, Row, Col, Image, Stack } from 'react-bootstrap';

import lbuld from '../../assets/images/newIcons/R1 icons new_prepare.svg';
import promote from '../../assets/images/newIcons/R1 icons new_megaphone.svg';
import payout from '../../assets/images/newIcons/R1 icons new_pay out.svg';

const HowDoesItWork = () => {
  return (
    <Container className="pb-4">
      <div className="py-5">
        <h2 className="hd-titles text-center">How does it work?</h2>
        <p className="sub-des text-center">
          Plan your fundraising strategy and account for your pre-campaign,
          during campaign, and post-campaign activities.
        </p>
      </div>
      <Row>
        <Col lg={4} md={4} sm={12} className="text-center">
          <Stack gap={4}>
            <Image src={lbuld} alt="" className="hdw-sz" />
            <div className="top-insd-layer">
              <h3 className="font-weight-bold">Prepare</h3>
            </div>
            <p className="small">
              Put yourself on potential investors’ radars by creating a
              comprehensive yet compelling campaign. Upload your video pitch and
              share your campaign on your social media channels.
            </p>
          </Stack>
        </Col>
        <Col lg={4} md={4} sm={12} className="text-center">
          <Stack gap={4}>
          <Image src={promote} alt="" className="hdw-sz" />
            <div className="top-insd-layer">
              <h3 className="font-weight-bold">Promote</h3>
            </div>
            <p className="small">
              Get the funds you raise once your campaign concludes. Have the
              peace of mind that it's secure and safekept by Round
              One's partner financial institutions until then.
            </p>
          </Stack>
        </Col>
        <Col lg={4} md={4} sm={12} className="text-center">
          <Stack gap={4}>
          <Image src={payout} alt="" className="hdw-sz" />
            <div className="top-insd-layer">
              <h3 className="font-weight-bold">Pay Out</h3>
            </div>
            <p className="small">
              Make good use of the funds entrusted to you and take your business
              to new heights. Don't forget your responsibilities to your
              investors and keep them in the loop as to how you spend those
              funds.
            </p>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default HowDoesItWork;

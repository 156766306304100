import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

const ContactContent = () => {
  return (
    <>
      <Container className="py-5">
        <Row>
          <Col lg={6} md={6} sm={12} className="mb-3">
            <div className="height-cu mb-3">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="770"
                    height="510"
                    id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Tytana Bldg, Plaza San Lorenzo Ruiz, Binondo Manila 1006&t=&z=10&ie=UTF8&iwloc=&output=embed"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginwidth="0"
                  ></iframe>
                  <a href="https://2yu.co">2yu</a>
                  <br />
                  <style>
                    .mapouter
                    {
                      'position:relative;text-align:right;height:510px;width:770px;'
                    }
                  </style>
                  <a href="https://embedgooglemap.2yu.co">
                    html embed google map
                  </a>
                  <style>
                    .gmap_canvas{' '}
                    {
                      'overflow:hidden;background:none!important;height:510px;width:770px;'
                    }
                  </style>
                </div>
              </div>
            </div>
            <div className="d-flex p-2">
              <div className="left-alignbx">
                <i className="fas fa-map-marker-alt m-1"></i>
                <span className="font-weight-bold">
                  <b>Head Office</b>
                </span>
                <p className="m-0">
                  <small>
                    U-1701 Tytana Bldg, Plaza San Lorenzo Ruiz, Binondo Manila
                    1006
                  </small>
                </p>
              </div>
            </div>
            <div className="height-cu mb-3">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    width="770"
                    height="510"
                    id="gmap_canvas"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.8389573741647!2d121.04484627902231!3d14.551200231256068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c817330843df%3A0x69a89faf926a1a74!2sThe%20Philippine%20Stock%20Exchange%2C%20Inc.!5e0!3m2!1sen!2sph!4v1709628554236!5m2!1sen!2sph"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginwidth="0"
                  ></iframe>
                  <a href="https://2yu.co">2yu</a>
                  <br />
                  <style>
                    .mapouter
                    {
                      'position:relative;text-align:right;height:510px;width:770px;'
                    }
                  </style>
                  <a href="https://embedgooglemap.2yu.co">
                    html embed google map
                  </a>
                  <style>
                    .gmap_canvas{' '}
                    {
                      'overflow:hidden;background:none!important;height:510px;width:770px;'
                    }
                  </style>
                </div>
              </div>
            </div>
            <div className="d-flex p-2">
              <div className="left-alignbx">
                <i className="fas fa-map-marker-alt m-1"></i>
                <span className="font-weight-bold">
                  <b>Branch Office</b>
                </span>
                <p className="m-0">
                  <small>
                    U-1603-1605 PSE Tower, 28th St. cor. 5th Ave., BGC, Taguig
                    1630
                  </small>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="left-alignbx">
              <h4 className="hd-colr font-weight-bold mb-2">Contact Us</h4>
              <p>
                Whether you have questions, to partner with us or you would just
                like to say hello, contact us.
              </p>
            </div>
            <div className="left-alignbx">
              <Form>
                <div className="form-group">
                  <label className="font-weight-bold py-2">Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="form-group py-3">
                  <label className="font-weight-bold py-2">Email Address</label>
                  <input type="email" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="font-weight-bold py-2">Your Message</label>
                  <textarea className="form-control" rows="3"></textarea>
                </div>
              </Form>
            </div>
            <div className="d-flex flex-column left-alignbx">
              <div>
                {/* <Button type="button" className="vmblu-btn my-3">
                  Send Inquiry
                </Button> */}
                <Button type="button" className="btn signbtn my-3">
                  Send Inquiry
                </Button>
              </div>
              <small>
                <i class="fa-solid fa-mobile-screen" /> +63 2 8820-8018
              </small>
              <small>
              &emsp;+63 2 8845-6041
              </small>
              <small className='mb-3'>
              &emsp;+63 2 8851-4651
              </small>
              <small>
                <i class="fa-regular fa-envelope" />&nbsp;info@roundone.ph
              </small>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactContent;

import React from 'react';

import bnnerctct from '../../assets/images/contactus-banner.jpg';

const BannerContact = () => {
    return (
        <>
            <div className='mrgntp-hdr'></div>
            <div className='ctc-hs' style={{ backgroundImage: `url(${bnnerctct})` }}></div>
        </>
    )
}

export default BannerContact
import React, { useEffect } from 'react';
import { getEvent } from 'store/reducers/home';
import { getEventContent } from 'store/reducers/content';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Image, Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { Link, useParams, useLocation } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';
import _ from 'lodash';
import moment from 'moment';

import round1 from '../../assets/images/RoundOne1-symbol.svg';
import event01 from '../../assets/images/sample1.png';
import carouselimg from '../../assets/images/sample1.png';

const EventContentPage = () => {
  let { guid } = useParams();
  const { content } = useSelector((state) => state.content);
  const { event } = useSelector((state) => state.home);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvent());
    dispatch(getEventContent(guid));
  }, [pathname]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="mrgntp-hdr"></div>
      <div className="text-center py-4">
        <h5 className="font-weight-bold text-primary">Events</h5>
        <h2 className="font-weight-bold py-3">{content?.title}</h2>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Image src={round1} alt="" className="social-rlogo border p-1 m-0" />
          <span className="font-weight-bold px-2">ADMIN</span>
          <span className="text-muted">
            {moment(content?.createdOn).format('MMMM Do YYYY')}
          </span>
          {/* <span className="text-muted px-2">3 min read</span> */}
          <i className="fas fa-share-alt"></i>
        </div>
      </div>
      <Container>
        <div className="iacbox">
          <div className="overflow-hidden small-brdrds mb-4">
            <Image src={content?.fileUrl} alt="" />
          </div>
          <div className="left-alignbx">
            <p
              dangerouslySetInnerHTML={{ __html: content?.eventDescription }}
            />
            {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum aliquet suscipit urna non pulvinar. Vestibulum ante
                ipsum primis in faucibus orci luctus et ultrices posuere cubilia
                curae; Nullam ex orci, fermentum fringilla scelerisque
                tristique, bibendum et lacus. Donec luctus eget ligula commodo
                tincidunt. In vel libero at tortor bibendum aliquet. Fusce nec
                massa odio. Nullam suscipit lectus a turpis egestas, non feugiat
                enim tristique. Praesent tortor tortor, suscipit nec cursus sit
                amet, elementum tincidunt tellus. Ut hendrerit gravida
                tristique. Etiam pellentesque efficitur nulla, dictum auctor
                nulla ornare mollis. Nullam ac aliquam eros, nec malesuada
                velit. Donec iaculis diam sapien, non porta ante pharetra non.
                Mauris placerat urna neque, rutrum scelerisque dolor faucibus
                et. Vivamus placerat sem ac sem consequat auctor. Proin varius
                porttitor sem id commodo.
              </p>
              <p>
                Integer tincidunt, lorem eu elementum lacinia, magna sapien
                mollis massa, id tincidunt ante eros at diam. Suspendisse elit
                purus, vestibulum a tempor et, venenatis sit amet risus. Fusce
                vestibulum efficitur tellus ut blandit. Pellentesque vitae orci
                vitae nisi consequat bibendum. Sed molestie auctor nisl, ornare
                malesuada nulla laoreet eget. Morbi molestie, dolor sed
                venenatis rutrum, odio leo consectetur risus, non auctor leo
                sapien eu sapien. Morbi rutrum accumsan ornare. Pellentesque
                auctor sed arcu in consequat. Proin a metus et magna pulvinar
                vestibulum. Nam venenatis cursus odio.
              </p>
              <p>
                Nulla non ante nec sapien porta scelerisque eu ut nunc. Aliquam
                ac libero quis lorem vulputate pulvinar. Etiam velit lectus,
                feugiat quis lorem vel, viverra vulputate quam. Interdum et
                malesuada fames ac ante ipsum primis in faucibus. Phasellus in
                mauris et mauris fermentum finibus quis non augue. Vestibulum at
                tellus nec lorem lobortis consequat vel vitae risus.
                Pellentesque ac blandit sapien, nec vulputate mi. Pellentesque
                vestibulum aliquam dolor, sed vestibulum magna pellentesque sed.
                Donec vitae felis eu quam faucibus interdum sit amet ut lacus.
                Curabitur ultricies ex lorem, vitae elementum urna vulputate ut.
                Curabitur ut quam nunc. Sed scelerisque justo at eleifend
                venenatis.
              </p> */}
            <div className="mt-5">
              <small className="font-weight-bold">Categories</small>
              <div className="d-flex flex-wrap mt-2">
                {content?.tags?.split(',')?.map((data, index) => (
                  <span key={index} className="smalltabs">
                    {data}
                  </span>
                ))}
              </div>
            </div>
            <div className="my-5">
              <div className="my-3 text-center">
                <h2 className="hd-titles">Other Events</h2>
              </div>
              {!_.isEmpty(event) && (
                <Carousel responsive={responsive}>
                  {event?.map((data, key) => (
                    <div className="carouselbx fulheight mx-3 p-3">
                      <div
                        className="carosize px-3"
                        style={{
                          backgroundImage: `url(${encodeURI(data?.fileUrl)})`,
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-baseline">
                          <div>
                            {/* <Link href="#" className='semi-bradius pplink py-2 px-3'>Upcoming Event</Link> */}
                          </div>
                          <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                            <Link href="#" className="shr-hvr">
                              <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="slf-overlay"></div>
                      </div>
                      <Container className="left-alignbx">
                        <h6 className="font-weight-bold text-primary my-3">
                          {data?.title}
                        </h6>
                        <div className="align-items-center txt-clamp-single">
                          <i className="fas fa-map-marker-alt text-primary"></i>
                          <small className="px-2">{data?.venueAddress}</small>
                        </div>
                        <div className="align-items-center txt-clamp-single">
                          <i className="fas fa-clock text-primary"></i>
                          <small className="px-2">
                            {moment(
                              _.first(data?.eventschedules)?.startDateTime
                            )
                              .utcOffset(960)
                              .format('MMMM Do YYYY | h:mm a')}
                            {moment(_.first(data?.eventschedules)?.endDateTime)
                              .utcOffset(960)
                              .format('- h:mm a')}
                          </small>
                        </div>
                        <div className="d-flex flex-wrap mt-2">
                          {data?.tags?.split(',')?.map((data, index) => (
                            <span key={index} className="smalltabs">
                              {data}
                            </span>
                          ))}
                        </div>
                        <p
                          className="py-2 text-left m-0 txt-clamp"
                          style={{ height: '5em' }}
                        >
                          <small>
                            {data?.eventDescription?.replace(/<[^>]+>/g, '')}
                          </small>
                        </p>
                        <div className="d-flex">
                          <Link
                            type="button"
                            to={`/event/${data?.guid}`}
                            className="lightblue-btn semi-bradius btn btn-sm mx-2 border-0"
                          >
                            See Details
                          </Link>
                        </div>
                      </Container>
                    </div>
                  ))}
                </Carousel>
              )}
              <div className="text-center">
                <Link className="vmblu-btn my-5 m-auto btn" to="/event">
                  View All
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <SubscribeNewsletter />
    </>
  );
};

export default EventContentPage;

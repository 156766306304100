import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import carouselimg from '../../assets/images/sample1.png';

const CampaignWorks = () => {
  const { faqs, campaign, education } = useSelector((state) => state.home);
  return (
    <>
      <Container className="pb-5">
        <div className="py-5">
          <h2 className="hd-titles text-center">
            Learn more on how your campaign works
          </h2>
          <p className="sub-des text-center">
            Here’s our advice, check out our Round One Academy
          </p>
        </div>
        <Row>
          {education?.slice(0, 3).map((data, key) => (
            <Col lg={4} md={4} sm={12} className="mb-3">
              <div className="carouselbx fulheight mx-3 p-3">
                <div
                  className="carosize px-3"
                  style={{ backgroundImage: `url(${encodeURI(data?.bannerImageUrl)})` }}
                >
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div></div>
                    <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                      <Link href="#" className="shr-hvr">
                        <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="slf-overlay"></div>
                </div>
                <Container>
                  <h5 className="font-weight-bold my-3">{data?.subTitle}</h5>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-clock text-primary"></i>
                    <small className="px-2">
                      {moment(data?.createdOn).format('MMMM Do YYYY')}
                    </small>
                  </div>
                  <div className="d-flex flex-wrap mt-2">
                    <span className="smalltabs">
                      {data?.educationalMaterialCategoryName}
                    </span>
                  </div>
                  <p className="py-2 text-left m-0 txt-clamp" style={{height: "5em"}}>
                    <small>{data?.content?.replace(/<[^>]+>/g, '')}</small>
                  </p>
                  <div className="text-left">
                    <Link
                      to={`/learn/${data?.guid}`}
                      variant="link"
                      className="py-2 px-0 text-small blu-t"
                    >
                      READ MORE
                    </Link>
                  </div>
                </Container>
              </div>
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <p className="pt-5">
            <small>Still have more questions?</small>
          </p>
          {/* <Link to={'/learn'} className="vmblu-btn btn">
            See FAQs
          </Link> */}
          <Link to="/learn" style={{ textDecoration: 'none' }}>
            <Button type="button" to={'/learn'} className="btn signbtn">
              See FAQs
            </Button>
          </Link>
        </div>
      </Container>
    </>
  );
};

export default CampaignWorks;

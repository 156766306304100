import api from 'utils/api';

export const requestToken = (params) =>
  api.callAuthPost(`public/v1/token`, params);

export const requestPublicView = (params) =>
  api.callPost(`v1/public-view/list`, params);

export const requestPublicEventList = (params) =>
  api.callPost(`v1/public-view/events-list`, params);

export const requestPublicNewsList = (params) =>
  api.callPost(`v1/public-view/news-updates-list`, params);

export const requestEducationalMateriaList = (params) =>
  api.callPost(`v1/public-view/educational-material-list`, params);

export const requestFaqsList = (params) =>
  api.callPost(`v1/public-view/faqs-list`, params);

export const requestSubCategory = (params) =>
  api.callPost(`/v1/public-view/faqs-subcategory-list`, params);

export const requestTopicList = (params) =>
  api.callPost(`v1/public-view/faqs-topic-list`, params);

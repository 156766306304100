import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';

import carouselimg from '../../assets/images/sample1.png';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { getEvent } from 'store/reducers/home';
import NotFound from 'components/common/NotFound';
import _ from 'lodash';

const CardListings = () => {
  const { event, pageCount } = useSelector((state) => state.home);
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';
  const year = searchParams.get('year') || '';

  const handleChangePage = (page) => {
    setSearchParams({ page: page });
    // dispatch(getEvent(page));
  };

  useEffect(() => {
    dispatch(getEvent(page, search, year));
  }, [searchParams]);

  return (
    <>
      <Container>
        <Row className="my-5">
          {_.isEmpty(
            event?.filter(
              (data) =>
                moment(_.first(data?.eventschedules)?.startDateTime).year() ===
                  Number(year) || !year
            )
          ) ? (
            <NotFound />
          ) : (
            event
              ?.filter(
                (data) =>
                  moment(
                    _.first(data?.eventschedules)?.startDateTime
                  ).year() === Number(year) || !year
              )
              ?.slice((page - 1) * 6, (page - 1) * 6 + 6)
              ?.map((data, key) => (
                <Col lg={4} md={4} sm={12} className="mb-4" key={key}>
                  <div className="carouselbx fulheight mx-3 p-3">
                    <div
                      className="carosize px-3"
                      style={{
                        backgroundImage: `url(${encodeURI(data?.fileUrl)})`,
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div>
                          {new Date(
                            moment(
                              _.first(data?.eventschedules)?.endDateTime
                            ).utcOffset(960)
                          ) -
                            new Date() <
                            0 || (
                            <Link
                              href="#"
                              className="semi-bradius pplink py-2 px-3"
                            >
                              Upcoming Event
                            </Link>
                          )}
                        </div>
                        <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                          <Link href="#" className="shr-hvr">
                            <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="slf-overlay"></div>
                    </div>
                    <Container className="left-alignbx">
                      <h6 className="font-weight-bold text-primary my-3">
                        {data?.title}
                      </h6>
                      <div className="align-items-center txt-clamp-single">
                        <i className="fas fa-map-marker-alt text-primary"></i>
                        <small className="px-2">{data?.venueAddress}</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-clock text-primary"></i>
                        <small className="px-2">
                          {' '}
                          {moment(_.first(data?.eventschedules)?.startDateTime)
                            .utcOffset(960)
                            .format('MMMM Do YYYY | hh:mm a')}
                          {moment(_.first(data?.eventschedules)?.endDateTime)
                            .utcOffset(960)
                            .format('- hh:mm a')}
                        </small>
                      </div>
                      <div className="d-flex flex-wrap mt-2">
                        {data?.tags?.split(',')?.map((data, index) => (
                          <span key={index} className="smalltabs">
                            {data}
                          </span>
                        ))}
                      </div>
                      <p className="py-2 text-left m-0 txt-clamp">
                        <small>
                          {data?.eventDescription?.replace(/<[^>]+>/g, '')}
                        </small>
                      </p>
                      <div className="d-flex">
                        <Link
                          type="button"
                          to={`/event/${data?.guid}`}
                          className="lightblue-btn semi-bradius btn btn-sm mx-2 border-0"
                        >
                          See Details
                        </Link>
                      </div>
                    </Container>
                  </div>
                </Col>
              ))
          )}
        </Row>
        {!_.isEmpty(event) && (
          <div className="mb-5">
            <div className="py-3">
              <span className="text-center">
                {page} of{' '}
                {Math.ceil(
                  _.size(
                    event?.filter(
                      (data) =>
                        moment(
                          _.first(data?.eventschedules)?.startDateTime
                        ).year() === Number(year) || !year
                    )
                  ) / 6
                )}
              </span>
            </div>
            <PaginationControl
              page={page}
              between={4}
              total={_.size(
                event?.filter(
                  (data) =>
                    moment(
                      _.first(data?.eventschedules)?.startDateTime
                    ).year() === Number(year) || !year
                )
              )}
              changePage={(page) => handleChangePage(page)}
              limit={6}
              ellipsis={1}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default CardListings;

import React from 'react';
import { Row, Col, Image, Stack, Button } from 'react-bootstrap';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';
import { useParams } from 'react-router-dom';

const CompleteInfo = ({ content, companyData, subscription }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });

  let { guid } = useParams();

  const { minutes, hours, days } = useTimer({
    expiryTimestamp: moment(content?.offeredPeriodEndDate),
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <Image
            src={companyData?.ImageUrl}
            alt=""
            className="social-rlogo border p-1 m-0"
          />
          <h4 className="font-weight-bold m-0 px-2">{content?.nameOfIssuer}</h4>
        </div>
        <div>
          <p className="text-uppercase text-small-xs font-weight-bold mb-1">
            Time
            <br />
            Remaining
          </p>
          <Stack direction="horizontal" gap={2}>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{days}</div>
              <small className="text-uppercase text-small-xs">Days</small>
            </div>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{hours}</div>
              <small className="text-uppercase text-small-xs">Hrs</small>
            </div>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{minutes}</div>
              <small className="text-uppercase text-small-xs">Mins</small>
            </div>
          </Stack>
        </div>
      </div>
      <div className="left-alignbx mb-4">
        <p className="font-weight-bold m-0">Campaign Highlights</p>
        <p
          dangerouslySetInnerHTML={{ __html: content?.campaignHighlights }}
        ></p>
      </div>
      <Row className="left-alignbx p-3 mb-4">
        <Col lg={6} lmd={6} sm={12} className="brdr-cntr">
          <div className="text-center">
            <small className="font-weight bold">Summary</small>
            <br />
            <small style={{ fontSize: '1em' }}>
              ({content?.typeOffering?.typeOfferingName})
            </small>
          </div>
          <div className="mt-2">
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Total Offering</small>
              <small className="blu-t font-weight-bold">
                PHP {formatter.format(content?.campaignTargetAmount)}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Unit Price</small>
              <small className="blu-t font-weight-bold">
                {content?.typeOffering?.typeOfferingName === 'Debt/Fixed Income'
                  ? 'N/A'
                  : `PHP ${formatter.format(content?.sharePrice)} / units`}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">No. of Units Offered:</small>
              <small className="blu-t font-weight-bold">
                {formatter.format(content?.numberSharesOffered)} units
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Equity Offered:</small>
              <small className="blu-t font-weight-bold">
                {formatter.format(content?.equityOffered)}%
              </small>
            </div>
          </div>
        </Col>
        <Col lg={6} lmd={6} sm={12}>
          <div className="text-center">
            <small className="font-weight bold">Investment Terms</small>
          </div>
          <br />
          <div className="mt-2">
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Minimum Amount per Investor:</small>
              <small className="blu-t font-weight-bold">
                PHP {formatter.format(content?.minAmountPerInvestor)}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Maximum Amount per Investor:</small>
              <small className="blu-t font-weight-bold">
                PHP {formatter.format(content?.maxAmountPerInvestor)}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Offer Start Date:</small>
              <small className="blu-t font-weight-bold">
                {moment(content?.offeredPeriodStartDate).format('YYYY MMMM DD')}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Offer End Date:</small>
              <small className="blu-t font-weight-bold">
                {moment(content?.offeredPeriodEndDate).format('YYYY MMMM DD')}
              </small>
            </div>
          </div>
        </Col>
      </Row>
      <div className="progress v-prg">
        <div
          className="progress-bar sqr-rmid"
          role="progressbar"
          style={{
            width: `${subscription?.stringPercentage}`,
          }}
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <h5 className="blu-t font-weight-bold m-0 p-1">
            ₱{subscription?.totalCommitmentAmmount}
          </h5>
          <small className="text-secondary p-1">
            reserved by {subscription?.totalInvestors ?? 0} investors
          </small>
        </div>
        <div>
          <small className="font-weight-bold blu-t">
            {subscription?.stringPercentage}
          </small>
        </div>
      </div>
      <a
        href={`https://auth.v2.roundone.ph/?campaignGuid=${guid}`}
        style={{ textDecoration: 'none' }}
      >
        <Button
          type="button"
          className="btn signbtn my-1"
          style={{ width: '50%' }}
        >
          Invest
        </Button>
      </a>
    </>
  );
};

export default CompleteInfo;

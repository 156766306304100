import api from 'utils/api';

export const requestCampaignContent = (guid) =>
  api.callGet(`v1/public-view/published-view/${guid}`);

export const requestEventContent = (guid) =>
  api.callGet(`v1/public-view/events/${guid}`);

export const requestEducationalContent = (guid) =>
  api.callGet(`v1/public-view/educational-material/${guid}`);

export const requestFaqsContent = (guid) =>
  api.callGet(`v1/public-view/faqs/${guid}`);

export const requestSubCategoryContent = (guid) =>
  api.callGet(`v1/public-view/faqs-subcategory/${guid}`);

export const requestFaqsTopic = (guid) =>
  api.callGet(`v1/public-view/faqs-topic/${guid}`);

export const requestNewsUpdate = (guid) =>
  api.callGet(`v1/public-view/news-updates/${guid}`);

export const requestIssuerDetails = (issuerId) =>
  api.callGet(`v1/public-view/published-view/issuer/${issuerId}/details`);

export const requestInvestmentSubs = (campaignGuid) =>
  api.callGet(`v1/public-view/${campaignGuid}/subscription-detail`);

import axios from 'axios';
import { getToken } from 'utils/session';
import { apiUrl, accessKey, secretKey, accountCode } from 'config';

axios.defaults.baseURL = apiUrl;

const getAuthenticationHeaders = (headers = {}) => ({
  Accept: 'application/json',
  'x-access-key': accessKey,
  'x-secret-key': secretKey,
  'account-code': accountCode,
  ...headers,
});

const getHeaders = (headers = {}) => ({
  Accept: 'application/json',
  Authorization: `Bearer ${getToken()}`,
  ...headers,
});

const getAuthConfig = (params, options = {}, _headers = {}, data) => {
  const headers = getAuthenticationHeaders(_headers);

  if (headers) {
    return {
      params,
      headers,
      data,
      ...options,
    };
  }

  return {
    params,
    ...options,
  };
};

const getConfig = (params, options = {}, _headers = {}, data) => {
  const headers = getHeaders(_headers);

  if (headers) {
    return {
      params,
      headers,
      data,
      ...options,
    };
  }

  return {
    params,
    ...options,
  };
};

const getResponse = (request) =>
  request
    .then((response) => response.data)
    .catch((error) => {
      return {
        status: error.response?.status,
        message: error.response.data?.message ?? error.response?.message,
        data: error.response.data?.data ?? {},
      };
    });

const callGetWithResponseHeaders = (path, params, options) =>
  axios
    .get(path, getConfig(params, options))
    .then((response) => [response.headers, response.data])
    .catch(() => null);

const callGet = (path, params, options) =>
  getResponse(axios.get(path, getConfig(params, options)));

const callPost = (path, data, options) =>
  getResponse(axios.post(path, data, getConfig(options)));

const callAuthPost = (path, data, options) =>
  getResponse(axios.post(path, data, getAuthConfig(options)));

export default {
  getAuthenticationHeaders,
  callGetWithResponseHeaders,
  callGet,
  callPost,
  callAuthPost,
};

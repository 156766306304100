import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import bigpic from '../../assets/images/shake-hands.jpg';
// import strips from '../../assets/images/stripes.png';

const MissionVision = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="d-flex flex-column combinebox">
      {/* <Image src={strips} alt="" className="patternblue-img" /> */}
      <div className="bg-white">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div className="ins-singlebx-left">
                <h2 className="hd-titles text-uppercase">our vision</h2>
                <p>
                  To become the leading digital ecosystem for entrepreneurs and
                  investors by providing opportunities, connections, and
                  support.
                </p>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="ins-singlebx-right">
                <h2 className="hd-titles text-uppercase">our mission</h2>
                <p>
                  To help Filipinos invest in each other by encouraging
                  entrepreneurs to realize the full potential of their business
                  ideas.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="blupicbx">
        <div className="blubg-below"></div>
        <Container
          className="wrap-yelo"
          style={{ opacity: 1.7 - scrollPosition / 1550 }}
        >
          <Image src={bigpic} alt="" />
          <div className="rounded-0 bg-warning yelow-square"></div>
        </Container>
      </div>
    </div>
  );
};

export default MissionVision;

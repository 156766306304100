import React, { useEffect } from 'react';
import { Container, Image, Stack } from 'react-bootstrap';
import MainLayout from 'layout/MainLayout';
import banner from 'assets/images/riskwarning/banner.png';
import LearningCard from 'components/common/LearningCards';
import { getEducation } from 'store/reducers/home';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useLocation } from 'react-router-dom';
import round1 from '../../assets/images/RoundOne1-symbol.svg';
import carouselimg from '../../assets/images/sample1.png';

const RiskWarning = () => {
  const { education } = useSelector((state) => state.home);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEducation());
  }, [pathname]);

  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <div className="text-center py-4">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <h5 className="font-weight-bold text-primary m-0">Others</h5>
            <span className="font-weight-bold px-2">/ 01 of 04</span>
          </div>
          <div className="text-center">
            <h1 className="font-weight-bold py-2">Investment Risk Warnings</h1>
          </div>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Image
              src={round1}
              alt=""
              className="social-rlogo border p-1 m-0"
            />
            <span className="font-weight-bold px-2">ADMIN</span>
            <span className="text-muted">September 14, 2022</span>
            <span className="text-muted px-2">3 min read</span>
            <i className="fas fa-share-alt"></i>
          </div>
        </div>

        <Container>
          <div className="iacbox">
            <div className="overflow-hidden small-brdrds mb-4">
              <Image
                src={banner}
                alt=""
                height={'540px'}
                width={'1280px'}
                className="rounded-4"
              />
            </div>
            <div className="text-start">
              <div>
                <p>
                  Round One wants all users to be aware of specific risks
                  related to Equity Crowdfunding Investments.
                </p>
                <p>
                  In order to maintain a fair and objective community, the
                  Intermediary is prohibited from providing investment advice or
                  from specific endorsement. This list of risk factors outlined
                  below is not a comprehensive document on risks to investments
                  listed on this platform. Investors must perform their own due
                  diligence and exercise discretion and good judgment in
                  participating in Crowdfunding Campaigns.
                </p>
                <p>
                  Your decision to invest in a Crowdfunding Campaign is a
                  personal investment decision made by you. No responsibility
                  for the consequences of that decision is accepted by Round One
                  or by any of its directors, agents, employees, or other
                  members. ✩ Investing in our platform does not involve a
                  regular return on your investment.
                </p>
                <p>
                  Your choice to invest in our Platform should at the very
                  least, consider the following:
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">Business Risk 📊 </span>
                <p>
                  Investors must be aware that any business or venture,
                  especially high-growth, startup, or innovative companies,
                  carry with it an element of risk of failure. There is a
                  substantial risk of loss of your entire invested capital when
                  participating in equity crowdfunding ventures. Business Risk
                  is only the general term for all risks associated with
                  business failure. Business Risk may also include factors
                  external to the organization and management, such as country
                  and economic risk. Issuer’s success is heavily dependent upon
                  the competency of the issuer’s directors and officers.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">Management Risk 🛠</span>
                <p>
                  Related to Business Risk, - Round One makes no warranties that
                  an issuer’s management team will successfully execute all
                  business plans.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">
                  Equity Investment Risk 📉
                </span>
                <p>
                  Equity Crowdfunding Investments are only one form of a diverse
                  array of investments and are considered highly speculative,
                  with a low likelihood of consistent revenue, profitability,
                  and dividend payment. The Intermediary cannot guarantee
                  protections against loss of capital due to the valuation of
                  your investments.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">
                  Past & Future Performance 📅{' '}
                </span>
                <p>
                  Past performance is not a reliable indicator of future
                  performance. You should not rely on any past performance as a
                  guarantee of future investment performance. Forecasts are not
                  a reliable indicator of future performance.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">
                  Minority Shareholder Risk 🍰{' '}
                </span>
                <p>
                  Most investors will likely be considered minority shareholders
                  in any Equity Crowdfunding Issuer and may be negatively
                  impacted by this status. As a minority shareholder, you will
                  generally be a passive investor and typically will not have
                  the right or ability to influence the direction of the
                  company. ✩ While the Platform provides as much transparency as
                  it is able to, the Intermediary cannot guarantee protections
                  against dilution risk, management risk, and other provisions
                  of the share purchase agreement of an Issuer.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">Dilution Risk 🏊</span>
                <p>
                  Issuers who raise funds through Round One may offer securities
                  with no protections against dilution. You may not have the
                  opportunity to buy additional shares during a subsequent
                  fundraising round so that you can maintain or preserve your
                  original proportionate ownership stake. Dilution affects
                  shareholders who are unable to buy any of the new shares being
                  issued in a subsequent funding round and as a result, your
                  proportionate shareholding of the company may be reduced, or
                  ‘diluted’. Your proportionate ownership stake can have an
                  effect on your ability to exercise voting, dividends and value
                  of shareholding.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">Insolvency Risk 🧊</span>
                <p>
                  The issuer may become temporarily or permanently insolvent,
                  resulting in its incapacity to repay the interest or redeem
                  the debt instrument. The solvency of an issuer may change due
                  to one or more of a range of factors including the issuing
                  company, the issuer’s economic sector and/or the political and
                  economic status of the countries concerned. The deterioration
                  of the issuer’s solvency will influence the price of the
                  securities that it issues.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <span className="font-weight-bold">Suitability 💼</span>
                <p>
                  Crowdfunding investments carry significant risks. The risk of
                  loss of investment is substantial due but not limited to risks
                  indicated on this page. Investors must understand and
                  appreciate the risks associated with early stage, growth
                  focused, and crowdfunding securities.
                </p>
                <p>
                  Such high risk securities must be duly considered and are only
                  a portion of a well-diversified portfolio of investments that
                  may include less risky assets such as cash, government
                  securities, insurance products, and shares of listed company
                  stock. Please take time to review and understand this document
                  in its entirety.
                </p>
                <p>
                  In making a determination of appropriateness and suitability,
                  Investors shall consider the relationship between risk and
                  return, as well as their own investment objectives, goals,
                  time horizons, and needs for retirement or estate planning. No
                  representations are made regarding the suitability of
                  investments displayed on the Round One platform.
                </p>
              </div>
            </div>
          </div>
          
          <LearningCard learningData={education} />
        </Container>
       
    </>
  );
};

export default RiskWarning;

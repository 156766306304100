import React from 'react';
import { useState } from 'react';
import { Button, Collapse, Container, Row, Col, Form } from 'react-bootstrap';

const FiltersAllNews = () => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Container>
                <div className='text-left mt-3'>
                    <Button
                        variant="link"
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className='text-decoration-none font-weight-bold p-0'
                    >
                        Filters
                        <i className="fas fa-chevron-down px-1"></i>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            <Row md={8} className='mt-3'>
                                <Col>
                                    <div>
                                        <small className='font-weight-bold'>
                                            Browse by Year
                                        </small>
                                        <div className="d-flex flex-wrap mt-2">
                                            <span className="smalltabs">2021</span>
                                            <span className="smalltabs">2022</span>
                                            <span className="smalltabs">2023</span>
                                            <span className="smalltabs">2024</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </div>
            </Container>
        </>
    )
}

export default FiltersAllNews
import React from 'react';
import { Row, Col } from 'react-bootstrap';

const Mapping = () => {
  return (
    <>
      <div className='relbox hidn-flw px-1'>
        <Row className='no-pads'>
          <Col lg={4} md={4} sm={12}>
            <div className='pre-hd d-flex justify-content-center'>
              <h2 className='relbox text-white hd-leftp align-self-center'>Pre<br /> Campaign</h2>
            </div>
            <div className='bg-lights fulheight'>
              <div className='d-flex flex-column padds-hpw center-mble'>
                <div className='right-hopw brdrleft d-flex flex-column justify-content-center mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Exploratory / Sign Up
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Preliminary discussion</li>
                    <li>Initial assessment</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Onboarding Process
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Documentary Requirements</li>
                    <li>Financial & Legal DD</li>
                    <li>Offering Terms</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Campaign Preparations
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Company Profile </li>
                    <li>Fundraising Details</li>
                    <li>Marketing Strategy</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className='cpgn-hd d-flex justify-content-center'>
              <h2 className='relbox text-white hd-leftp align-self-center'>Campaign</h2>
            </div>
            <div className='bg-lights fulheight'>
              <div className='d-flex flex-column padds-hpw center-mble'>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Signs up / Browse
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Investor opens an account</li>
                    <li>Submit requirements</li>
                    <li>Undergoes KYC</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Investor Funds Account
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Funds will reflect in account</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Review / Subscribe
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Attend in roadshows </li>
                    <li>Engage with Fundraiser</li>
                    <li>Conduct own due diligence</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className='post-hd d-flex justify-content-center'>
              <h2 className='relbox text-white hd-leftp align-self-center'>Post<br />Campaign</h2>
            </div>
            <div className='bg-lights fulheight'>
              <div className='d-flex flex-column padds-hpw center-mble'>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Receives Confirmation
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Confirmation of committed funds<br /> and reflect ownership </li>
                    <li>Engage with Fundraiser</li>
                    <li>Conduct own due diligence</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Ongoing Reporting
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Submission of annual reports</li>
                    <li>Investor updates</li>
                  </ul>
                </div>
                <div className='right-hopw brdrleft mb-4'>
                  <h4 className='hdlft-hopw text-nowrap'>
                    Review / Support
                  </h4>
                  <ul className='small list-hopw'>
                    <li>Ongoing  investor relations activities</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Mapping
import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
// import { Link } from "react-router-dom";

import backgroundwhite from '../../assets/images/homebannerbg.png';
import subsImage from '../../assets/images/newsletter.png';

const SubscribeNewsletter = () => {
    return (
        <div className='numcbgdrk bluebg-sqr {' style={{ backgroundImage: `url(${backgroundwhite})` }}>
            <Container>
                <Row>
                    <Col lg="7" md="7" sm="12" className='text-white p-5 align-self-center subbx'>
                        <h2 className='hdsf-titles text-left'>
                            Subscribe to our Newsletter!
                        </h2>
                        <div className='d-flex flex-wrap hpylk'>
                            <span>Free resources</span>
                            <span className='px-3'>|</span>
                            <span>Exclusive event accesss</span>
                            <span className='px-3'>|</span>
                            <span>News & Updates</span>
                        </div>
                        <div className="input-group mt-3">
                            <input type="text" className="form-control" placeholder="Type your email address here..." aria-label="Recipient's username" aria-describedby="basic-addon2" />
                            <div className="input-group-append mx-2">
                                <Button type="button" className="signbtn-bnr">Subscribe Now</Button>
                            </div>
                        </div>
                    </Col>
                    <Col lg="5" md="5" sm="12">
                        <Image src={subsImage} alt="" className="full-w" />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SubscribeNewsletter
import React, { useEffect } from 'react';
import { Container, Image, Accordion, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';

import { getFaqsTopicContent } from 'store/reducers/content';
import { getFaqs, getFaqsTopic, getSubFaqs } from 'store/reducers/home';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import round1 from '../../assets/images/RoundOne1-symbol.svg';
import event01 from '../../assets/images/sample1.png';
import Faqs from 'components/Faqs';
import _ from 'lodash';

const FaqsContentPage = () => {
  let { guid } = useParams();
  const { content } = useSelector((state) => state.content);
  const { subFaqs } = useSelector((state) => state.home);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFaqsTopicContent(guid));
    dispatch(getFaqs());
    dispatch(getSubFaqs());
    dispatch(getFaqsTopic());
  }, [guid]);

  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <div className="text-center py-4">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <h5 className="font-weight-bold text-primary m-0">
              {
                _.find(
                  subFaqs,
                  (data) => data?.faqSubCategoryId == content?.faqSubCategoryId
                )?.faqSubCategoryName
              }
            </h5>
            <span className="font-weight-bold px-2">
              / {content?.faqTopicName}
            </span>
          </div>
          {/* <h2 className='font-weight-bold py-3'>
                        What are the types of crowdfunding?
                    </h2>
                    <div className='d-flex flex-row justify-content-center align-items-center'>
                        <Image src={round1} alt="" className="social-rlogo border p-1 m-0" />
                        <span className='font-weight-bold px-2'>
                            Yessar Burgos
                        </span>
                        <span className='text-muted'>September 14, 2022</span>
                        <span className='text-muted px-2'>3 min read</span>
                        <i className="fas fa-share-alt"></i>
                    </div> */}
        </div>
        <Container>
          <div className="iacbox">
            {/* <div className='overflow-hidden small-brdrds mb-4'>
                            <Image src={event01} alt="" />
                        </div> */}
            <div className="left-alignbx">
              <div dangerouslySetInnerHTML={{ __html: content?.content }} />
              {/* <p>
                                There are four types of crowdfunding: debt, donation-based, equity, and rewards-based.
                            </p>
                            <div className='accbx'>
                                <Accordion defaultActiveKey={['0']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Debt Crowdfunding 💰</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                            </p>
                                            <p className='py-3'>
                                                Examples: Funding Circle, Lending Club
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Donation-based Crowdfunding 💌</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                            </p>
                                            <p className='py-3'>
                                                Examples: Funding Circle, Lending Club
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header>Equity Crowdfunding 👯</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                            </p>
                                            <p className='py-3'>
                                                Examples: Funding Circle, Lending Club
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>Rewards-based Crowdfunding 🎁</Accordion.Header>
                                        <Accordion.Body>
                                            <p>
                                                Also known as crowdlending, this is a way of aggregating funds for a fundraiser's debt requirements through a crowdfunding platform. Payment of the principal plus interest is made to the platform, who will then distribute it back to the individual investors.
                                            </p>
                                            <p className='py-3'>
                                                Examples: Funding Circle, Lending Club
                                            </p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div> */}
              <div className="my-5">
                <Row>
                  <Col lg={6} md={6} sm={12}>
                    <small className="font-weight-bold">Tags</small>
                    <div className="d-flex flex-wrap mt-2">
                      {content?.tags?.split(',')?.map((data, index) => (
                        <span key={index} className="smalltabs">
                          {data}
                        </span>
                      ))}
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <small className="font-weight-bold">Related Topics</small>
                    <div className="d-flex flex-wrap mt-2">
                      <span className="smalltabs">
                        {content?.relatedTopics}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="my-5">
                <div className="my-3 text-center">
                  <h2 className="hd-titles">Other FAQS</h2>
                </div>
                <Faqs />
              </div>
            </div>
          </div>
        </Container>
        <SubscribeNewsletter />
       
    </>
  );
};

export default FaqsContentPage;

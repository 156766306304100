import React, { Fragment } from 'react';
import { Container, Button, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment/moment';
import Carousel from 'react-multi-carousel';
import ShareButton from '../ShareButton';
import { convert } from 'html-to-text';

const LearningCard = ({ learningData }) => {

    
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 2000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="my-5">
      <div className="my-3 text-center">
        <h2 className="hd-titles">Related Learning Materials</h2>
      </div>
      <Carousel responsive={responsive}>
        {learningData?.map((data, key) => (
          <div className="carouselbx fulheight mx-3 p-3">
            <div
              className="carosize px-3"
              style={{
                backgroundImage: `url(${encodeURI(data?.bannerImageUrl)})`,
              }}
            >
              <div className="d-flex justify-content-between align-items-baseline">
                <div></div>
                <ShareButton link={`/learn/${data?.guid}`}/>
              </div>
              <div className="slf-overlay"></div>
            </div>
            <Container className="left-alignbx">
              <h6 className="font-weight-bold my-3">{data?.title}</h6>
              <div className="d-flex align-items-center">
                <i className="fas fa-clock text-primary"></i>
                <small className="px-2">
                  {moment(data?.createdOn).format('MMMM Do YYYY')}
                </small>
              </div>
              <div className="d-flex flex-wrap mt-2">
                <span className="smalltabs">
                  {data?.educationalMaterialCategoryName}
                </span>
              </div>
              <p className="py-2 text-left m-0 txt-clamp">
                <small>{convert(data?.content,{wordwrap: 500})}</small>
              </p>
              <Link
                to={`/learn/${data?.guid}`}
                className="py-2 px-0 btn-link btn"
              >
                Read More
              </Link>
            </Container>
          </div>
        ))}
      </Carousel>
      <div className="my-5">
        <span>Still have more questions?</span>
        <div className="text-center">
          <Link type="button" to={'/learn'} className="vmblu-btn btn">
            See FAQs
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LearningCard;

import React from 'react';
import { Container, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import CampaignCard from 'components/common/CampaignCards';
import Binocular from '../../assets/images/newIcons/Binocular.png';
// import carouselimg from '../../assets/images/sample1.png';
// import dot from '../../assets/images/dottedblue.png';

const FundraiserPage = () => {
  const { campaign } = useSelector((state) => state.home);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  
  return (
    <div className="relbox">
      {/* <Image src={dot} alt="" className="dotblue" /> */}
      <Container className="pb-5 text-center">
        <div className="py-5">
          <h2 className="hd-titles">Ongoing Fundraises</h2>
          <p className="sub-des">
            Explore investment-ready ventures and be a part of their story!
          </p>
        </div>
        {!_.isEmpty(campaign?.campaigns?.data) ? (
          <Carousel responsive={responsive}>
            {campaign?.campaigns?.data?.map((data, key) => (
              <div className="carouselbx fulheight mx-3 p-3" key={key}>
                <CampaignCard data={data} />
              </div>
            ))}
          </Carousel>
        ) : (
          <div>
            <div className="py-5">
              <img src={Binocular} />
              <p className="font-weight-bold">
                There’s nothing to see here yet!
              </p>
            </div>
          </div>
        )}
        {/* <Link to={'/company'} className="vmblu-btn mt-5 btn">
          View All
        </Link> */}
        <Link to="/company" style={{ textDecoration: 'none' }}>
          <Button type="button" className="btn signbtn">
            View All
          </Button>
        </Link>
      </Container>
    </div>
  );
};

export default FundraiserPage;

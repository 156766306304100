import { Container, Image, Stack } from 'react-bootstrap';
import MainLayout from 'layout/MainLayout';
import banner from 'assets/images/privacy/banner.png';

const Privacy = () => {
  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <div className="text-center py-4">
          <h1 className="font-weight-bold py-2">Privacy Statement</h1>
        </div>
        <Container>
          <div className="iacbox">
            <div className="overflow-hidden small-brdrds mb-4">
              <Image
                src={banner}
                alt=""
                height={'540px'}
                width={'1280px'}
                className="rounded-4"
              />
            </div>
            <div className="text-start">
              <div>
                <p>
                  This Privacy Statement sets forth Round One’s approach with
                  respect to the information that is collected from the Users as
                  well as the visitors of this Website. This also provides for
                  the rights of Round One over the information collected
                  regardless of the means that is used to access this Website
                  and the Services. Unless otherwise provided, capitalized terms
                  in this Privacy Statement shall have the definitions provided
                  in the Terms.
                </p>
                <p>
                  By using the Services, you consent to the collection, storage,
                  processing, transferring, disclosure, and another usage of the
                  information described in this Privacy Statement. If you do not
                  agree with the terms of this Privacy Statement, please do not
                  use this Website or Services, nor provide us any information.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  1. Information We Collect
                </span>
                <Container>
                  <p>
                    Round One may collect, store, and transfer the following
                    information when you use the Services through the following
                    methods:
                  </p>
                  <span>a. Information Provided to Us</span>
                  <p>
                    Personal and identifying data will be requested of all Users
                    mostly in connection with, but not limited, regulatory
                    requirements for account opening. Issuers are solely
                    responsible for the content of their public disclosures
                    containing (for instance, their customer’s) personal data as
                    covered by their own privacy policies. Information we
                    collect may include information [2] such as:
                  </p>
                  <ul>
                    <li>
                      Identity data, such as your name, gender, and date of
                      birth;
                    </li>
                    <li>
                      Contact data, such as billing address, delivery address,
                      email address, and phone numbers;
                    </li>
                    <li>Photograph;</li>
                    <li>
                      Account data, such as bank account and payment details;
                    </li>
                    <li>
                      Valid IDs, such as passport, SSS ID, TIN, voter’s ID, and
                      other government-issued IDs;
                    </li>
                    <li>
                      Financial Information, such as the source of income;
                      and/or
                    </li>
                    <li>
                      Transaction data, such as details about payments to and
                      from you, and other details of transactions you have
                      entered through the Website.
                    </li>
                  </ul>
                </Container>
                <span className="font-weight-bold">
                  b. Information from the Use of Our Services
                </span>
                <Container>
                  <p>
                    We may be required to collect personal information when you
                    contact Round One or Intermediary personnel. The team also
                    collects information from the use of our Services, related
                    services and metadata about your device, such as:
                  </p>
                  <ul>
                    <li>
                      Technical data, such as Internet protocol (IP) address,
                      your login data, browser type and version, time zone
                      setting and location, browser plug-in types and versions,
                      operating system and platform, international mobile
                      equipment identity, device identifier, and other
                      information and technology on the devices you use to
                      access the Services;
                    </li>
                    <li>
                      Usage data, such as information on how you use the
                      Services and how you view any content on the Website;
                    </li>
                    <li>
                      Data required to verify your identity when you contact us,
                      such as name, birthdate, address.
                    </li>
                  </ul>
                </Container>

                <span className="font-weight-bold">c. Cookies 🍪</span>
                <Container>
                  <p>
                    The Website uses cookies to improve its features and
                    provides its visitors and users with tailored experience.
                    Cookies are small data files that track, save, and store
                    information when you visit the Website and use the service.
                  </p>
                </Container>

                <span className="font-weight-bold">
                  d. Information from Third Parties
                </span>
                <Container>
                  <p>
                    In conducting certain due diligence measures we may collect
                    information that is publicly available or provided by third
                    party sources. In some instances, we may collect data about
                    you in order to determine your qualification and eligibility
                    for certain Services. We may collect data from financial
                    institutions, financing companies, lenders, your employer,
                    telecommunication companies, utility companies, government
                    agencies, credit bureaus, financial service providers and
                    other service providers. The information collected will
                    depend on the requirements for the Services that you want to
                    avail. If you share personal data regarding third parties,
                    you warrant that you have the consent of said third parties
                    to share their personal data to Round One and the third
                    parties which we may share such data to (as indicated in
                    this Privacy Statement) and for their personal data to be
                    processed in the manner described in this Privacy Statement.
                  </p>
                </Container>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  2. Data Protection Guidelines
                </span>
                <Container>
                  <p>
                    In line with the law and good business practice, we are
                    guided by the following general principles of the Data
                    Privacy Act of 2012 (R.A. No. 10172):
                  </p>
                </Container>
                <span>Personal Data must be</span>
                <Container>
                  <ol type="a">
                    <li>
                      Collected and processed for specifically stated and
                      legitimate purposes, in a manner that is both fair and
                      lawful;
                    </li>
                    <li>Kept current, up to date and accurate;</li>
                    <li>Relevant & adequate for the purposes stated;</li>
                    <li>Kept for only as long as is necessary; and</li>
                    <li>Retained in a secure manner.</li>
                  </ol>
                </Container>
                <hr className="border-2 border-top border-black" />
                <span className="font-weight-bold">
                  2. How We Use Information That We Collect
                </span>
                <p>
                  Your information may be processed, profiled or shared for the
                  purposes of:
                </p>
                <Container>
                  <ol type="a">
                    <li>
                      To fulfill our contractual obligations to you and/or to
                      deliver Services you have opted to avail of;
                    </li>
                    <li>
                      To establish and verify your identity and to satisfying
                      our obligations pursuant to Securities and Exchangec.
                      Commission (“SEC”), and industry regulations on Customer
                      Account Information;
                    </li>
                    <li>
                      In compliance with mandates set forth by the Anti Money
                      Laundering Council of the Philippines;
                    </li>
                    <li>
                      To establish your eligibility to use our products and
                      services;
                    </li>
                    <li>
                      To facilitate our business services such as your email
                      address to deliver notices or your bank account to manage
                      your funds;
                    </li>
                    <li>
                      To carry out fund transfers and payment instructions;
                    </li>
                    <li>To conceptualize and run advertising campaigns;</li>
                    <li>
                      To allow effective communication among users and the
                      larger crowdfunding community;
                    </li>
                    <li>
                      To improve the usability and functionality of our products
                      and services;
                    </li>
                    <li>
                      To conduct market research, statistical analysis, and data
                      science activities;
                    </li>
                    <li>
                      To address consumer complaints or customer services
                      requirements;
                    </li>
                    <li>
                      To prevent fraud and to investigate possible legal and
                      contractual obligations which may have been violated by
                      You or other users. In such cases, we may cooperate with
                      other users, and with law enforcement agencies and aid in
                      investigations;
                    </li>
                    <li>To establish and defend legal rights;</li>
                    <li>To conduct audits;</li>
                    <li>
                      To manage and facilitate relations with our group
                      companies and investors;
                    </li>
                    <li>
                      To enhance the overall user experience of our Services;
                      and
                    </li>
                    <li>
                      To fulfill any other purposes directly related to the
                      above-stated purposes.
                    </li>
                  </ol>
                </Container>
                <p>
                  We will not process your personal information in ways
                  incompatible with the above-stated purposes.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  4. Accuracy and Retention of Personal Data
                </span>
                <p>
                  Data is retained for the duration of the use of our products
                  and services. Upon closure of your account, we are obligated
                  to retain all personal, portfolio and transaction data for a
                  period of three to five (2-5) years under the Securities
                  Regulation Code (“SRC”) Rules.
                </p>
                <p>
                  Certain Books of Accounts that may contain personal and
                  transaction data may also be retained by us for a period up to
                  ten (10) years as required by the Bureau of Internal Revenue.
                </p>
                <p>
                  Records involved in connection to any SEC investigation or
                  ongoing legal investigation or court case may be retained for
                  a period of time up to ten (10) years or for as long as
                  reasonably necessary for which it was collected or to comply
                  with Round One’s legal obligations, resolve disputes, and
                  enforce any of its existing agreements.
                </p>
                <p>
                  Round One is not responsible for the accuracy, completeness,
                  appropriateness, or legality of any uploaded files and the
                  information that you provided in order to access and use the
                  Services.
                </p>
                <p>
                  It is the responsibility of the user to ensure that all
                  personal data provided to the Intermediary is current and up
                  to date. The user shall notify the Intermediary of any
                  inaccuracies they may encounter with regards to their personal
                  data on this Website.
                </p>
                <p>
                  You agree and understand that we may transfer your personal
                  data to offshore locations for storage purposes. The offshore
                  locations shall be such places where our network providers or
                  cloud service providers have servers. We shall ensure that the
                  personal data will be stored and processed pursuant to the
                  Philippine Data Privacy Act of 2012.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  5. Information We Share
                </span>
                <p>
                  Round One may contract trusted third parties who assist in
                  operating and maintaining the Website and Services, conducting
                  Round One’s business, and providing service to you, as well as
                  for the purposes mentioned in Section 2 of this Privacy
                  Statement.
                </p>
                <p>
                  Third parties to whom we may share information include the
                  following:
                </p>
                <Container>
                  <ol type="a">
                    <li>Other users of the Services;</li>
                    <li>
                      Government agencies and regulators such as but not limited
                      to the Securities and Exchange Commission, the Anti-Money
                      Laundering Council, the Department of Trade and Industry,
                      the Bureau of Internal Revenue, the Department of Justice;
                    </li>
                    <li>
                      Law enforcement agencies such as the National Bureau of
                      Investigation and the Philippine National Police;
                    </li>
                    <li>Securities brokers; exchanges;</li>
                    <li>Judicial and administrative bodies;</li>
                    <li>
                      Credit bureaus and providers that conduct credit scoring
                      services;
                    </li>
                    <li>
                      Telecommunications entities and communications service
                      providers;
                    </li>
                    <li>Cloud service providers and network administrators;</li>
                    <li>Private auditors and legal advisors;</li>
                    <li>Market research agencies;</li>
                    <li>
                      Bank and financial service providers, insurance companies,
                      and payments providers;
                    </li>
                    <li>
                      Other third party service providers and vendors which are
                      directly necessary to deliver the Services you availed of.
                    </li>
                  </ol>
                </Container>
                <p>
                  Round One intends to provide any such third parties only the
                  items of information that they need to perform specific
                  functions and requires them to keep those items of information
                  confidential and protect your information.
                </p>
                <p>
                  Where your personal data is shared, Round One subjects these
                  third parties to the same standard of protections mandated by
                  the Philippine Data Privacy Act of 2012. Personal data may be
                  provided to any tax, regulatory, or legal authority for the
                  purpose of audit or monitoring and/or other legal mandates.
                </p>
                <p>
                  Your personal data and data obtained from the use of our
                  Services may be shared in connection to significant
                  transactions, such as entry into local or foreign bourse or
                  material changes in Issuer ownership. In such cases, Round One
                  takes reasonable measures to ensure any shared data remains
                  secure and confidential.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">6. Your Rights</span>
                <p>
                  You may exercise your rights provided under the Philippine
                  Data Privacy Act of 2012:
                </p>
                <Container>
                  <ol type="a">
                    <li>
                      Right to be informed whether personal information
                      pertaining to him or her shall be, are being or have been
                      processed;
                    </li>
                    <li>
                      Right to be furnished the information required by the law
                      before the entry of his or her personal information into
                      the processing system of the personal information
                      controller, or at the next practical opportunity;
                    </li>
                    <li>
                      Right to reasonable access to, upon demand, the following:
                      (1) Contents of his or her personal information that were
                      processed; (2) Sources from which personal information
                      were obtained; (2) Names and addresses of recipients of
                      the personal information; (4) Manner by which such data
                      were processed; (5) Reasons for the disclosure of the
                      personal information to recipients; (6) Information on
                      automated processes where the data will or likely to be
                      made as the sole basis for any decision significantly
                      affecting or will affect the data subject; (7) Date when
                      his or her personal information concerning the data
                      subject were last accessed and modified; and (8) The
                      designation, or name or identity and address of the
                      personal information controller;
                    </li>
                    <li>
                      Right to dispute the inaccuracy or error in the personal
                      information and right to have it corrected immediately and
                      accordingly, unless the request is vexatious or otherwise
                      unreasonable;
                    </li>
                    <li>
                      Right to suspend, withdraw or order the blocking, removal
                      or destruction of his or her personal
                    </li>
                    <li>
                      Right to be indemnified for any damages sustained due to
                      such inaccurate, incomplete, outdated, false, unlawfully
                      obtained or unauthorized use of personal information; and
                    </li>
                    <li>
                      Right to file a complaint with the National Privacy
                      Commission (“NPC”) if you feel that your personal
                      information has been misused, maliciously disclosed, or
                      improperly disposed, or that any of your data privacy
                      rights have been violated.
                    </li>
                  </ol>
                </Container>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  7. Security of Your Data
                </span>
                <p>
                  Round One employs a variety of security measures to reduce and
                  mitigate the risk of data leaks or breaches, including but not
                  limited to encryption technology and (etc. etc.). Nonetheless,
                  Round One cannot fully guarantee the security of personal data
                  disclosed online. The User accepts the inherent risks of
                  engaging in online transactions and does not hold Round One
                  responsible for any breach of security apart from gross
                  negligence.
                </p>
                <p>
                  Round One shall take reasonable steps to protect any
                  information that you provide and to protect the same from any
                  loss, misuse, and unauthorized access, disclosure, alteration,
                  or destruction.
                </p>
                <p>
                  We restrict access to personal information and securely
                  destroy personal information when no longer needed. However,
                  no internet or e-mail transmission is ever fully secure or
                  error free. Therefore, you should take special care in
                  securing your account and the information you will send to
                  Round One. Also, when you use passwords, usernames, or other
                  special access features of the Website, it is your
                  responsibility to safeguard them.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">8. Revisions</span>
                <p>
                  Contents of this Privacy Statement may change as needed or as
                  directed by law. Changes may be communicated through email,
                  inbox or featured prominently on the site.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">9. Inquiries</span>
                <p>
                  If you have questions about this Privacy Statement and if you
                  wish to inspect or update the information that Round One holds
                  about you, please feel free to contact us at:
                </p>
                <span>MA. Wincel D. Chang</span>
                <p>(08) 242 4006</p>
                <a href="mailto:webmaster@example.com">compliance@eastern-sec.com</a>
                <p>Eastern Securities Development Corporation</p>
                <p>Unit 1701</p>
                <p>Tytana Plaza,</p>
                <p>Binondo, Manila</p>
              </Stack>
              <hr className="border-2 border-top border-black" />
              <Stack gap={2}>
                <span className="font-weight-bold">
                  10. Validity of Consent
                </span>
                <p>
                  This consent and authorization remain valid and subsisting for
                  a limited period consistent with the purposes above or until
                  otherwise revoked or cancelled in writing. You may inform the
                  Company of the specific personal information you do not want
                  to be processed beyond the requested purpose. The Company will
                  respect your request insofar as it is feasible to fulfill the
                  purpose for which the personal information was collected.
                </p>
              </Stack>
              <hr className="border-2 border-top border-black mb-5" />
            </div>
          </div>
        </Container>
       
    </>
  );
};

export default Privacy;

import React from 'react';
import { Row, Col, Image, Container, Button } from 'react-bootstrap';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import CompleteInfo from './CompleteInfo';
import IncompleteInfo from './IncompleteInfo';

const CampaignHighLights = () => {
  const { content, issuer, subscription } = useSelector((state) => state.content);

  const companyData = JSON.parse(
    _.find(issuer?.details, (data) => data?.sectionCode == 'COINF')?.jsonData ??
      '[]'
  );

  return (
    <>
      <Container className="overflow-hidden ">
        <Row className="my-4">
          <Col lg={6} lmd={6} sm={12}>
            <div className="" style={{ height: '400px' }}>
              <Carousel
                interval="1000"
                transitionTime="1000"
                // showThumbs={false}
                thumbWidth={80}
              >
                {content?.transactionCampaignGalleries?.map((data, key) => (
                  <div className="small-brdrds overflow-hidden" key={key} style={{height: 400, width: 700}}>
                    <img src={data?.url} style={{objectFit: "fill"}}/>
                    {/* <p className="legend">My Classic Still 1</p> */}
                  </div>
                ))}
              </Carousel>
            </div>
          </Col>
          <Col lg={6} lmd={6} sm={12}>
            {!_.isEmpty(content) &&
              (_.isNull(content?.campaignCode) ? (
                <IncompleteInfo content={content}/>
              ) : (
                <CompleteInfo content={content} companyData={companyData} subscription={subscription} />
              ))}
            {/* <div className='border-top py-4 mt-4'>
                            <h4 className='font-weight-bold'>
                                INVEST NOW!
                            </h4>
                            <Button type="button" className="vmblu-btn btn btn-primary inv-btn">INVEST</Button>
                        </div> */}
          </Col>
        </Row>
        <div className="d-flex align-items-center">
          <small className="blu-t p-1">
            <a
              href={`https://${content?.website}`}
              target="_blank"
              rel="noopener"
              className="lnkk"
            >
              https://{content?.website}
            </a>
          </small>
          <small className="text-secondary p-1">{content?.address}</small>
        </div>
        <hr />
      </Container>
    </>
  );
};

export default CampaignHighLights;

import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import Binocular from '../../assets/images/newIcons/Binocular.png';
import { convert } from 'html-to-text';

const HowtoInvest = () => {
  const { faqs, campaign, education } = useSelector((state) => state.home);

  return (
    <div className="viobgbx text-center">
      <div className="py-5">
        <h2 className="hd-titles">Learn more on how to invest</h2>
        <small>Take on our investment advice from our Round One Academy</small>
      </div>
      <Container className="pb-5">
        <Row>
          {_.isEmpty(education) ? (
            <div>
              <div className="py-5">
                <img src={Binocular} />
                <p className="font-weight-bold">
                  There’s nothing to see here yet!
                </p>
              </div>
            </div>
          ) : (
            education?.slice(0, 3)?.map((data, key) => (
              <Col lg={4} md={4} sm={12} className="mb-3" key={key}>
                <div className="carouselbx fulheight mx-3 p-3">
                  <div
                    className="carosize px-3"
                    style={{ backgroundImage: `url(${encodeURI(data?.bannerImageUrl)})` }}
                  >
                    <div className="d-flex justify-content-end top-icons-caro mt-2">
                      <Link href="#" className="shr-hvr">
                        <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                      </Link>
                    </div>
                    <div className="slf-overlay"></div>
                  </div>
                  <Container>
                    <div className="font-weight-bold text-left pt-2">
                      {data?.title}
                    </div>
                    <p className="text-left m-0">
                      <small>
                        <i className="fas fa-clock blutwo"></i>
                        <span className="p-2">
                          {moment(data?.createdOn).format('MMMM Do YYYY')}
                        </span>
                      </small>
                    </p>
                    <div className="d-flex flex-wrap mt-2">
                      <span className="smalltabs">Retail Investors</span>
                      <span className="smalltabs">Qualified Investor</span>
                    </div>
                    <p className="py-2 text-left m-0 txt-clamp">
                      <small>{convert(data?.content,{wordwrap: 500})}</small>
                    </p>
                    <div className="d-flex justify-content-between align-items-baseline">
                      <Link variant="link"  className="py-2 px-0 btn-link btn" to={`/learn/${data?.guid}`}>
                        Read More
                      </Link>
                    </div>
                  </Container>
                </div>
              </Col>
            ))
          )}
        </Row>
        <p className="pt-5">
          <small>Still have trouble learning?</small>
        </p>
        {/* <Button type="button" className="vmblu-btn">
          See FAQs
        </Button> */}
        {/* <Link to="/event" style={{ textDecoration: 'none' }}> */}
        <Button type="button" to={'/learn'} className="btn signbtn">
          See FAQs
        </Button>
        {/* </Link> */}
      </Container>
    </div>
  );
};

export default HowtoInvest;

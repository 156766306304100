
export const authenticateUser = (token) => {
    sessionStorage.setItem("token", token);
};

export const deauthenticateUser = () => {

};

export const isAuthenticated = (options) => {
    return sessionStorage.getItem('token') !== null;
};

export const getToken = () => {
    return sessionStorage.getItem("token");
};
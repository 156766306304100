import MainLayout from 'layout/MainLayout'
import React from 'react'
import AllEventsBanner from './AllEventsBanner'
import CardListings from './CardListings'
// import Cardlistinghorizontal from './Cardlistinghorizontal'
import FiltersEvent from './FiltersEvent'
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter'

const AllEventsPage = () => {
    return (
        <>
             
                <AllEventsBanner />
                <FiltersEvent />
                <CardListings />
                {/* <Cardlistinghorizontal /> */}
                <SubscribeNewsletter />
             
        </>
    )
}

export default AllEventsPage
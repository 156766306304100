import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import homeReducer from './home';
import contentReducer from './content';

const rootReducer = combineReducers({
    auth: authReducer,
    home: homeReducer,
    content: contentReducer,
});
  
export default rootReducer;
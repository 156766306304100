import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';

import carouselimg from '../../assets/images/sample1.png';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import CampaignCard from 'components/common/CampaignCards';

const OtherFundraiser = () => {
  const { campaign } = useSelector((state) => state.home);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Container>
        <div className="mt-5">
          <h2 className="hd-titles text-center">See Other Fundraisers</h2>
          <p className="sub-des">
            Explore investment-ready ventures and be a part of their story!
          </p>
        </div>
        {!_.isEmpty(campaign) && (
          <Carousel responsive={responsive}>
            {campaign?.campaigns?.data?.map((data, key) => (
              <div className="carouselbx fulheight mx-3 p-3" key={key}>
                <CampaignCard data={data} />
              </div>
            ))}
          </Carousel>
        )}
        <Link to={'/company'}>
          <Button type="button" className="vmblu-btn mb-5">
            View All
          </Button>
        </Link>
      </Container>
    </>
  );
};

export default OtherFundraiser;

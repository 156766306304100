import React, {useState} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Container, Form, Button } from 'react-bootstrap';
import { dates } from './constant';

import investimg from '../../assets/images/investbanner.jpg';

const AllNewsBanner = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [year, setYear] = useState(searchParams.get('year') || '');
    const [search, setSearch] = useState(searchParams.get('search') || '');
    const handleSearchPage = () => {
        setSearchParams({ page: 1, search: search, year: year });
    };
    
    return (
        <>
            <div className='mrgntp-hdr'></div>
            <div className='relbox'>
                <div className='mrgntp-hdr evntsbnr' style={{ backgroundImage: `url(${investimg})` }}>
                    <Container className='pb-5' style={{ top: '25%', position: 'relative' }}>
                        <div className='widhdr text-center relbox'>
                            <h2 className="text-white">
                                All News
                            </h2>
                            <p>
                                Find the news you’re looking for.
                            </p>
                        </div>
                        <div className='srchboxlrn relbox'>
                            <div className="input-group">
                                <input type="text" value={search} onChange={(event) => setSearch(event.target.value)} onKeyDown={(event) => { if (event.key === "Enter") { handleSearchPage() } }} className="form-control semi-bradius" placeholder="Serch your event here.." />
                                <div className="input-group-append mx-2">
                                    <Button type="button" onClick={handleSearchPage} className="srchbtn semi-bradius">Search</Button>
                                </div>
                            </div>
                            <div className='d-flex slctbox flex-row mt-1'>
                                <span className='text-info p-2'>
                                    Filter
                                </span>
                                <Form.Select size="sm" value={year} onChange={(e) => setYear(e.target.value)}>
                                    <option>Year</option>
                                    {dates?.map((data) => <option value={data}>{data}</option>)}
                                </Form.Select>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default AllNewsBanner
import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import ecosystem from '../../assets/images/pie-chart.svg';
import { Link } from 'react-router-dom';
// import corporateImg from '../../assets/images/corporate.png';

const OurEcosystemPage = () => {
  return (
    <>
      <div className="hidn-flw">
        <Container>
          <Row className="align-items-center">
            <Col lg={6} md={6} sm={12}>
              <div className="px-4">
                <div className="d-flex align-items-center">
                  <div>
                    <h5 className="chtext">Investors</h5>
                  </div>
                  <div>
                    <h5 className="chtext mt-5">
                      Financial Intermediaries
                      <br /> and Regulators
                    </h5>
                    <Image src={ecosystem} alt="" className="full-w p-4" height={350}/>
                    <h5 className="chtext mb-5">Private and Public Partners</h5>
                  </div>
                  <div>
                    <h5 className="chtext">
                      Issuers /<br />
                      Fundraisers{' '}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              {/* <Image src={corporateImg} alt="" className="full-w" /> */}
              <div className="left-alignbx relbox mb-5">
                <div className="blu-oe"></div>
                <h2 className="hd-titles-white px-3 py-2 mb-4">
                  Our Ecosystem
                </h2>
                <p className="blu-t py-4">
                  We work with partners who are passionate in building up the
                  Philippines’ start up and SME ecosystem with us!
                </p>
                <Link to="/contactus" style={{ textDecoration: 'none' }}>
                  <Button type="button" className="blu-btn">
                    Partner with us!
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className='lightgrn-brline'></div> */}
    </>
  );
};

export default OurEcosystemPage;

import { Container, Nav, Navbar, Button, Image } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';

const handleTab = (location) => {
  switch (_.first(location.pathname.toLowerCase().substring(1).split("/"))) {
    case 'raise':
      return 0;
    case 'invest':
      return 1;
    case 'learn':
      return 2;
    case 'contactus':
      return 3;
    default:
      return false;
  }
};

const HeaderNav = () => {
  const location = useLocation();

  const [isScrollInTop, setIsScrollInTop] = useState(true);
  const [value, setValue] = useState(handleTab(location));

  useEffect(() =>{
    setValue(handleTab(location))
  },[location.pathname])

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY ? setIsScrollInTop(false) : setIsScrollInTop(true);
    };

    window.addEventListener('scroll', handleScroll);
  }, []);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className={`bg-body-tertiary bg-hd navbarSticky ${
          !isScrollInTop ? 'navbarFixedToTop' : ''
        }`}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <Image src={logo} alt="logo" className="mr-auto logo-hd" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {!isTabletOrMobile && (
              <Nav className="me-auto">
                <Tabs
                  value={value}
                  orientation={isTabletOrMobile ? 'vertical' : ''}
                  sx={{
                    '& .MuiTabs-indicator': {
                      backgroundColor: '#2e2be2',
                    },
                    position: 'relative',
                    left: '10%',
                  }}
                >
                  <Tab
                    label="Raise"
                    as={Link}
                    to="/Raise"
                    className="nav-link"
                    sx={{
                      fontSize: '18px',
                      textTransform: 'none',
                      fontFamily: [
                        'Mulish',
                        'Segoe UI',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                      ].join(','),
                      '&:hover': {
                        color: '#2e2be2',
                        opacity: 1,
                      },
                      '&.Mui-selected': {
                        color: '#2e2be2',
                      },
                    }}
                    onMouseEnter={() => setValue(0)}
                    onMouseLeave={() => setValue(handleTab(location))}
                  />
                  <Tab
                    label="Invest"
                    as={Link}
                    to="/Invest"
                    className="nav-link"
                    sx={{
                      fontSize: '18px',
                      textTransform: 'none',
                      fontFamily: [
                        'Mulish',
                        'Segoe UI',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                      ].join(','),
                      '&:hover': {
                        color: '#2e2be2',
                        opacity: 1,
                      },
                      '&.Mui-selected': {
                        color: '#2e2be2',
                      },
                    }}
                    onMouseEnter={() => setValue(1)}
                    onMouseLeave={() => setValue(handleTab(location))}
                  />
                  <Tab
                    label="Learn"
                    as={Link}
                    to="/learn"
                    className="nav-link"
                    sx={{
                      fontSize: '18px',
                      textTransform: 'none',
                      fontFamily: [
                        'Mulish',
                        'Segoe UI',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                      ].join(','),
                      '&:hover': {
                        color: '#2e2be2',
                        opacity: 1,
                      },
                      '&.Mui-selected': {
                        color: '#2e2be2',
                      },
                    }}
                    onMouseEnter={() => setValue(2)}
                    onMouseLeave={() => setValue(handleTab(location))}
                  />
                  <Tab
                    label="Contact Us"
                    as={Link}
                    to="/ContactUs"
                    className="nav-link"
                    sx={{
                      fontSize: '18px',
                      textTransform: 'none',
                      fontFamily: [
                        'Mulish',
                        'Segoe UI',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                      ].join(','),
                      '&:hover': {
                        color: '#2e2be2',
                        opacity: 1,
                      },
                      '&.Mui-selected': {
                        color: '#2e2be2',
                      },
                    }}
                    onMouseEnter={() => setValue(3)}
                    onMouseLeave={() => setValue(handleTab(location))}
                  />
                </Tabs>
              </Nav>
            )}
            <Nav>
              {isTabletOrMobile && (
                <>
                  <Nav.Link
                    as={Link}
                    to="/Raise"
                    className={location.pathname === '/Raise' ? 'hd-colr text-decoration-underline' : ''}
                  >
                    Raise
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/Invest"
                    className={location.pathname === '/Invest' ? 'hd-colr text-decoration-underline' : ''}
                  >
                    Invest
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/learn"
                    className={location.pathname === '/learn' ? 'hd-colr text-decoration-underline' : ''}
                  >
                    Learn
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contactus"
                    className={
                      location.pathname === '/contactus' ? 'hd-colr' : ''
                    }
                  >
                    Contact Us
                  </Nav.Link>
                </>
              )}

              {/* <Nav.Link
                href="https://auth.v2.roundone.ph/"
                style={{ textDecoration: 'none' }}
              >
                Login
              </Nav.Link> */}
              <Link
                to="https://auth.v2.roundone.ph/"
                style={{ textDecoration: 'none' }}
              >
                <a className="btn btn-link login-link mx-3 my-1 text-decoration-none">
                  Login
                </a>
              </Link>
              <Link
                to="https://auth.v2.roundone.ph/registration"
                style={{ textDecoration: 'none' }}
              >
                <Button type="button" className="btn signbtn my-1">
                  Sign Up
                </Button>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default HeaderNav;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { requestFaqsTopic, requestIssuerDetails } from 'services/api/content';
import {
  requestPublicView,
  requestPublicNewsList,
  requestPublicEventList,
  requestEducationalMateriaList,
  requestFaqsList,
  requestSubCategory,
  requestTopicList,
} from 'services/api/home';

const initialState = {
  news: [],
  event: [],
  campaign: [],
  education: [],
  pageCount: 0,
  faqs: [],
  subFaqs: [],
  faqsTopic: [],
};

const home = createSlice({
  name: 'home',
  initialState,
  reducers: {
    initCampaign: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initNews: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initEvent: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initEducational: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initFaqs: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initSubFaqs: (state) => {
      state.fetching = true;
      state.error = null;
    },
    initFaqsTopic: (state) => {
      state.fetching = true;
      state.error = null;
    },
    successCampaign: (state, { payload }) => {
      state.campaign = payload;
      state.fetching = false;
      state.error = null;
    },
    successNews: (state, { payload }) => {
      state.news = payload.data;
      state.pageCount = payload.pageCount;
      state.fetching = false;
      state.error = null;
    },
    successEvent: (state, { payload }) => {
      state.event = payload.data;
      state.pageCount = payload.pageCount;
      state.fetching = false;
      state.error = null;
    },
    successEducational: (state, { payload }) => {
      state.education = payload.data;
      state.pageCount = payload.pageCount;
      state.fetching = false;
      state.error = null;
    },
    successFaqs: (state, { payload }) => {
      state.faqs = payload;
      state.fetching = false;
      state.error = null;
    },
    successSubFaqs: (state, { payload }) => {
      state.subFaqs = payload;
      state.fetching = false;
      state.error = null;
    },
    successFaqsTopic: (state, { payload }) => {
      state.faqsTopic = payload;
      state.fetching = false;
      state.error = null;
    },
    errorCampaign: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorNews: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorEvent: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorEducational: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorFaqs: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorSubFaqs: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
    errorFaqsTopic: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
  },
});

export const {
  initCampaign,
  initNews,
  initEvent,
  initEducational,
  initFaqs,
  initSubFaqs,
  initFaqsTopic,
  successCampaign,
  successNews,
  successEvent,
  successEducational,
  successFaqs,
  successSubFaqs,
  successFaqsTopic,
  errorCampaign,
  errorNews,
  errorEvent,
  errorEducational,
  errorFaqs,
  errorSubFaqs,
  errorFaqsTopic,
} = home.actions;

export const getCampaign = (page, search) => async (dispatch) => {
  dispatch(initCampaign());

  const params = {
    orderBy: 'createdOn',
    isDescending: true,
    pageNumber: page ?? 1,
    rowPerPage: 6,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message } = await requestPublicView(params);

  if (success) {
    dispatch(successCampaign(data));
  } else {
    dispatch(errorCampaign(message));
  }
};

export const getNews = (page, search) => async (dispatch) => {
  dispatch(initNews());

  const params = {
    // orderBy: 'createdOn',
    isDescending: true,
    pageNumber: page ?? 1,
    rowPerPage: 6,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message, totalCount } = await requestPublicNewsList(
    params
  );

  if (success) {
    dispatch(successNews({ data: data, pageCount: totalCount }));
  } else {
    dispatch(errorNews(message));
  }
};

export const getEvent = (page, search) => async (dispatch) => {
  dispatch(initEvent());

  const params = {
    orderBy: 'createdOn',
    isDescending: true,
    pageNumber: 1,
    rowPerPage: 1000,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message, totalCount } = await requestPublicEventList(
    params
  );

  if (success) {
    dispatch(
      successEvent({
        data: data.sort((a, b) => {
          const dateA = new Date(_.first(a?.eventschedules)?.startDateTime);
          const dateB = new Date(_.first(b?.eventschedules)?.startDateTime);
          return dateB - dateA;
        }),
        pageCount: totalCount,
      })
    );
  } else {
    dispatch(errorEvent(message));
  }
};

export const getEducation = (page, search, tags) => async (dispatch) => {
  dispatch(initEducational());

  const params = {
    orderBy: '',
    isDescending: true,
    pageNumber: 1,
    rowPerPage: 1000,
    search: search ?? '',
    advanceFilter: {
      educationalMaterialCategoryName: '',
    },
  };

  const { success, data, message, totalCount } =
    await requestEducationalMateriaList(params);

  if (success) {
    dispatch(successEducational({ data: data, pageCount: totalCount }));
  } else {
    dispatch(errorEducational(message));
  }
};

export const getFaqs = (search) => async (dispatch) => {
  dispatch(initFaqs());

  const params = {
    orderBy: '',
    isDescending: true,
    pageNumber: 1,
    rowPerPage: 100,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message } = await requestFaqsList(params);

  if (success) {
    dispatch(successFaqs(data));
  } else {
    dispatch(errorFaqs(message));
  }
};

export const getSubFaqs = (search) => async (dispatch) => {
  dispatch(initSubFaqs());

  const params = {
    orderBy: 'rank',
    isDescending: false,
    pageNumber: 1,
    rowPerPage: 100,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message } = await requestSubCategory(params);

  if (success) {
    dispatch(successSubFaqs(data));
  } else {
    dispatch(errorSubFaqs(message));
  }
};

export const getFaqsTopic = (search) => async (dispatch) => {
  dispatch(initFaqsTopic());

  const params = {
    orderBy: '',
    isDescending: true,
    pageNumber: 1,
    rowPerPage: 100,
    search: search ?? '',
    advanceFilter: {},
  };

  const { success, data, message } = await requestTopicList(params);

  if (success) {
    dispatch(successFaqsTopic(data));
  } else {
    dispatch(errorFaqsTopic(message));
  }
};

export default home.reducer;

import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { requestToken } from 'services/api/home';
import { authenticateUser } from 'utils/session';

const initialState = {
  token: [],
  fetching: false,
  error: null,
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initAuth: (state) => {
      state.fetching = true;
      state.token = null;
    },
    successAuth: (state, { payload }) => {
      state.token = payload;
      state.fetching = false;
    },
    errorAuth: (state, { payload }) => {
      state.fetching = false;
      state.error = { message: payload };
    },
  },
});

export const {
  initAuth,
  successAuth,
  errorAuth,
} = auth.actions;

export const getAuth = () => async (dispatch) => {
  dispatch(initAuth());
  const { success, message, data  } = await requestToken();

  if (success) {
    dispatch(successAuth(data?.access_token));
    authenticateUser(data?.access_token);
  } else {
    dispatch(errorAuth(message));
  }
};

export default auth.reducer;

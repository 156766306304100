import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import bookcover from '../../assets/images/eastern-block.jpg';

const Commit = () => {
    return (
        <>
            <div className='sfbg hidn-flw'>
                <Row>
                    <Col lg={3} md={3} sm={12}>
                        <Image src={bookcover} alt="" className="full-w" />
                    </Col>
                    <Col lg={9} md={9} sm={12}>
                        <div className='details-cntrmid'>
                            <h2 className='hdsf-titles text-primary'>
                                We commit to uphold the highest standards of integrity
                            </h2>
                            <small>
                                Created and managed by Eastern Securities and Development Corporation, a member of the Philippines Stock Exchange since 1970s. SEC Crowdfunding License #75952
                            </small>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Commit
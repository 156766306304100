import React from 'react';
import HeaderNav from './nav';
import Footer from './footer';
import { useSelector } from 'react-redux';
// import ScrollToTop from 'react-scroll-to-top'
import { Container, Button, Carousel, Image, Alert } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


function MainLayout({ children }) {
  const { fetching } = useSelector((state) => state.content);
  const [show, setShow] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    if(scrollPosition > 750 && show)
      setShow(false)
  }, [scrollPosition])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
        <div>
          <HeaderNav />
          <div className={!fetching ? 'screen' : 'fade-out-screen'}>
            <div className="dot-flashing" />
          </div>
          <div>
            {children}
          </div>
          {show &&
          <div className="fixbox alertbx">
          <Alert
            variant="danger"
            onClose={() => setShow(false)}
            className="m-0"
            dismissible
          >
            <div className="d-flex justify-content-center ">
              <span className="p-1">
                <i className="fas fa-hand-paper"></i>
              </span>
              <Alert.Heading>RISK WARNING</Alert.Heading>
              <span className="p-1">
                <i className="fas fa-hand-paper"></i>
              </span>
            </div>
            <p className='font-weight-bold'>
              Crowdfunded investments are alternative investments and are thus
              generally considered high risk and speculative in nature. You are
              likely not to receive dividends or regular income from such
              investments. There is a substantial chance that you may lose the
              entirety of your investment. Fundraiser and campaigns listed or
              eligible for listing on the Round One platform are not
              endorsements by Round One regarding investability and may not be
              construed as financial advice. We caution you to speak to your
              financial advisor on how to best allocate your portfolio based on
              your needs and objectives. For additional information, check out
              our <Link to={'/riskwarning'}>Risk Warnings.</Link>
            </p>
            <small>(Click <strong>(x)</strong> to hide this Notification)</small>
          </Alert>
        </div>
        }
          <Footer />
        </div>
    </>
  );
}

export default MainLayout;

import React, { useEffect } from 'react';
import { Container, Image, Button, Accordion, Row, Col } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import { Link, useParams, useLocation } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';
import { getEducation } from 'store/reducers/home';
import { getEducationalContent } from 'store/reducers/content';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import round1 from '../../assets/images/RoundOne1-symbol.svg';
import event01 from '../../assets/images/sample1.png';
import carouselimg from '../../assets/images/sample1.png';

const LearnContentPage = () => {
  let { guid } = useParams();
  const { content } = useSelector((state) => state.content);
  const { education } = useSelector((state) => state.home);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEducation());
    dispatch(getEducationalContent(guid));
  }, [pathname]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <div className="text-center py-4">
          <div className="d-flex flex-row justify-content-center align-items-center">
            <h5 className="font-weight-bold text-primary m-0">
              {content?.educationalMaterialCategoryName}
            </h5>
            {/* <span className="font-weight-bold px-2">/ 04 of 04</span> */}
          </div>
          <h2 className="font-weight-bold py-3">{content?.title}</h2>
          <div className="d-flex flex-row justify-content-center align-items-center">
            <Image
              src={round1}
              alt=""
              className="social-rlogo border p-1 m-0"
            />
            <span className="font-weight-bold px-2">{content?.createdBy}</span>
            <span className="text-muted">
              {moment(content?.updatedOn ?? content?.createdOn).format(
                'MMMM D, YYYY'
              )}
            </span>
            <span className="text-muted px-2">3 min read</span>
            <i className="fas fa-share-alt"></i>
          </div>
        </div>
        <Container>
          <div className="iacbox">
            <div className="overflow-hidden small-brdrds mb-4">
              <Image src={content?.bannerImageUrl} alt="" />
            </div>
            <div className="left-alignbx">
              <div dangerouslySetInnerHTML={{ __html: content?.content }} />
              {/* <h2 className='font-weight-bold text-secondary py-3'>
                                For Fundraisers and Investors
                            </h2> */}
              {/* <div className='accbx'>
                                <Accordion defaultActiveKey={['0']} alwaysOpen>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>1) Issuer/Fundraiser’s Campaign Fees</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>2) Break Fee & Penalty</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header>3) Transaction Fees for Secondary Sales & Share Buy Backs</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="4">
                                        <Accordion.Header>4) Registration Fees</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="5">
                                        <Accordion.Header>5) Miscellaneous Charges & Fees </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="6">
                                        <Accordion.Header>6) Sanctions & Penalties</Accordion.Header>
                                        <Accordion.Body>
                                            <div className='left-alignbx'>
                                                <small>7.5% Success Fee</small>
                                                <p>
                                                    <small>
                                                        Payable upon successful campaign completion, deductible from funds raised through the platform prior to remittance to the issuer/fundraiser.
                                                    </small>
                                                </p>
                                            </div>
                                            <div className='left-alignbx mt-3'>
                                                <i className="fas fa-star text-warning"></i>
                                                <span className='px-2'>
                                                    Sample Computation of an Issuer raising Php 1,000,000.00 through the platform:
                                                </span>
                                                <div className='mx-4 my-3'>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Success Fee</Col>
                                                        <Col>: Php 75,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>VAT</Col>
                                                        <Col>: Php 9,000.00</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col className='border-bottom border-secondary pb-3'>WH Tax*</Col>
                                                        <Col className='border-bottom border-secondary pb-3'>: (Php 11,250.00)</Col>
                                                    </Row>
                                                    <Row xs={4} md={6} lg={6}>
                                                        <Col>Net Funds to Issuer</Col>
                                                        <Col>: Php 927,250.00</Col>
                                                    </Row>
                                                    <p className='py-3'>
                                                        <small>
                                                            *BIR Form 2307 for certification of withholding taxes remittance to the BIR
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div> */}
              <div className="my-5">
                <div className="my-3 text-center">
                  <h2 className="hd-titles">Related Learning Materials</h2>
                </div>
                <Carousel responsive={responsive}>
                  {education?.map((data, key) => (
                      <div className="carouselbx fulheight mx-3 p-3">
                        <div
                          className="carosize px-3"
                          style={{
                            backgroundImage: `url(${encodeURI(data?.bannerImageUrl)})`,
                          }}
                        >
                          <div className="d-flex justify-content-between align-items-baseline">
                            <div></div>
                            <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                              <Link href="#" className="shr-hvr">
                                <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                              </Link>
                            </div>
                          </div>
                          <div className="slf-overlay"></div>
                        </div>
                        <Container className="left-alignbx">
                          <h6 className="font-weight-bold my-3">
                            {data?.subTitle}
                          </h6>
                          <div className="d-flex align-items-center">
                            <i className="fas fa-clock text-primary"></i>
                            <small className="px-2">
                              {moment(data?.createdOn).format('MMMM Do YYYY')}
                            </small>
                          </div>
                          <div className="d-flex flex-wrap mt-2">
                            <span className="smalltabs">
                              {data?.educationalMaterialCategoryName}
                            </span>
                          </div>
                          <p className="py-2 text-left m-0 txt-clamp">
                            <small>
                              {data?.content?.replace(/<[^>]+>/g, '')}
                            </small>
                          </p>
                          <Link
                            to={`/learn/${data?.guid}`}
                            className="py-2 px-0 btn-link btn"
                          >
                            Read More
                          </Link>
                        </Container>
                      </div>
                  ))}
                </Carousel>
                <div className="my-5">
                  <span>Still have more questions?</span>
                  <div className='text-center'>
                    <Link
                      type="button"
                      to={'/learn'}
                      className="vmblu-btn btn"
                    >
                      See FAQs
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </Container>
        <SubscribeNewsletter />
       
    </>
  );
};

export default LearnContentPage;

import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';

import hand from '../../assets/images/risk-statement.svg';

const RiskStatement = () => {
    return (
        <div className='py-5'>
            <h4 className='font-weight-bold py-4 colortxt-rsk text-center'>
                RISK STATEMENT
            </h4>
            <Container>
                <div className='wid-svn mx-auto'>
                    <Row className='aligm-items-center'>
                        <Col lg={3} md={3} sm={12}>
                            <Image src={hand} alt="" className='mb-2 full-w' />
                        </Col>
                        <Col lg={9} md={9} sm={12}>
                            <div className='left-alignbx'>
                                <p>
                                    Crowdfunded investments are alternative investments and are thus generally considered high risk and speculative in nature. You are likely not to receive dividends or regular income from such investments. There is a substantial chance that you may lose the entirety of your investment. Fundraiser and campaigns listed or eligible for listing on the Round One platform are not endorsements by Round One regarding investability and may not be construed as financial advice. We caution you to speak to your financial advisor on how to best allocate your portfolio based on your needs and objectives. For additional information, check out our Risk Warnings.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default RiskStatement
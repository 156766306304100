import React, { Fragment } from 'react';
import { Container, Carousel, Button, Row, Col, Stack } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment/moment';
import { useLocation } from 'react-router-dom';
import ShareButton from '../ShareButton';

const CampaignCard = ({ data }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: 0,
  });

  const location = useLocation();

  return (
    <Link
      to={`/company/${data?.guid}`}
      className="text-decoration-none text-dark"
    >
      <div
        className="carosize px-3"
        style={{
          backgroundImage: `url(${data?.campaignBanner})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        <div className="d-flex justify-content-between align-items-baseline">
          <div>
            {(data?.totalRaisedAmount / data?.totalOffering) * 100 >= 80 && (
              <Link href="#" className="pplink">
                Almost Sold Out
              </Link>
            )}
          </div>
          <ShareButton link={`/company/${data?.guid}`} />
        </div>
        <div className="slf-overlay"></div>
        <div className="avatarbx p-2">
          <div className="d-flex flex-wrap align-items-center relbox">
            <div
              className="caro-avatar relbox mx-3"
              style={{
                backgroundImage: `url(${data?.issuerImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
              }}
            ></div>
            <small className="name-btm">{data?.nameOfIssuer}</small>
          </div>
        </div>
      </div>

      <Container>
        <p className="py-2 text-left m-0 txt-clamp" style={{ height: '5em' }}>
          <small>{data?.campaignHighlights?.replace(/<[^>]+>/g, '')}</small>
        </p>
        {/* <div className="d-flex align-items-center">
          <i className="fas fa-map-marker-alt text-primary p-2"></i>
          <small>{data?.issuerMapAddress}</small>
        </div> */}
        {!_.isEqual(location.pathname, '/company') && (
          <Fragment>
            <div className="d-flex flex-wrap mt-2" style={{ height: '2em' }}>
              {!_.isEmpty(data?.campaignIndustry) && (
                <span className="smalltabs">{data?.campaignIndustry}</span>
              )}
              {!_.isEmpty(data?.campaignInstrumentType) && (
                <span className="smalltabs">
                  {data?.campaignInstrumentType}
                </span>
              )}
            </div>
            <div className=" mt-auto">
              <div className="d-flex align-items-center py-2">
                <small>Pre-money Valuation {data?.preMoneyValuation}</small>
              </div>
              <Row className="mb-3">
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column text-left">
                    <small>Target</small>
                    <small className="font-weight-bold">
                      {formatter.format(data?.totalOffering)}
                    </small>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column text-left">
                    <small>Amount raised:</small>
                    <small className="font-weight-bold">
                      {formatter.format(data?.totalRaisedAmount || 0)}
                    </small>
                  </div>
                </Col>
              </Row>
              <div className="progress v-prg">
                <div
                  className="progress-bar sqr-rmid"
                  role="progressbar"
                  style={{
                    width: `${
                      data?.totalRaisedAmountPercentageByTotalOffering || '0%'
                    }`,
                  }}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="d-flex justify-content-between txtsmall">
                <div>
                  <i className="fas fa-clock p-1"></i>
                  <small className="p-1">
                    {moment(data?.offeredPeriodEndDate).diff(
                      moment(new Date()),
                      'days'
                    ) > 0
                      ? moment(data?.offeredPeriodEndDate).diff(
                          moment(new Date()),
                          'days'
                        )
                      : 0}{' '}
                    days left
                  </small>
                </div>
                <small>
                  {data?.totalRaisedAmountPercentageByTotalOffering || '0%'}
                </small>
              </div>
            </div>
          </Fragment>
        )}
      </Container>
    </Link>
  );
};

export default CampaignCard;

import React, { useEffect } from 'react';
import { Container, Image, Button } from 'react-bootstrap';
import { getNews } from 'store/reducers/home';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from 'react-multi-carousel';
import { Link, useParams, useLocation } from 'react-router-dom';
import MainLayout from 'layout/MainLayout';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';

import round1 from '../../assets/images/RoundOne1-symbol.svg';
import event01 from '../../assets/images/sample1.png';
import _ from 'lodash';
import carouselimg from '../../assets/images/sample1.png';
import { getNewsContent } from 'store/reducers/content';
import moment from 'moment';

const NewsContentPage = () => {
  let { guid } = useParams();
  const { content } = useSelector((state) => state.content);
  const { news } = useSelector((state) => state.home);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
    dispatch(getNewsContent(guid));
  }, [pathname]);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
     <>
      <div className="mrgntp-hdr"></div>
      <div className="text-center py-4">
        <h5 className="font-weight-bold text-primary">News</h5>
        <h2 className="font-weight-bold py-3">{content?.title}</h2>
        <div className="d-flex flex-row justify-content-center align-items-center">
          <Image src={round1} alt="" className="social-rlogo border p-1 m-0" />
          <span className="font-weight-bold px-2">{content?.createdBy}</span>
          <span className="text-muted">
            {moment(content?.createdOn).format('MMMM Do YYYY')}
          </span>
          {/* <span className='text-muted px-2'>3 min read</span> */}
          <i className="fas fa-share-alt"></i>
        </div>
      </div>
      <Container>
        <div className="iacbox">
          <div className="overflow-hidden small-brdrds mb-4">
            {!_.isEmpty(content) && (
              <Image src={_.first(content?.newUpdatesFiles)?.s3FileName} alt="" />
            )}
          </div>
          <div className="left-alignbx">
            <p dangerouslySetInnerHTML={{ __html: content?.content }} />
            {/* <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum aliquet suscipit urna non pulvinar. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam ex orci, fermentum fringilla scelerisque tristique, bibendum et lacus. Donec luctus eget ligula commodo tincidunt. In vel libero at tortor bibendum aliquet. Fusce nec massa odio. Nullam suscipit lectus a turpis egestas, non feugiat enim tristique. Praesent tortor tortor, suscipit nec cursus sit amet, elementum tincidunt tellus. Ut hendrerit gravida tristique. Etiam pellentesque efficitur nulla, dictum auctor nulla ornare mollis. Nullam ac aliquam eros, nec malesuada velit. Donec iaculis diam sapien, non porta ante pharetra non. Mauris placerat urna neque, rutrum scelerisque dolor faucibus et. Vivamus placerat sem ac sem consequat auctor. Proin varius porttitor sem id commodo.
                            </p>
                            <p>
                                Integer tincidunt, lorem eu elementum lacinia, magna sapien mollis massa, id tincidunt ante eros at diam. Suspendisse elit purus, vestibulum a tempor et, venenatis sit amet risus. Fusce vestibulum efficitur tellus ut blandit. Pellentesque vitae orci vitae nisi consequat bibendum. Sed molestie auctor nisl, ornare malesuada nulla laoreet eget. Morbi molestie, dolor sed venenatis rutrum, odio leo consectetur risus, non auctor leo sapien eu sapien. Morbi rutrum accumsan ornare. Pellentesque auctor sed arcu in consequat. Proin a metus et magna pulvinar vestibulum. Nam venenatis cursus odio.
                            </p>
                            <p>
                                Nulla non ante nec sapien porta scelerisque eu ut nunc. Aliquam ac libero quis lorem vulputate pulvinar. Etiam velit lectus, feugiat quis lorem vel, viverra vulputate quam. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus in mauris et mauris fermentum finibus quis non augue. Vestibulum at tellus nec lorem lobortis consequat vel vitae risus. Pellentesque ac blandit sapien, nec vulputate mi. Pellentesque vestibulum aliquam dolor, sed vestibulum magna pellentesque sed. Donec vitae felis eu quam faucibus interdum sit amet ut lacus. Curabitur ultricies ex lorem, vitae elementum urna vulputate ut. Curabitur ut quam nunc. Sed scelerisque justo at eleifend venenatis.
                            </p> */}
            <div className="mt-5">
              <small className="font-weight-bold">Categories</small>
              <div className="d-flex flex-wrap mt-2">
                <span className="smalltabs">
                  {content?.newsUpdatesCategoryName}
                </span>
              </div>
            </div>
            <div className="my-5">
              <div className="my-3 text-center">
                <h2 className="hd-titles">Other News</h2>
              </div>
              <Carousel responsive={responsive}>
                {news?.map((data, key) => (
                  <div className="carouselbx fulheight mx-3 p-3" key={key}>
                    <div
                      className="carosize px-3"
                      style={{
                        backgroundImage: `url(${_.first(data?.newUpdatesFiles)?.s3FileName.replace(/\s/g,"%20")})`,
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-baseline">
                        <div>
                          {/* <Link href="#" className='semi-bradius pplink py-2 px-3'>Upcoming Event</Link> */}
                        </div>
                        <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                          <Link href="#" className="shr-hvr">
                            <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="slf-overlay"></div>
                    </div>
                    <Container className="left-alignbx">
                        <h6 className="font-weight-bold text-primary my-3 h-10" style={{height:"2em"}}>
                          {data?.title}
                        </h6>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-user-check text-primary text-small"></i>
                        <small className="px-2">{data?.createdBy}</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <i className="fas fa-clock text-primary text-small"></i>
                        <small className="px-2">
                          {moment(data?.createdOn).format('MMMM Do YYYY')}
                        </small>
                      </div>
                      <p className="py-2 text-left m-0 txt-clamp">
                        <small>{data?.content?.replace(/<[^>]+>/g, '')}</small>
                      </p>
                      <div className="d-flex">
                        <Link
                          variant="link"
                          to={`/news/${data?.guid}`}
                          className="p-0 text-small"
                        >
                          Read More
                        </Link>
                        {/* <Button type="button" className="lightblue-btn semi-bradius btn btn-sm mx-2 border-0">See Details</Button> */}
                      </div>
                    </Container>
                  </div>
                ))}
              </Carousel>
              <div className="text-center mt-5">
                <Link to={'/news'} className="vmblu-btn btn">
                  View All
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <SubscribeNewsletter />
     </>
  );
};

export default NewsContentPage;

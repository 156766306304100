import React from 'react';
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import youtube from '../../assets/images/youtube.jpg';
import time from '../../assets/images/stopwatch.svg';
import diligence from '../../assets/images/icon-hand check.svg';
import community from '../../assets/images/icon-puzzle.svg';

const RaisingFunds = () => {
  return (
    <div className="hopw-hbox">
      <Container>
        <h2 className="hd-titles text-center py-5">
          Raising Funds Through Round One
        </h2>
        <Row className="align-items-center">
          <Col lg={6} md={12} sm={12}>
            <div className="mb-5">
              {/* <Image src={youtube} alt="" className="full-w" /> */}

              <iframe
                className="yth"
                src="https://www.youtube.com/embed/uqvUQh1kH-k?si=M5qq_EtA5YKyzRW_"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="left-alignbx">
              <h3 className="hd-colr font-weight-bold">
                Benefits of Equity Crowdfunding:
              </h3>
              <small>
                Raise funds from investors online in exchange of ownership in
                their business.
              </small>
            </div>
            <Row className="text-center mt-3">
              <Col lg={4} md={4} sm={12}>
                <div className="fulheight">
                  <div className="top-insd-layer">
                    <Image src={time} alt="" className="env-sz mb-2" />
                    <h3>More Convenient</h3>
                  </div>
                  <p className="small">
                    Pitch, promote and manage investors all-in-one-place
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <div className="fulheight">
                  <div className="top-insd-layer">
                    <Image src={diligence} alt="" className="env-sz mb-2" />
                    <h3>Crowd Due Diligence</h3>
                  </div>
                  <p className="small">
                    Boost investor confidence by puting yourself out there
                  </p>
                </div>
              </Col>
              <Col lg={4} md={4} sm={12}>
                <div className="fulheight">
                  <div className="top-insd-layer">
                    <Image src={community} alt="" className="env-sz mb-2" />
                    <h3>Involve the Community</h3>
                  </div>
                  <p className="small">
                    Ask for support from investors to grow the company
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="text-center">
          <Link to="/learn" style={{ textDecoration: 'none' }}>
            {/* <Button
              type="button"
              className="vmblu-btn my-5 btn btn-primary"
              fdprocessedid="kphdfn"
            > */}
            <Button type="button" className="btn signbtn mb-5">
              Learn More
            </Button>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default RaisingFunds;

import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'

import key from '../../assets/images/equity-icon.png';
import debt from '../../assets/images/debt-icon.png';
import hybrid from '../../assets/images/hybrid-icon.png';

const CfSecurities = () => {
    return (
        <div className='numcbgdrk'>
            <Container>
                <h2 className='hd-titles-whte text-center py-5'>
                    Types of Crowdfunding Securities
                </h2>
                <Row className='pb-5'>
                    <Col lg={4} md={4} sm={12} className='mb-3'>
                        <div className='curvebx shdwbx fulheight mx-3'>
                            <div className='padd-med'>
                                <div className='justify-content-center my-4'>
                                    <Image src={key} alt="" className='cf-icons' />
                                    <h4 className='font-weight-bold pt-2'>
                                        Equity
                                    </h4>
                                </div>
                                <small>
                                    <p>Equity securities grant ownership rights of a company to investors. When the company earns a profit, the investor may be entitled to a dividend or a share in the company's growth.</p>
                                    <p>This type of security usually comes in the form of common shares, but depending on the terms and agreements of the campaign, may also be preferred shares.</p>
                                </small>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='mb-3'>
                        <div className='curvebx shdwbx fulheight mx-3'>
                            <div className='padd-med'>
                                <div className='justify-content-center my-4'>
                                    <Image src={debt} alt="" className='cf-icons' />
                                    <h4 className='font-weight-bold pt-2'>
                                        Debt
                                    </h4>
                                </div>
                                <small>
                                    <p>
                                        Also referred to as fixed-income securities, debt securities are created when investors lend money to a company for a fixed period of time in return for regular payments of interest.
                                    </p>
                                    <p>
                                        Interest rates are dependent on the creditworthiness of the fundraiser; and upon maturity, they must repay the principal loan.
                                    </p>
                                </small>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} className='mb-3'>
                        <div className='curvebx shdwbx fulheight mx-3'>
                            <div className='padd-med'>
                                <div className='justify-content-center my-4'>
                                    <Image src={hybrid} alt="" className='cf-icons' />
                                    <h4 className='font-weight-bold pt-2'>
                                        Hybrid
                                    </h4>
                                </div>
                                <small>
                                    <p>
                                        As the name suggests, hybrid securities are instruments that are a combination of both equity and debt, such as SAFE or convertible notes.
                                    </p>
                                    <p>
                                        This provides flexibility on both the company issuing the securities (i.e. the “issuer”) and investor’s end.
                                    </p>
                                </small>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CfSecurities
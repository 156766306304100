import React, { useEffect } from 'react';
import MainLayout from 'layout/MainLayout';
import AllCompaniesBanner from './AllCompanyBanner';
import MarketLocation from './MarketLocation';
// import FundraiserPage from 'components/homepage/fundraiser';
// import FundraiserSoon from './FundraiserSoon';
import HowtoInvest from 'pages/Invest/HowtoInvest';
import FiltersComapanies from './Filters';

const AllCompaniesPage = () => {
  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <AllCompaniesBanner />
        <FiltersComapanies />
        <MarketLocation />
        {/* <FundraiserPage />
                <FundraiserSoon /> */}
        <HowtoInvest />
       
    </>
  );
};

export default AllCompaniesPage;

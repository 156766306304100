import React, { useState } from 'react';
import { Container, Button, Row, Col, Form, Modal } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import investimg from '../../assets/images/investbanner.jpg';

const AllCompaniesBanner = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState(searchParams.get('search') || '');
    const handleSearchPage = () => {
        setSearchParams({ page: 1, search: search });
    };
    const [lgShow, setLgShow] = useState(false);

    return (
        <>
            <div className='relbox'>
                <div className='comp-bnr lrnbnr' style={{ backgroundImage: `url(${investimg})` }}>
                    <Container className='pb-5'>
                        <div className='widhdr text-center relbox'>
                            <h2 className="text-white">
                                All Companies
                            </h2>
                            <p>
                                Find the company you’re looking for.
                            </p>
                        </div>
                        <div className='srchbox relbox'>
                            <Row className='d-flex flex-row align-self-center'>
                                <Col lg={8} md={8} sm={12} className='p-0'>
                                    <div className="form-group has-search">
                                        <span className="fa fa-search form-control-feedback"></span>
                                        <input type="text" value={search} onChange={(event) => setSearch(event.target.value)} onKeyDown={(event) => { if (event.key === "Enter") { handleSearchPage() } }} className="form-control semi-bradius bg-light" placeholder="Type the company name here" />
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12}>
                                    <div className='d-flex flex-row btns-srch'>
                                        <div>
                                            <Button type="button" onClick={handleSearchPage} className="semi-bradius font-weight-bold srchbtn fulheight">Search</Button>
                                        </div>
                                        <div>
                                            <Button
                                                type="button"
                                                className="btn optionbtn semi-bradius lightgraybg d-flex flex-row align-items-center justify-content-around border-0"
                                                onClick={() => setLgShow(true)}
                                            >
                                                <i className="fas fa-server"></i> See Options
                                            </Button>
                                        </div>
                                        {/* <Button class="btn"><i className="fas fa-server"></i> Home</Button> */}
                                        {/* <i className="fas fa-server px-2"></i> */}
                                    </div>

                                </Col>
                            </Row>
                            <div className='d-flex slctbox flex-row mt-1'>
                                <span className='text-info p-2'>
                                    Filter
                                </span>
                                <Form.Select size="sm">
                                    <option>Industry</option>
                                </Form.Select>
                                <Form.Select size="sm">
                                    <option>Instrument Type</option>
                                </Form.Select>
                                <Form.Select size="sm">
                                    <option>Market Location</option>
                                </Form.Select>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header className='border-0 mdl-clsebtn' closeButton>
                    {/* <Modal.Title id="example-modal-sizes-title-lg">
                                                Large Modal
                                            </Modal.Title> */}
                </Modal.Header>
                <Modal.Body className='pt-0 pb-3'>
                    <div className='full-w p-3'>
                        <Row className='d-flex flex-row align-self-center'>
                            <Col lg={8} md={8} sm={12} className='p-0'>
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    <input type="text" className="form-control semi-bradius bg-light" placeholder="Search company name or person here" />
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12}>
                                <div className='d-flex flex-row btns-srch'>
                                    <div>
                                        <Button type="button" className="semi-bradius font-weight-bold srchbtn fulheight">Search</Button>
                                    </div>
                                    <div>
                                        <Button
                                            type="button"
                                            className="btn optionbtn semi-bradius bg-secondary d-flex flex-row align-items-center justify-content-around border-0"
                                            onClick={() => setLgShow(true)}
                                        >
                                            <i className="fas fa-server"></i> Hide Options
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className='py-3 px-4 border-bottom'>
                        <small className='font-weight-bold'>
                            Minimum Investment
                        </small>
                        {/* <Row>
                            <Col>
                                <span>P1,000 and below</span>
                            </Col>
                            <Col lg={6} md={6}>
                                <Form.Range />
                            </Col>
                            <Col>
                                <span>
                                    P100,000 and above
                                </span>
                            </Col>
                        </Row> */}
                        <Form>
                          {['checkbox'].map((type) => (
                            <div key={`inline-${type}`} className="mb-3">
                              <div className="d-flex flex-row flex-wrap">
                                <div>
                                  <Form.Check
                                    inline
                                    label="1 month or less"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-1`}
                                  />
                                </div>
                                <div className="mx-2">
                                  <Form.Check
                                    inline
                                    label="2-5 Months"
                                    name="group1"
                                    type={type}
                                    id={`inline-${type}-2`}
                                  />
                                </div>
                                <div>
                                  <Form.Check
                                    inline
                                    label="6 Months  or more"
                                    type={type}
                                    id={`inline-${type}-3`}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </Form>
                    </div>
                    <div className='py-3 px-4 border-bottom'>
                        <small className='font-weight-bold'>
                            Campaign Duration
                        </small>
                        <Form>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <div className='d-flex flex-row flex-wrap'>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="1 month or less"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-1`}
                                            />
                                        </div>
                                        <div className='mx-2'>
                                            <Form.Check
                                                inline
                                                label="2-5 Months"
                                                name="group1"
                                                type={type}
                                                id={`inline-${type}-2`}
                                            />
                                        </div>
                                        <div>
                                            <Form.Check
                                                inline
                                                label="6 Months  or more"
                                                type={type}
                                                id={`inline-${type}-3`}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Form>
                    </div>
                    <div className='py-3 px-4 border-bottom'>
                        <small className='font-weight-bold'>
                            Industry
                        </small>
                        <div className='d-flex flex-wrap'>
                            <span className='smalltabs'>Advertising & Marketing</span>
                            <span className='smalltabs'>Automative & Transport</span>
                            <span className='smalltabs'>Clothing & Accessories</span>
                            <span className='smalltabs'>Energy</span>
                            <span className='smalltabs'>Content & Information</span>
                            <span className='smalltabs'>Entertainment</span>
                            <span className='smalltabs'>Data & Analysis</span>
                            <span className='smalltabs'>Fintech</span>
                            <span className='smalltabs'>Food & Beverage</span>
                            <span className='smalltabs'>Games</span>
                            <span className='smalltabs'>Healthcare</span>
                            <span className='smalltabs'>Home & Personal</span>
                            <span className='smalltabs'>Recruitment & Procurement </span>
                            <span className='smalltabs'>Property</span>
                            <span className='smalltabs'>SaaS/PaaS</span>
                            <span className='smalltabs'>Programming & Security</span>
                            <span className='smalltabs'>Travel, Leisure & Sport</span>
                            <div className="form-group has-search m-1">
                                {/* <i class="fas fa-plus-circle"></i> */}
                                <span className="fas fa-plus-circle form-control-feedback plus-icon"></span>
                                <input type="text" className="form-control semi-bradius bg-light frm-indstry" placeholder="Add Industry" />
                            </div>
                        </div>
                    </div>
                    {/* <div className='py-3 px-4 border-bottom'>
                        <small className='font-weight-bold'>
                            Instrument Type
                        </small>
                        <div className='d-flex flex-wrap'>
                            <span className='smalltabs'>Equity</span>
                            <span className='smalltabs'>Debt</span>
                            <span className='smalltabs'>Convertible Note </span>
                            <span className='smalltabs'>SAFE</span>
                        </div>
                    </div> */}
                    <div className='py-3 px-4 border-bottom'>
                        <small className='font-weight-bold'>
                            Market Location
                        </small>
                        <div className='d-flex flex-wrap'>
                            <div className='m-1'>
                                <Form.Select size="sm" className='semi-bradius bgselect-drp'>
                                    <option>Nationwide</option>
                                </Form.Select>
                            </div>
                            <div className='m-1'>
                                <Form.Select size="sm" className='semi-bradius bgselect-drp'>
                                    <option>Region</option>
                                </Form.Select>
                            </div>
                            <div className='m-1'>
                                <Form.Select size="sm" className='semi-bradius' disabled>
                                    <option>Province</option>
                                </Form.Select>
                            </div>
                            <div className='m-1'>
                                <Form.Select size="sm" className='semi-bradius' disabled>
                                    <option>City</option>
                                </Form.Select>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-end p-3'>
                        <Button
                            type="button"
                            className="btn semi-bradius bg-secondary border-0"
                        >
                            Clear All
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AllCompaniesBanner
import React from 'react';
import {
  Container,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import cIcon1 from '../../assets/images/invest/legal_agreements_march_25_2024_updated-01.svg';
import cIcon2 from '../../assets/images/invest/investor_questionaire_march_25_2024_updated.svg';
import cIcon3 from '../../assets/images/secodnary-market-icon.svg';
import investtyp1 from '../../assets/images/invest/indv_investor-01.svg';
import investtyp2 from '../../assets/images/invest/inst_investors-01.svg';
import icndoc1 from '../../assets/images/invest/invest_icons_wire_trasnfer.svg';
import icndoc2 from '../../assets/images/invest/invest_icons_online_transfer.svg';
import icndoc3 from '../../assets/images/invest/invest_icons_cheque.svg';
import icndoc4 from '../../assets/images/invest/invest_icons_cash.svg';
import icndoc5 from '../../assets/images/invest/invest_icons_govt_id.svg';
import icndoc6 from '../../assets/images/invest/invest_icons_annual_tax.svg';
import icndoc7 from '../../assets/images/raise/icons_sec registration.svg';
import vrfd from '../../assets/images/invest/verified_march_25_update.svg';
import buildings from '../../assets/images/manila-buildings.svg';
import conference from '../../assets/images/conference.svg';
import webinar from '../../assets/images/webinar.svg';
import media from '../../assets/images/social-media.svg';
import investor from '../../assets/images/investor-open-house.svg';
import probar from '../../assets/images/investor-progress-bar.svg';
import emailc from '../../assets/images/invest/invest_icons_email_confirm.svg';
import template from '../../assets/images/active-invest-commitment.svg';
import template2 from '../../assets/images/commitment-details.svg';
import notif from '../../assets/images/invest/notif_icon_march_25_update.svg';
import market from '../../assets/images/invest/invest_icons_market.png';
import investmonitor from '../../assets/images/invest/discussion_panel_update_may_10_2024.svg';
import investc1 from '../../assets/images/invest-campaign01.svg';
import investc2 from '../../assets/images/invest-campaign02.svg';
import investc3 from '../../assets/images/invest-campaign03.svg';

const InvestorWalkthrough = () => {
  return (
    <>
      <div className="flowbx text-center text-white py-5">
        <h2 className="hd-titles-whte">Investor Walkthrough</h2>
        <small>
          A step-by-step guide to get started for you first investment.
        </small>
      </div>
      <div>
        <Container>
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">1</div>
              <h4 className="hd-colr font-weight-bold m-2">
                REGISTER AS AN INVESTOR
              </h4>
            </div>
            <div className="d-flex align-items-center cnterflxm mb-3">
              <small className="blu-t blu-t m-2">www.roundone.ph</small>
              <div className="shp-butn static-btn text-white">Sign Up</div>
            </div>
            <h5 className="font-weight-bold blutwo left-alignbx">
              INVESTOR TYPES
            </h5>
            <Row className="left-alignbx">
              <Col lg={6} md={4} sm={12}>
                <p>
                  <small>
                    Individual (non-corporate, natural persons) and
                    Institutional investors are two investor classes in the
                    Philippines and two account types you can access on our
                    platform.
                  </small>
                </p>
                <p>
                  <small>
                    Registering yourself as a{' '}
                    <a
                      href="https://roundone.ph/learn/3509d26c-f6b4-4ae0-a1d9-1b2e1afb9184"
                      target="_blank"
                      className='blu-t'
                    >
                      Qualified Investor
                    </a>{' '}
                    enables you to enjoy no annual limits on your investment
                    commitments. Qualified investors are also called
                    “professional” or “accredited” investors.
                  </small>
                </p>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="investor-types">
                  <Image
                    src={investtyp1}
                    alt=""
                    className="full-w"
                    height={100}
                  />
                  <p className="font-weight-bold hd-colr text-center">
                    Individual Investor
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Also known as “retail investors”, individual investors
                          are non-professional individuals who invest their own
                          money through a brokerage firm. They have a buying
                          limit of Php 10 million.If you are an investor looking
                          to expand your portfolio on a smaller scale, this role
                          is for you
                        </Tooltip>
                      }
                    >
                      <i className="fas fa-info-circle blutwo hvr-info px-1 info-icon"></i>
                    </OverlayTrigger>
                  </p>
                </div>
              </Col>
              <Col lg={3} md={3} sm={12}>
                <div className="investor-types">
                  <Image
                    src={investtyp2}
                    alt=""
                    className="full-w"
                    height={100}
                  />
                  <p className="font-weight-bold hd-colr text-center">
                    Institutional Investor
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          Institutional investors are juridical entities who
                          invest on behalf of others. They have a buying limit
                          of Php 50 million. If you are a seasoned investor for
                          others, this role is for you
                        </Tooltip>
                      }
                    >
                      <i
                        className="fas fa-info-circle hd-colr hvr-info px-1 info-icon"
                        //data-toggle="tooltip"
                        //title="For more information on receiving your funds, visit <link to Learn page>"
                      ></i>
                    </OverlayTrigger>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">2</div>
              <h4 className="hd-colr font-weight-bold m-2">
                COMPLETE YOUR PROFILE
              </h4>
            </div>
            <div className="d-flex align-items-center blu-t cnterflxm mb-3">
              <small>Dashboard</small>
              <small className="lessthn"></small>
              <small>Profile</small>
              <div className="shp-butn static-btn text-white m-2">
                <i className="fas fa-pen p-1"></i>
                Edit
              </div>
            </div>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <ul className="small list-hopw left-alignbx">
                  <li style={{ marginBottom: '1em' }}>
                    Please enter in full personal and financial data to allow us
                    to begin the preliminary steps in opening your investor's
                    account.
                  </li>
                  <li style={{ marginBottom: '1em' }}>
                    All fields are required except for your Bank Details.
                  </li>
                  <li style={{ marginBottom: '1em' }}>
                    Proof of income is not required for investment accounts with
                    an aggregate value below Php50,000 (bold emphasis)
                  </li>
                </ul>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="d-flex flex-row align-items-center">
                  <h5 className="m-0">
                    <i className="fas fa-exclamation-triangle text-danger px-2"></i>
                  </h5>
                  <p className="px-2 text-danger m-0">
                    NOTE: DO NOT FORGET TO{' '}
                    <span className="text-decoration-underline">SAVE</span> YOUR
                    PROFILE!
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">3</div>
              <h4 className="hd-colr font-weight-bold m-2">
                UPLOAD YOUR DOCUMENTS
              </h4>
            </div>
            <Row className="align-items-center my-4">
              <Col lg={4} md={4} sm={12}>
                <div className="d-flex justify-content-center">
                  <Col className="d-flex justify-content-center flex-column text-center p-2">
                    <Image src={icndoc5} alt="" className="docbox" />
                    <small className="blutwo">
                      Valid government issued IDs
                    </small>
                  </Col>
                  <Col className="d-flex justify-content-center flex-column text-center p-2">
                    <Image src={icndoc6} alt="" className="docbox" />
                    <small className="blutwo">
                      Annual Tax Returns, BIR Form or equivalents{' '}
                      <span className="text-danger">*</span>
                    </small>
                  </Col>
                  <Col className="d-flex justify-content-center flex-column text-center p-2">
                    <Image src={icndoc7} alt="" className="docbox" />
                    <small className="blutwo">
                      Qualified Investor Certification or accredited equivalent
                    </small>
                  </Col>
                </div>
              </Col>
              <Col lg={8} md={8} sm={12}>
                <div className="left-alignbx">
                  <p>
                    “It is also a requirement to upload your Annual Income Tax
                    Return or equivalent document, even if you are a foreign
                    citizen or file your tax returns in a foreign jurisdiction.
                  </p>
                  <p>
                    It is important that we are able to determine your annual
                    income in order to set investor limits and safeguards for
                    you.
                  </p>
                  <p>
                    This requirement is waived* for investor accounts that do
                    not contain cash or securities with a total value of less
                    than Php50,000 pesos.
                  </p>
                  <p>
                    If you are unsure as to whether you can use a certain
                    equivalent document, simply upload it to the platform and we
                    can provide feedback for you- or message us directly at{' '}
                    <a href="#" className="text-secondary">
                      info@roundone.ph
                    </a>{' '}
                    to inquire.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">4</div>
              <h4 className="hd-colr font-weight-bold m-2">
                ACCOMPLISH/REVIEW YOUR LEGAL AGREEMENTS AND INVESTOR
                QUESTIONNAIRE
              </h4>
            </div>
            <Row className="my-3">
              <Col lg={6} md={4} sm={12}>
                <div className="relbox my-4">
                  <Image src={cIcon1} alt="" className="icn-circle" />
                  <small className="edgblu-curvebx">Legal Agreements </small>
                </div>
                <div className="d-flex justify-content-start align-items-center blu-t mb-3 mt-5">
                  <small>Dashboard</small>
                  <small className="lessthn"></small>
                  <small>Profile</small>
                  <small className="lessthn"></small>
                  <small>Legal Agreements</small>
                </div>
                <p>
                  <small className="d-flex justify-content-start">
                    You'll find the prerequisite legal agreements in this
                    section:
                  </small>
                </p>
                <ul className="small list-hopw left-alignbx">
                  <li>Terms and Conditions</li>
                  <li>Privacy Statement</li>
                  <li>Investment Agreement</li>
                  <li>Crowdfunding Cash Account Agreement</li>
                </ul>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <div className="relbox my-4">
                  <Image src={cIcon2} alt="" className="icn-circle" />
                  <small className="edgblu-curvebx">
                    Investor Questionnaire
                  </small>
                </div>
                <div className="d-flex align-items-center blu-t mb-3 mt-5">
                  <small>Dashboard</small>
                  <small className="lessthn"></small>
                  <small>Profile</small>
                  <small className="lessthn"></small>
                  <small>Investor Questionaire</small>
                </div>
                <div className="left-alignbx">
                  <p>
                    To assess that you understand the risk and consequences for
                    when you are investing, answer the following questions
                    correctly.
                  </p>
                  <div className="d-flex flex-row align-items-center">
                    <h5 className="m-0">
                      <i className="fas fa-exclamation-triangle text-danger px-2"></i>
                    </h5>
                    <p className="px-2 text-danger m-0">
                      NOTE: You’ll receive a notification once your account has
                      been successfully verified.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="ylw-vrfdbx">
              <Row className="d-flex flex-row align-items-center">
                <Col lg={3} md={3} sm={12}>
                  <Image src={vrfd} alt="" className="full-w" />
                </Col>
                <Col lg={9} md={9} sm={12}>
                  <p className="m-0">
                    <small>
                      Kindly ensure all documentary requirements are uploaded
                      and required information are filled accurately to help us
                      verify your account.
                    </small>
                    <br />
                    <small className="font-weight-bold">
                      You will receive a notification once your account has been
                      successfully verified within 36 hours.
                    </small>
                  </p>
                </Col>
              </Row>
            </div>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">5</div>
              <h4 className="hd-colr font-weight-bold m-2">
                FUNDING YOUR ACCOUNT
              </h4>
            </div>
            <Row className="left-alignbx mt-3">
              <Col lg={3} md={3} sm={12}>
                <h4 className="blutwo font-weight-bold text-center">
                  MODE OF PAYMENT
                </h4>
                <div className="d-flex flex-wrap cnterflxm mb-2">
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-center flex-column text-center p-2"
                  >
                    <Image src={icndoc1} alt="" className="docbox" />
                    <small className="blutwo font-weight-bold">
                      Wire Transfer
                    </small>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-center flex-column text-center p-2"
                  >
                    <Image src={icndoc2} alt="" className="docbox" />
                    <small className="blutwo font-weight-bold">
                      Online Fund Transfer
                    </small>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-center flex-column text-center p-2"
                  >
                    <Image src={icndoc3} alt="" className="docbox" />
                    <small className="blutwo font-weight-bold">Cheque</small>
                  </Col>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    className="d-flex justify-content-center flex-column text-center p-2"
                  >
                    <Image src={icndoc4} alt="" className="docbox" />
                    <small className="blutwo font-weight-bold">Cash</small>
                  </Col>
                </div>
                <p>
                  Funding your account is simple, deposit your portfolio funds
                  to any of our partner banks listed here and upload your
                  transaction confirmation as a picture or a screenshot via your
                  dashboard’s “Add Balance” feature. Stay tuned for more ways to
                  fund your account!
                </p>
                <div className="d-flex justify-content-center mb-2">
                  <div className="shp-butn static-btn text-white">
                    Add Balance
                  </div>
                </div>
                <p>
                  Once we are able to verify your deposit, expect your balance
                  to update upon successful bank transaction clearing.
                </p>
              </Col>
              <Col lg={9} md={9} sm={12}>
                <small className="blutwo">
                  Payment to / Beneficiary Name (Wire Transfer):
                </small>
                <h5 className="blutwo font-weight-bold pt-2 m-0">
                  Eastern Securities Development Corporation
                </h5>
                <small className="blutwo">
                  Room 1701 Tytana Plaza Bldg. Plaza Lorenzo Ruiz Binondo
                  Manila, 1006 Philippines
                </small>
                <Row className="mt-3">
                  <Col lg={6} md={6} sm={12}>
                    <div className="brnchbox">
                      <small className="font-weight-bold">
                        Asia United Bank (AUB)
                      </small>
                      <div className="d-flex flex-column">
                        <span>
                          570 Quintin Paredes Street, Binondo, Manila,
                          Philippines
                        </span>
                        <span>SWIFT Code: AUBKPHMM</span>
                      </div>
                      <ul className="small list-hopw py-3 px-4">
                        <li>₱ Acct. No#: 004-01-003 573-5</li>
                        <li>₱ Acct. No# :004-0188 1083-7</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className="brnchbox">
                      <small className="font-weight-bold">
                        Bank of the Philippine Islands (BPI)
                      </small>
                      <div className="d-flex flex-column">
                        <span>
                          560 Quintin Paredes St. Binondo, Manila, 1006,
                          Philippines
                        </span>
                        <span>Branch: Quintin Paredes Branch</span>
                        <span>Branch Code: 0476</span>
                        <span>SWIFT code: BOPIPHMM</span>
                      </div>
                      <ul className="small list-hopw py-3 px-4">
                        <li>₱ Acct. No#: 476-100-8531</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className="brnchbox">
                      <small className="font-weight-bold">
                        Banco de Oro (BDO)
                      </small>
                      <div className="d-flex flex-column">
                        <span>
                          262 Juan Luna St. Binondo, Manila, 1006, Philippines
                        </span>
                        <span>Branch: Juan Luna Branch</span>
                        <span>Branch Code: 258</span>
                        <span>SWIFT code: BNORPHMM</span>
                      </div>
                      <ul className="small list-hopw py-3 px-4">
                        <li>$ Acct. No#: 100-5701-13598</li>
                        <li>₱ Acct. No#: 002-5800-11362</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={12}>
                    <div className="brnchbox">
                      <small className="font-weight-bold">
                        Metropolitan Bank and Trust Co. (MBT)
                      </small>
                      <div className="d-flex flex-column">
                        <span>
                          G/F Tytana Plaza Bldg. Plaza Lorenzo Ruiz, Binondo,
                          Manila 1006, Philippines
                        </span>
                        <span>Branch: Downtown Center Branch</span>
                        <span>Branch Code: 016</span>
                        <span>Bank code: 026</span>
                        <span>SWIFT code: MBTCPHMM</span>
                      </div>
                      <ul className="small list-hopw py-3 px-4">
                        <li>$ Account No# : 016-2-016-01799-4</li>
                        <li>₱ Acct. No#: 016-7-0167 0093-9</li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <p className="text-danger">
              IMPORTANT: Only course your investment funds such as deposits
              through authorized Eastern Securities Development Corporation
              (ESDC) bank accounts (listed here), authorized channels and
              agents. Under no circumstances should a corporation, individual or
              non-ESDC authorized agent directly accept or solicit you for
              investment funds for the purposes of crowd-investing. Do not
              accept transaction evidence or receipts from non-authorized
              persons as proof of deposit or transfer. Should you be unsure,
              forward any queries regarding payments or cash transactions to
              info@roundone.ph, treasury@eastern-sec.com or
              compliance@eastern-sec.com.
            </p>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">6</div>
              <h4 className="text-primary font-weight-bold m-2">
                INVEST A FUNDRAISER
              </h4>
            </div>
            <Row className="mt-3">
              <Col lg={6} md={6} sm={12} className="mb-4">
                <div className="d-flex flex-column justify-content-center fulheight semi-bradius p-3 bg-white border shdwbx-iac border-secondary">
                  <small className="blu-t blu-t text-center mb-3">
                    A. Visit the Investment Opportunities dashboard, select a
                    fundraiser,
                    <br /> enter the minimum amount, and proceed to{' '}
                    <b>Invest</b>
                  </small>
                  <Image src={investc1} alt="" className="iacbox shadw" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} className="mb-4">
                <div className="d-flex flex-column justify-content-center fulheight semi-bradius p-3 bg-white border shdwbx-iac border-secondary">
                  <small className="blu-t blu-t text-center mb-3">
                    B. Input required minimum amount then Review
                  </small>
                  <Image src={investc2} alt="" className="iacbox shadw" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} className="mb-4">
                <div className="d-flex flex-column justify-content-center fulheight semi-bradius p-3 bg-white border shdwbx-iac border-secondary">
                  <small className="blu-t blu-t text-center mb-3">
                    C. Lastly, this message appears and you’re done
                    {/* <i className="far fa-check-square"></i> and <b>Confirm</b> */}
                  </small>
                  <Image src={investc3} alt="" className="iacbox shadw" />
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} className="mb-4">
                <div className="d-flex flex-column justify-content-center fulheight semi-bradius p-3 bg-white border shdwbx-iac border-secondary">
                  <small className="blu-t blu-t text-center mb-3">
                    D. Your dashboard will reflect your current commited funds
                    <br />
                    and remaining available balance within your annual
                    investment limits.
                  </small>
                  <Image src={probar} alt="" className="iacbox shadw" />
                </div>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">7</div>
              <h4 className="text-primary font-weight-bold m-2">
                RECEIVE CONFIRMATION
              </h4>
            </div>
            <div className="bdr-xsemi insd-rc mrgn-cntr border border-secondary shdwbx-iac px-4 py-2 my-3">
              <div className="d-flex justify-content-center align-items-center">
                <Image src={emailc} alt="" className="env-sz" />
                <p className="font-weight-bold blu-t m-0 p-2">
                  YOU WILL RECEIVE A EMAIL CONFIRMATION OF YOUR COMMITMENT
                </p>
              </div>
            </div>
            <div className="iacbox left-alignbx mt-4">
              <small className="blu-t">In Dashboard</small>
              <Image src={template} alt="" className="full-w my-3" />
              <p className="text-center">
                <small>
                  Confirmation of committed funds and reflect ownership will
                  appear in the “Active Investment Commitments”.
                </small>
              </p>
            </div>
            <div className="d-flex justify-content-start align-items-center flex-wrap blu-t mb-3 mt-5">
              <small>Portfolio</small>
              <small className="lessthn"></small>
              <small>Investment Commitments</small>
              <small className="lessthn"></small>
              <small>View Campaign</small>
              <small className="lessthn"></small>
              <small>Commitments Tab</small>
              <small className="lessthn"></small>
              <small>View Transactions</small>
            </div>
            <Image src={template2} alt="" className="full-w my-3" />
            <p className="text-center">
              <small>
                Once your committed campaign has been fully subscribed, you will
                be notified that your commitment has now become an investment.
              </small>
            </p>
            <div className="mrgn-cntr px-4 py-2 my-3">
              <div className="d-flex justify-content-center align-items-center">
                <h2 className="m-0">
                  <i className="fas fa-exclamation-circle color-fb"></i>
                </h2>
                <p className="m-0 p-2">
                  You may still modify your commitment before a fully-subscribed
                  campaign ends.
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">8</div>
              <h4 className="text-primary font-weight-bold m-2">
                MONITOR FUNDRAISER PROGRESS
              </h4>
            </div>
            <div className="d-flex flex-column justify-content-center fulheight semi-bradius p-3 bg-white border shdwbx-iac border-secondary my-4">
              <p className="blu-t blu-t text-center font-weight-bold mb-3">
                Monitor the fundraiser's progress and engage with them.
              </p>
              <Image src={investmonitor} alt="" className="iacbox shadw" />
              {/* <Image src={iw1} alt="" className="" /> */}
            </div>
            <div className="mrgn-cntr px-4 py-2 my-4">
              <div className="d-flex justify-content-center align-items-center">
                <Image src={notif} alt="" className="env-sz" />
                <p className="m-0 p-2">
                  Check out your notifications for any updates!
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">9</div>
              <h4 className="text-primary font-weight-bold m-2">
                INVESTOR RELATIONS ACTIVITIES
              </h4>
            </div>
            <div
              className="bg-bldgbox text-center"
              style={{ backgroundImage: `url(${buildings})` }}
            >
              <Row>
                <Col lg={3} md={3} sm={12}>
                  <div className="circle-ira mb-3">
                    <Image src={investor} alt="" className="" />
                  </div>
                  <h5 className="blu-t">Investor Open House</h5>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <div className="circle-ira mb-3">
                    <Image src={media} alt="" className="" />
                  </div>
                  <h5 className="blu-t">Social Media</h5>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <div className="circle-ira mb-3">
                    <Image src={webinar} alt="" className="" />
                  </div>
                  <h5 className="blu-t">Webinar</h5>
                </Col>
                <Col lg={3} md={3} sm={12}>
                  <div className="circle-ira mb-3">
                    <Image src={conference} alt="" className="" />
                  </div>
                  <h5 className="blu-t">Conference</h5>
                </Col>
              </Row>
            </div>
            <p className="text-center text-secondary py-4 m-0">
              Interact and support your fundraisers, celebrating key milestones
              alongside them!
            </p>
          </div>
          <hr />
          <div className="py-3">
            <div className="d-flex flex-row align-items-center cnterflxm">
              <div className="num-stp flowbx text-white">10</div>
              <h4 className="text-primary font-weight-bold m-2">
                SECONDARY MARKET (COMING SOON)
              </h4>
            </div>
            <Row className="iacbox mt-4">
              <Col lg={12} md={12} sm={12}>
                {/* <div className='relbox my-4'>
                                    <Image src={cIcon3} alt="" className="icn-circle" />
                                    <small className='edgblu-curvebx'>Secondary Market</small>
                                </div> */}
                <Image src={market} alt="" className="mb-2 insd-rc py-3" />
                <p>
                  Trade conveniently. Our secondary market is coming to you
                  soon!
                </p>
              </Col>
              {/* <Col lg={6} md={6} sm={12}>
                                <Image src={market} alt="" className='mb-2 insd-rc' />
                            </Col> */}
            </Row>
          </div>
        </Container>
      </div>
      <div className="lghtblue">
        <Container></Container>
      </div>
    </>
  );
};

export default InvestorWalkthrough;

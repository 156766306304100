import React, { useEffect, useLayoutEffect, useState } from 'react';
import './index.css';
import HomePage from './pages/Home';
import {
  BrowserRouter,
  createBrowserRouter,
  Routes,
  useLocation,
  useNavigate,
  Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'store/reducers/auth';
import { isAuthenticated } from 'utils/session';

import _ from 'lodash';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/mulish'; // Defaults to weight 400
import '@fontsource/mulish/400.css'; // Specify weight
import '@fontsource/mulish/400-italic.css'; // Specify weight and style
import './styles/default.css';

import ScrollToTop from 'react-scroll-to-top';

import './App.css';
import IssuerProfilePage from 'pages/IssuerProfile.js';
import AllCompaniesPage from 'pages/AllCompanies/index.js';
import RaisePage from 'pages/Raise/Index';
import LearnPage from 'pages/Learn';
import AllEventsPage from 'pages/AllEvents';
import ContactUsPage from 'pages/ContactUs';
import InvestPage from 'pages/Invest';
import EventContentPage from 'pages/EventContent/Index';
import NewsContentPage from 'pages/NewsContent/Index';
import LearnContentPage from 'pages/LearnContent/Index';
import FaqsContentPage from 'pages/FaqsContent/Index';
import AllNewsPage from 'pages/AllNews/Index';
import Privacy from 'pages/Privacy';
import TermsOfService from 'pages/TermsOfService';
import RiskWarning from 'pages/RiskWarning';
import { Toaster } from 'react-hot-toast';
import MainLayout from 'layout/MainLayout'

const Wrapper = ({ children }) => {
  const {pathname, search} = useLocation();
  const navigate = useNavigate();
  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'instant' });
    if(pathname.toLowerCase() === '/faq'){
      navigate('/learn', {replace: true});
    }
  }, [pathname, search]);
  return children;
};

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <HomePage />,
//   },
//   {
//     path: '/invest',
//     element: <InvestPage />,
//   },
//   {
//     path: '/fundraise',
//     element: <AllCompaniesPage />,
//   },
//   {
//     path: '/fundraise/:guid',
//     element: <IssuerProfilePage />,
//   },
//   {
//     path: '/raise',
//     element: <RaisePage />,
//   },
//   {
//     path: '/learn',
//     element: <LearnPage />,
//   },
//   {
//     path: '/event',
//     element: <AllEventsPage />,
//   },
//   {
//     path: '/contactus',
//     element: <ContactUsPage />,
//   },
//   {
//     path: '/event/:guid',
//     element: <EventContentPage />,
//   },
//   {
//     path: '/news/:guid',
//     element: <NewsContentPage />,
//   },
//   {
//     path: '/learn/:guid',
//     element: <LearnContentPage />,
//   },
//   {
//     path: '/faqs/:guid',
//     element: <FaqsContentPage />,
//   },
//   {
//     path: '/news',
//     element: <AllNewsPage />,
//   },
// ]);

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAuth());
  }, []);



  return (
    <div className="App">
      <ScrollToTop
        smooth
        viewBox="0 0 512 512"
        svgPath="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm143.6 28.9l72.4-75.5V392c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V209.4l72.4 75.5c9.3 9.7 24.8 9.9 34.3 .4l10.9-11c9.4-9.4 9.4-24.6 0-33.9L273 107.7c-9.4-9.4-24.6-9.4-33.9 0L106.3 240.4c-9.4 9.4-9.4 24.6 0 33.9l10.9 11c9.6 9.5 25.1 9.3 34.4-.4z"
        className="scrll-up"
      />
      <Toaster />
      <BrowserRouter>

        <Wrapper>
          {!_.isEmpty(token) && (
                    <MainLayout>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/invest" element={<InvestPage />} />
              <Route exact path="/company" element={<AllCompaniesPage />} />
              <Route exact path="/raise" element={<RaisePage />} />
              <Route exact path="/learn" element={<LearnPage />} />
              <Route exact path="/faq" element={<LearnPage />} />
              <Route exact path="/event" element={<AllEventsPage />} />
              <Route exact path="/news" element={<AllNewsPage />} />
              <Route exact path="/contactus" element={<ContactUsPage />} />
              <Route exact path="/event/:guid" element={<EventContentPage />} />
              <Route exact path="/news/:guid" element={<NewsContentPage />} />
              <Route exact path="/learn/:guid" element={<LearnContentPage />} />
              <Route
                exact
                path="/company/:guid"
                element={<IssuerProfilePage />}
              />
              <Route exact path="/faqs/:guid" element={<FaqsContentPage />} />
              <Route exact path="/privacypolicy" element={<Privacy />} />
              <Route
                exact
                path="/termsofservice"
                element={<TermsOfService />}
              />
              <Route exact path="/riskwarning" element={<RiskWarning />} />
            </Routes>
            </MainLayout>
          )}
        </Wrapper>

      </BrowserRouter>
    </div>
  );
}

export default App;

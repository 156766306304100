import React from 'react';
import { Row, Col, Image, Stack } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTimer } from 'react-timer-hook';

import round1 from '../../assets/images/RoundOne1-symbol.svg';

const IncompleteInfo = ({ content }) => {
  const { minutes, hours, days } = useTimer({
    expiryTimestamp: moment(content?.offeredPeriodEndDate),
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <Image
            src={content?.issuerAccount?.imageUrl}
            alt=""
            className="social-rlogo border p-1 m-0"
          />
          <h4 className="font-weight-bold m-0 px-2">{content?.nameOfIssuer}</h4>
        </div>
        <div>
          <p className="text-uppercase text-small-xs font-weight-bold mb-1">
            Time
            <br />
            Remaining
          </p>
          <Stack direction="horizontal" gap={2}>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{days}</div>
              <small className="text-uppercase text-small-xs">Days</small>
            </div>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{hours}</div>
              <small className="text-uppercase text-small-xs">Hrs</small>
            </div>
            <div className="rounded border border-primary fundrbox fit-content m-auto p-1">
              <div className="h5 font-weight-bold blu-t m-0">{minutes}</div>
              <small className="text-uppercase text-small-xs">Mins</small>
            </div>
          </Stack>
        </div>
      </div>
      <div className="left-alignbx mb-4">
        <p className="font-weight-bold m-0">Campaign Highlights</p>
        <p
          dangerouslySetInnerHTML={{ __html: content?.campaignHighlights }}
        ></p>
      </div>
      <h6 className="text-center text-small font-weight-bold">
        Company Information
      </h6>
      <Row className="left-alignbx p-3 mb-4">
        <Col lg={6} lmd={6} sm={12} className="brdr-cntr">
          <div className="mt-2">
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Address</small>
              <small className="blu-t font-weight-bold">
                {content?.address}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Social Media</small>
              <div className="d-flex flex-row align-items-center">
                {/* <i className="fab fa-facebook-f fb-iconbg rounded-circle fb-pads text-white"></i>
                                <i className="fab fa-instagram pink-colorbg rounded-circle insta-pads text-white mx-1"></i>
                                <i className="fab fa-linkedin-in linkedin-iconbg rounded-circle insta-pads text-white"></i>
                                <i className="fab fa-youtube yt-colorbg rounded-circle yt-pads text-white mx-1"></i> */}
              </div>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Website</small>
              <small className="blu-t font-weight-bold">
                {content?.website}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Company Email</small>
              <small className="blu-t font-weight-bold">
                {content?.emailAddress}
              </small>
            </div>
          </div>
        </Col>
        <Col lg={6} lmd={6} sm={12}>
          <div className="mt-2">
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Type</small>
              <small className="blu-t font-weight-bold">Non-Corporation</small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Date of Incorporation</small>
              <small className="blu-t font-weight-bold">
                {moment(content?.issuerAccount?.createdOn).format('D/mm/yyyy')}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Major Industry Classification</small>
              <small className="blu-t font-weight-bold">
                {content?.industry}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">Industry Classification Group</small>
              <small className="blu-t font-weight-bold">
                {content?.industryDetail ?? '--'}
              </small>
            </div>
            <div className="d-flex flex-column py-1">
              <small className="text-dark">SEC Registration No.</small>
              <small className="blu-t font-weight-bold">{content?.tin}</small>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default IncompleteInfo;

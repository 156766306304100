import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import img01 from 'assets/images/landingpage/R1_icons_new_globe.svg'
import img02 from 'assets/images/landingpage/R1_icons_new_handshake.svg'
import img03 from 'assets/images/landingpage/R1_icons_new_raise_stocks.svg'
import img04 from 'assets/images/landingpage/R1_icons_new_stocks.svg'

const Services = () => {
  return (
    <>
      <Row className="layer-col">
      <Col lg={3} md={3} sm={12} className="bx-ly">
          <div className="top-insd-layer">
          <Image
              src={img03}
              alt=""
              height={75}
            />
            <h3 className="font-weight-bold py-3">
              Publicly raise up to ₱60m per year, per company
            </h3>
          </div>
          <p className="small">
            As a fundraiser through Round One, issuers are now allowed to
            publicly promote their fundraising campaign and exceed the
            19-investor limit.
          </p>
        </Col>
        <Col lg={3} md={3} sm={12} className="bx-ly">
          <div className="top-insd-layer">
            <Image
              src={img02}
              alt=""
              height={75}
            />
            <h3 className="font-weight-bold py-3">Professionalize Your Business</h3>
          </div>
          <p className="small">
          Take your first steps in becoming investment ready, get ready to join the big leagues!
          </p>
        </Col>
        <Col lg={3} md={3} sm={12} className="bx-ly">
          <div className="top-insd-layer">
            <Image
              src={img04}
              alt=""
              height={75}
            />
            <h3 className="font-weight-bold py-3">
              Access Legitimate Start-up & MSME growth ventures
            </h3>
          </div>
          <p className="small">
            Avoid investment scams by investing only in registered securities,
            under the SEC’s crowdfunding rules.
          </p>
        </Col>
        <Col lg={3} md={3} sm={12} className="bx-ly">
          <div className="top-insd-layer">
            <Image
              src={img01}
              alt=""
              height={75}
            />

            <h3 className="font-weight-bold py-3">
              Investment Monitoring and Support
            </h3>
          </div>
          <p className="small">
            Both fundraisers and investors are required to undergo
            Know-Your-Client (KYC) procedure(s), used to verify and know customer
            risk and financial profiles.
          </p>
        </Col>
      </Row>
    </>
  );
};

export default Services;

import React from 'react';
import { Container } from 'react-bootstrap';
import Mapping from '../Mapping';
// import yellowcrv from '../../assets/images/pattern-graphic-yellow.png';
// import Mappingblue from '../Mappingblue';

const PlatformPage = () => {
    return (
        <>
            <div className='relbox hopw-hbox'>
                {/* <Image src={yellowcrv} alt="" className="ylw-graphic" /> */}
                <Container>
                    <h2 className='hd-titles text-center text-uppercase arrowsd py-5'>How our<br/> platform works</h2>
                </Container>
                {/* <div className='d-flex justify-content-center flex-wrap'>
                    <h5 className='text-uppercase font-weight-bold right-hopw p-2'>legend</h5>
                    <ul className='lst-lgd p-2'>
                        <li className='smll-crcl numcbglight'></li>
                        <li className='px-3'>Fundraisers (Issuers)</li>
                    </ul>
                    <ul className='lst-lgd p-2'>
                        <li className='smll-crcl numcbgdrk'></li>
                        <li className='px-3'>Investors</li>
                    </ul>
                    <ul className='lst-lgd p-2'>
                        <li className='smll-crcl numcbgdrk shdwlghtsml'></li>
                        <li className='px-3'>Both</li>
                    </ul>
                </div> */}
            </div>
            {/* <Mappingblue /> */}
            <Mapping />
        </>
    )
}

export default PlatformPage
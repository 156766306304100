import React, { useEffect } from 'react';
import { getCampaign, getEducation, getFaqs } from 'store/reducers/home';
import { useDispatch } from 'react-redux';

import MainLayout from '../../layout/MainLayout';

import FundraiserPage from '../../components/homepage/fundraiser';
import InvestorWalkthrough from './InvestorWalkthrough';
import RiskStatement from './RiskStatement';
import WantToKnowMore from './WTKM';
import SubscribeNewsletter from '../../components/homepage/subscribe-newsletter';
import HowtoInvest from './HowtoInvest';
import BannerInvest from './BannerInvest';
import InvesmentsPlatform from './InvestmentsPlatform';


const InvestPage = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getCampaign());
        dispatch(getFaqs());
        dispatch(getEducation());
      }, []);

    return (
        <>
             
                <BannerInvest />
                <InvesmentsPlatform />
                <FundraiserPage />
                <InvestorWalkthrough />
                <RiskStatement />
                <WantToKnowMore />
                <HowtoInvest />
                <SubscribeNewsletter />
             
        </>
    )
}

export default InvestPage
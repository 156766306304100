import React, { useEffect } from 'react';
import MainLayout from '../../layout/MainLayout';
import BannerLearn from './BannerLearn';
import HelpYouToday from './HelpYouToday';
import { useDispatch } from 'react-redux';
import { getEducation } from 'store/reducers/home';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';
import Faqs from 'components/Faqs';


const LearnPage = () => {
  return (
    <>
       
        <BannerLearn />
        <HelpYouToday />
        <Faqs />
        <SubscribeNewsletter />
       
    </>
  )
}

export default LearnPage
import {
  configureStore,
} from '@reduxjs/toolkit';
import rootReducer from './reducers';


const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [],
        ignoredPaths: []
      },
    }),
});

if (process.env.NODE_ENV === 'development') {
  module.hot?.accept('./reducers', () => {
    const newRootReducer = require('./reducers').default;
    store.replaceReducer(newRootReducer);
  });
}


export default { store };

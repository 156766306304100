import React, { useState } from 'react';
import { Button, Collapse, Container, Row, Col, Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

const FiltersEvent = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [year, setYear] = useState(searchParams.get('year') || '');

  const handleYear = (value) => {
    setYear(value);
    setSearchParams({ page: 1, year: value });
  };

  return (
    <>
      <Container>
        <div className="text-left mt-3">
          <Button
            variant="link"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
            className="text-decoration-none font-weight-bold p-0"
          >
            Filters
            <i className="fas fa-chevron-down px-1"></i>
          </Button>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <Row md={8} className="mt-3">
                <Col>
                  <div>
                    <small className="font-weight-bold">Browse by Year</small>
                    <div className="d-flex flex-wrap mt-2">
                      <span
                        className="smalltabs"
                        onClick={() => handleYear(2021)}
                      >
                        2021
                      </span>
                      <span
                        className="smalltabs"
                        onClick={() => handleYear(2022)}
                      >
                        2022
                      </span>
                      <span
                        className="smalltabs"
                        onClick={() => handleYear(2023)}
                      >
                        2023
                      </span>
                      <span
                        className="smalltabs"
                        onClick={() => handleYear(2024)}
                      >
                        2024
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Collapse>
        </div>
      </Container>
    </>
  );
};

export default FiltersEvent;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import InvestPage from './pages/Invest/index';
import RaisePage from './pages/Raise/Index';
import ContactUsPage from './pages/ContactUs';
import LearnPage from './pages/Learn';
// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
import { Provider } from 'react-redux';
import AppStore from './store';

const { store } = AppStore;

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <App/>
//   },
//   {
//     path: "/invest",
//     element: <InvestPage />
//   },
//   {
//     path: "/raise",
//     element: <RaisePage/>
//   },
//   {
//     path: "/learn",
//     element: <LearnPage />
//   },
//   {
//     path: "/contactus",
//     element: <ContactUsPage />
//   },
// ]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App/>
    </Provider>
);



import React, { useEffect } from 'react';
import { getCampaign, getEvent, getNews } from 'store/reducers/home';
import { useDispatch } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/default.css';

import '../App.css';
import HeaderNav from 'layout/nav';
import HomepageBanner from 'components/homepage/homepage-banner';
import Services from 'components/homepage/services';
import MissionVision from 'components/homepage/mission-vision';
import WhyRoundOne from 'components/homepage/why-round-one';
import OurEcosystemPage from 'components/homepage/our-ecosystem';
import PlatformPage from 'components/homepage/platform-works';
import FundraiserPage from 'components/homepage/fundraiser';
import CfSecurities from 'components/homepage/crowdfunding-securities';
import SocialFeed from 'components/homepage/social-feed';
import Commit from 'components/homepage/commit';
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter';
import Announcements from 'components/homepage/announcements';
import RoundOneNews from 'components/homepage/RoundOneNews';
import Footer from 'layout/footer';
import MainLayout from 'layout/MainLayout';

function Home() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCampaign());
    dispatch(getNews());
    dispatch(getEvent());
  }, []);
  
  return (
    <div className="App">
       
      <HomepageBanner />
      <Services />
      <MissionVision />
      <WhyRoundOne />
      <PlatformPage />
      <OurEcosystemPage />
      <FundraiserPage />
      <CfSecurities />
      <SocialFeed />
      <Announcements />
      <RoundOneNews />
      <Commit />
      <SubscribeNewsletter />
    </div>
  );
}

export default Home;

import React, { useEffect } from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import {
  getEducation,
  getFaqs,
  getFaqsTopic,
  getSubFaqs,
} from 'store/reducers/home';
import _ from 'lodash';

import bbulb from '../../assets/images/getting-started-blue.svg';
import issuer from '../../assets/images/issuer-icon2.svg';
import investor from '../../assets/images/investor-icon.svg';
import partner from '../../assets/images/learn/puzzle_edit_blue.svg';
// import carouselimg from '../../assets/images/sample1.png';
import { convert } from 'html-to-text';

const HelpYouToday = () => {
  const { education, pageCount } = useSelector((state) => state.home);
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const page = searchParams.get('page') ?? 1;
  const search = searchParams.get('search') ?? null;
  const category = searchParams.get('category') ?? null;

  const issuerCount = _.size(
    _.filter(
      education,
      (data) => data.educationalMaterialCategoryName.toLowerCase() === 'issuer'
    )
  );
  const gettingStartedCount = _.size(
    _.filter(
      education,
      (data) =>
        data.educationalMaterialCategoryName.toLowerCase() === 'getting started'
    )
  );
  const investorCount = _.size(
    _.filter(
      education,
      (data) =>
        data.educationalMaterialCategoryName.toLowerCase() === 'investor'
    )
  );

  const handleChangePage = (page, search, category) => {
    setSearchParams(_.omitBy({ page: page, search, category }, _.isNil));
    //dispatch(getEducation(page));
  };

  const handleTags = (search, category) => {
    if (category?.toLowerCase() === 'investors' && investorCount === 0) return;
    if (category?.toLowerCase() === 'issuers' && issuerCount === 0) return;
    if (
      category?.toLowerCase() === 'getting started' &&
      gettingStartedCount === 0
    )
      return;

    setSearchParams(_.omitBy({ page: 1, search, category }, _.isNil));
  };

  useEffect(() => {
    dispatch(getFaqs());
    dispatch(getSubFaqs());
    dispatch(getFaqsTopic());
  }, []);

  useEffect(() => {
    dispatch(getEducation(page, search, category));
  }, [searchParams]);

  return (
    <Container>
      <h2 className="hd-titles text-center py-5">How can we help you today?</h2>
      <Row className="mb-5">
        <Col lg={3} md={3} sm={12}>
          <a
            className={`d-flex flex-column align-items-center fulheight text-center lnk-icn text-decoration-none ${
              _.isEqual(category, 'Getting Started')
                ? ''
                : 'text-decoration-none text-dark'
            }`}
            onClick={() => handleTags(search, 'Getting Started')}
          >
            {/* <div style={{ backgroundImage: `url(${bbulb})` }} className='hvr-icn'></div> */}
            <Image src={bbulb} alt="" className="env-szheight mb-2" />
            <span className="font-weight-bold mt-2">Getting Started</span>
          </a>
        </Col>
        <Col lg={3} md={3} sm={12}>
          <a
            className={`d-flex flex-column align-items-center fulheight text-center lnk-icn text-decoration-none ${
              _.isEqual(category, 'Issuers')
                ? ''
                : 'text-decoration-none text-dark'
            }`}
            onClick={() => handleTags(search, 'Issuers')}
          >
            <Image src={issuer} alt="" className="env-szheight mb-2" />
            <span className="font-weight-bold">Issuers</span>
          </a>
        </Col>
        <Col lg={3} md={3} sm={12}>
          <a
            className={`d-flex flex-column align-items-center fulheight text-center lnk-icn text-decoration-none ${
              _.isEqual(category, 'Investors')
                ? ''
                : 'text-decoration-none text-dark'
            }`}
            onClick={() => handleTags(search, 'Investors')}
          >
            <Image src={investor} alt="" className="env-szheight mb-2" />
            <span className="font-weight-bold">Investors</span>
          </a>
        </Col>
        <Col lg={3} md={3} sm={12}>
          <a
            className={`d-flex flex-column align-items-center fulheight text-center lnk-icn text-decoration-none ${
              _.isEqual(category, 'Partners') ? '' : 'text-dark'
            }`}
            //onClick={() => handleTags(search, 'Partners')}
          >
            <Image src={partner} alt="" className="env-szheight mb-2" />
            <span className="font-weight-bold">Partners (Coming Soon)</span>
          </a>
        </Col>
      </Row>
      <Row>
        {education
          ?.filter(
            (data) =>
              data.educationalMaterialCategoryName?.toLowerCase() ===
                category?.toLowerCase() || _.isNull(category)
          )
          ?.slice((page - 1) * 6, (page - 1) * 6 + 6)
          ?.map((data, key) => (
            <Col lg={4} md={4} sm={12} className="mb-4" key={key}>
              <div className="carouselbx fulheight mx-3 p-3">
                <div
                  className="carosize px-3"
                  style={{
                    backgroundImage: `url(${encodeURI(data?.bannerImageUrl).replace(/[!'()*]/g, escape)})`,
                  }}
                >
                  <div className="d-flex justify-content-between align-items-baseline">
                    <div></div>
                    <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                      <Link href="#" className="shr-hvr">
                        <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="slf-overlay"></div>
                </div>
                <Container className="left-alignbx">
                  <h6
                    className="font-weight-bold txt-clamp-two my-3"
                    style={{ height: '2.5rem' }}
                  >
                    {data?.title}
                  </h6>
                  <div className="d-flex align-items-center">
                    <i className="fas fa-clock text-primary"></i>
                    <small className="px-2">
                      {moment(data?.createdOn).format('MMMM Do YYYY')}
                    </small>
                  </div>
                  <div className="d-flex flex-wrap mt-2">
                    <span className="smalltabs">
                      {data?.educationalMaterialCategoryName}
                    </span>
                  </div>
                  <p
                    className="py-2 text-left m-0 txt-clamp"
                    style={{ height: '5em' }}
                  >
                    <small>{convert(data?.content, { wordwrap: 500 })}</small>
                  </p>
                  <Link
                    to={`/learn/${data?.guid}`}
                    className="py-2 px-0 btn-link btn"
                  >
                    Read More
                  </Link>
                </Container>
              </div>
            </Col>
          ))}
      </Row>
      <div className="d-flex justify-content-center">
        <PaginationControl
          page={page}
          between={4}
          total={_.size(
            _.filter(
              education,
              (data) =>
                data.educationalMaterialCategoryName?.toLowerCase() ===
                  category?.toLowerCase() || _.isNull(category)
            )
          )}
          limit={6}
          changePage={(page) => handleChangePage(page, search, category)}
          ellipsis={1}
        />
      </div>
    </Container>
  );
};

export default HelpYouToday;

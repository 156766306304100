import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PaginationControl } from 'react-bootstrap-pagination-control';

import carouselimg from '../../assets/images/sample1.png';
import { getNews } from 'store/reducers/home';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import NotFound from 'components/common/NotFound';

const CardNews = () => {
  const { news, pageCount } = useSelector((state) => state.home);
  let [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const page = searchParams.get('page') || 1;
  const search = searchParams.get('search') || '';
  const year = searchParams.get('year') || '';

  const handleChangePage = (page) => {
    setSearchParams({ page: page });
    dispatch(getNews(page));
  };

  useEffect(() => {
    dispatch(getNews(page, search, year));
  }, [searchParams]);

  return (
    <>
      <Container>
        <Row className="my-5">
          {_.isEmpty(news) ? (
            <NotFound />
          ) : (
            news?.map((data, key) => (
              <Col lg={4} md={4} sm={12} className="mb-4" key={key}>
                <div className="carouselbx fulheight mx-3 p-3">
                  <div
                    className="carosize px-3"
                    style={{
                      backgroundImage: `url(${_.first(
                        data?.newUpdatesFiles
                      )?.s3FileName.replace(/\s/g, '%20')})`,
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-baseline">
                      <div>
                        {/* <Link href="#" className='semi-bradius pplink py-2 px-3'>Upcoming Event</Link> */}
                      </div>
                      <div className="d-flex justify-content-end top-icons-caro mt-2  ">
                        <Link href="#" className="shr-hvr">
                          <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="slf-overlay"></div>
                  </div>
                  <Container className="left-alignbx">
                    <h6 className="font-weight-bold text-primary my-3">
                      {data?.title}
                    </h6>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-user-check text-primary text-small"></i>
                      <small className="px-2">{data?.createdBy}</small>
                    </div>
                    <div className="d-flex align-items-center">
                      <i className="fas fa-clock text-primary text-small"></i>
                      <small className="px-2">
                        {moment(data?.createdOn).format('MMMM Do YYYY')}
                      </small>
                    </div>
                    <p className="py-2 text-left m-0 txt-clamp">
                      <small>{data?.content?.replace(/<[^>]+>/g, '')}</small>
                    </p>
                    <div className="d-flex">
                      <Link
                        variant="link"
                        to={`/news/${data?.guid}`}
                        className="p-0 text-small"
                      >
                        Read More
                      </Link>
                      {/* <Button type="button" className="lightblue-btn semi-bradius btn btn-sm mx-2 border-0">See Details</Button> */}
                    </div>
                  </Container>
                </div>
              </Col>
            ))
          )}
        </Row>
        {!_.isEmpty(news) && (
          <div className="mb-5">
            <div className="py-3">
              <span className="text-center">
                {page} of {Math.ceil(pageCount / 6)}
              </span>
            </div>
            <PaginationControl
              page={page}
              between={4}
              total={pageCount}
              changePage={(page) => handleChangePage(page)}
              limit={20}
              ellipsis={1}
            />
          </div>
        )}
      </Container>
    </>
  );
};

export default CardNews;

import React from 'react';
import { Container, Carousel, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import Binocular from '../../assets/images/newIcons/Binocular.png';
import _ from 'lodash';
// import strips from '../../assets/images/stripes.png';
import img1 from '../../assets/images/img-01.jpg';

const Announcements = () => {
  const { event } = useSelector((state) => state.home);
  return (
    <div className="annmt-bg relbox">
      {/* <Image src={strips} alt="" className="patternblue-imgtwo" /> */}
      <Container>
        <div className="py-5">
          <h2 className="hd-titles text-center">
            Upcoming Events and Announcements
          </h2>
          <p className="sub-des">
            Here are the exciting events we have lined up. Check them out to
            expand your knowledge and network!
          </p>
        </div>
        {_.isEmpty(
          event.filter((data) => {
            if (
              new Date(_.first(data?.eventschedules)?.endDateTime) -
                new Date() <
              0
            )
              return false;
            else return true;
          })
        ) ? (
          <div>
            <div className="py-5">
              <img src={Binocular} />
              <p className="font-weight-bold">
                There’s nothing to see here yet!
              </p>
            </div>
          </div>
        ) : (
          <Carousel className="main-crslbox mb-3" fade>
            {event
              .filter((data) => {
                if (
                  new Date(
                    moment(
                      _.first(data?.eventschedules)?.endDateTime
                    ).utcOffset(960)
                  ) -
                    new Date() <
                  0
                )
                  return false;
                else return true;
              })
              ?.map((data, key) => (
                <Carousel.Item key={key}>
                  <Row className="itembgcrsl d-flex align-items-center">
                    <Col lg={6} md={6} sm={12}>
                      <div
                        className="brdr-rdis-uea mb-3"
                        style={{
                          backgroundImage: `url(${encodeURI(data?.fileUrl)})`,
                        }}
                      >
                        <div className="d-flex justify-content-end top-icons-caro m-3 ">
                          <Link href="#" className="shr-hvr">
                            <i className="fas fa-share-alt rounded-circle bg-white text-primary p-2"></i>
                          </Link>
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} className="">
                      <div className="px-4 text-left">
                        <h5 className="txt-hdcarsl">{data?.title}</h5>
                        <p className="add-elps m-0">
                          <i className="fas fa-map-marker-alt blutwo"></i>
                          <span className="p-2">
                            <small>{data?.venueAddress}</small>
                          </span>
                        </p>
                        <p>
                          <i className="fas fa-clock blutwo"></i>
                          <span className="p-2">
                            <small>
                              {moment(
                                _.first(data?.eventschedules)?.startDateTime
                              )
                                .utcOffset(960)
                                .format('MMMM Do YYYY | h:mm a')}
                              {moment(
                                _.first(data?.eventschedules)?.endDateTime
                              )
                                .utcOffset(960)
                                .format('- h:mm a')}
                            </small>
                          </span>
                        </p>
                        <div className="d-flex flex-wrap">
                          {data?.tags?.split(',')?.map((tag, index) => (
                            <span key={index} className="smalltabs">
                              {tag}
                            </span>
                          ))}
                        </div>
                        <small className="dtlsbx-annc my-3">
                          {data?.eventDescription?.replace(/<[^>]+>/g, '')}
                        </small>
                        <div className="d-flex justify-content-start mt-3">
                          {/* <Button type="button" className="blu-btn mble-btn m-1">
                        Register Now
                      </Button> */}
                          <Link
                            className="lightblue-btn m-1 btn"
                            to={`/event/${data?.guid}`}
                          >
                            See Details
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Carousel.Item>
              ))}
          </Carousel>
        )}
        {/* <Link className="vmblu-btn mrg-btm btn" to={'/event'}>
          View All
        </Link> */}
      </Container>
      <Link to="/event" style={{ textDecoration: 'none' }}>
        <Button type="button" className="btn signbtn mt-5">
          View All
        </Button>
      </Link>
    </div>
  );
};

export default Announcements;

import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  Tab,
  Tabs,
  Image,
  Button,
  Container,
  Modal,
  Form,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import crowdinvesting from '../../assets/images/crowdfund.jpeg';
import vm from '../../assets/images/vm.jpeg';
import community from '../../assets/images/community.jpeg';
import join from '../../assets/images/join.jpeg';
import _ from 'lodash';

const IntroBusiness = () => {
  const [lgShow, setLgShow] = useState(false);

  const { issuer } = useSelector((state) => state.content);

  const summaryData = JSON.parse(
    _.find(issuer?.details, (data) => data?.sectionCode == 'SUBUS')?.jsonData ??
      '[]'
  );

  const teamData = JSON.parse(
    _.find(issuer?.details, (data) => data?.sectionCode == 'TEAM')?.jsonData ??
      '[]'
  );

  return (
    <>
      <Container>
        <div className="intrbx mt-5">
          <Tabs
            defaultActiveKey="introbusiness"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="introbusiness" title="Introduction to Business">
              <h2 className="font-weight-bold">Introduction to Business</h2>
              <div className="text-start">
                <div
                  dangerouslySetInnerHTML={{
                    __html: summaryData?.IntroductionToTheBusiness,
                  }}
                />
                <span className="font-weight-bold">
                  Company's Financial Highlights
                </span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: summaryData?.CompanyHighlights,
                  }}
                />
                <span className="font-weight-bold">Business Summary</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: summaryData?.BusinessPlanSummary,
                  }}
                />
                <span className="font-weight-bold">Management Discussion</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: summaryData?.ManagementDiscussion,
                  }}
                />
              </div>

              {/* <h5 className="text-dark font-weight-bold text-center py-4">
                Introduction to the Business
              </h5>
              <div className="left-alignbx">
                <p>
                  Round One is the country's first SEC-licensed equity
                  crowdfunding platform which enables startups and SMEs to raise
                  funds online. We provide an avenue for the public to own
                  shares of a private company, foster the business community,
                  and grow the ecosystem.
                </p>
                <p>
                  It is a business unit of Eastern Securities Development
                  Corporation, a duly registered stock brokerage and a member of
                  the Philippine Stock Exchange since 1977. Round One is
                  licensed by the Securities Exchange Commission (SEC) and is in
                  compliance with SEC Memorandum Circular No. 14 (also known as
                  the Rules and Regulations Governing Crowdfunding).
                </p>
                <p>
                  The platform features an end-to-end investment
                  portal/marketplace that matches private companies that are
                  start-ups and/or SMEs to potential investors from all over the
                  country. Entrepreneurs gain access to capital, information,
                  and support, while issuers enjoy access to alternative
                  investments.
                </p>
                <p>
                  On the platform, you can directly invest and own shares of
                  private businesses raising funds, from pre-seed to series A
                  funding, from common or preferred shares, convertible notes,
                  down to SAFEs, or simple agreements for future equity.
                </p>
              </div>
              <div className="sp-lithbg my-5">
          <Image src={crowdinvesting} alt="" className="iacbox" />
        </div>
        <div className="left-alignbx">
          <p className="font-weight-bold">Our Vision and Mission</p>
          <p>
            Round One envisions itself to be a digital business community and
            have its own financial ecosystem for small-medium enterprises. Our
            mission is to build an ecosystem centered around connecting
            Philippine entrepreneurs and startups to various investors and also
            advisors who could help fund and support your business.
          </p>
          <Image src={vm} alt="" className="iacbox d-block my-5" />
          <p className="font-weight-bold">Our Ecosystem</p>
          <p>
            Round One envisions itself to be a digital business community and
            have its own financial ecosystem for small-medium enterprises. Our
            mission is to build an ecosystem centered around connecting
            Philippine entrepreneurs and startups to various investors and also
            advisors who could help fund and support your business.
          </p>
          <p>
            In these 40 years, Eastern Securities has been building its network
            through partnerships with financial institutions, these financial
            institutions are the ones who safe keep all crowdfunding funds and
            act as custodian banks.
          </p>
          <p>
            Part of the ecosystem is the network of advisors, industry
            professionals, and the business community, to assist you should you
            need help in your valuations, financial projections, pitch deck, and
            marketing.
          </p>
          <p>
            The Round One platform allows you to immerse your company in this
            wide network of opportunities.
          </p>
          <Image src={community} alt="" className="iacbox d-block my-5" />
          <p>
            There are 3 key people in the Round One community - investors,
            issuers, and advisors.
          </p>
          <ul>
            <li>
              <p>
                <span className="font-weight-bold">Investors</span> - Aside from
                the usual angel Investor, venture capitalist, and institutional
                funds, we are also tapping into individual family offices, seed
                funds, and qualified investors. More than just the capital,
                investors can also offer their expertise, knowledge, and
                connections.
              </p>
            </li>
            <li>
              <p>
                <span className="font-weight-bold">Issuers</span> - You can
                connect with other businesses and also various government
                institutions, organizations, incubators and accelerators who can
                help you.
              </p>
            </li>
            <li>
              <p>
                <span className="font-weight-bold">Advisors</span> - Consultants
                and advisors to provide you with the expertise you need to grow
                and expand, to give guidance at the early stage where it is most
                crucial.
              </p>
            </li>
          </ul>
          <Image src={join} alt="" className="iacbox d-block my-5" />
          <p>Learn more about equity crowdfunding below:</p>
          <ul className="dless bblue p-0">
            <li>Start Up Podcast Special: StartUp Enablers</li>
            <li>PHSW 2021: Exploring Equity Crowdfunding in the Philippines</li>
            <li>Make Your Business Investment Ready </li>
            <li>Deep Dive into Crowdfunding </li>
            <li>Finance 101: Securing Funds Through Crowdfunding </li>
            <li>Bull's Eye Part 1: Spotting Investment Opportunities</li>
            <li>Business Financing and SME Credit Scorings</li>
          </ul>
          <div>
            <h6 className="font-weight-bold text-center">Company Highlights</h6>
            <ul>
              <li>
                Received our permanent{' '}
                <span className="bblue">crowdfunding license</span> on Feb 23,
                2022.
              </li>
              <li>
                <span className="bblue">Officially launched</span> the platform
                in October 2022.
              </li>
              <li>Onboarded 9 issuers in the first cohort.</li>
              <li>
                Growing partnerships with incubators, accelerators, government
                institutions, private institutions & organizations.
              </li>
            </ul>
          </div>
        </div> */}
            </Tab>
            <Tab eventKey="teams" title="Teams">
              <Row className="my-5">
                {teamData?.map((data, key) => (
                  <Col lg={4} md={4} sm={12} className="mb-5" key={key}>
                    <div className="d-flex cnterflxm">
                      <div>
                        <div className="rounded-circle numc border border-secondary mb-3">
                          <Image src={data?.ImageUrl} />
                          <i className="fas fa-user-tie"></i>
                        </div>
                      </div>
                      <div className="mx-3">
                        <div className="d-flex flex-column right-hopw">
                          <span className="font-weight-bold text-uppercase">
                            {data?.FirstName} {data?.LastName}
                          </span>
                          <small className="py-2">{data?.PositionTitle}</small>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}

                {/* <Col lg={4} md={4} sm={12} className="mb-5">
                  <div className="d-flex cnterflxm">
                    <div>
                      <div className="rounded-circle numc border border-secondary mb-3">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex flex-column right-hopw">
                        <span className="font-weight-bold text-uppercase">
                          Brandon BENITO LEONG
                        </span>
                        <small className="py-2">Unit Lead</small>
                        <div className="d-flex align-items-center">
                          <div className="colorbg-two text-white rounded-circle px-2">
                            <i className="fab fa-linkedin-in linkin"></i>
                          </div>
                          <small className="font-weight-bold p-1">
                            Linkedin Page
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="mb-5">
                  <div className="d-flex cnterflxm">
                    <div>
                      <div className="rounded-circle numc border border-secondary mb-3">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex flex-column right-hopw">
                        <span className="font-weight-bold text-uppercase">
                          LEAH BUFI
                        </span>
                        <small className="py-2">Marketing Lead</small>
                        <div className="d-flex align-items-center">
                          <div className="colorbg-two text-white rounded-circle px-2">
                            <i className="fab fa-linkedin-in linkin"></i>
                          </div>
                          <small className="font-weight-bold p-1">
                            Linkedin Page
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="mb-5">
                  <div className="d-flex cnterflxm">
                    <div>
                      <div className="rounded-circle numc border border-secondary mb-3">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex flex-column right-hopw">
                        <span className="font-weight-bold text-uppercase">
                          JERIKA CLETO
                        </span>
                        <small className="py-2">Asst. Marketing Manager</small>
                        <div className="d-flex align-items-center">
                          <div className="colorbg-two text-white rounded-circle px-2">
                            <i className="fab fa-linkedin-in linkin"></i>
                          </div>
                          <small className="font-weight-bold p-1">
                            Linkedin Page
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="mb-5">
                  <div className="d-flex cnterflxm">
                    <div>
                      <div className="rounded-circle numc border border-secondary mb-3">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex flex-column right-hopw">
                        <span className="font-weight-bold text-uppercase">
                          CAMILLE BULLAN
                        </span>
                        <small className="py-2">Financial Analyst</small>
                        <div className="d-flex align-items-center">
                          <div className="colorbg-two text-white rounded-circle px-2">
                            <i className="fab fa-linkedin-in linkin"></i>
                          </div>
                          <small className="font-weight-bold p-1">
                            Linkedin Page
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={12} className="mb-5">
                  <div className="d-flex cnterflxm">
                    <div>
                      <div className="rounded-circle numc border border-secondary mb-3">
                        <i className="fas fa-user-tie"></i>
                      </div>
                    </div>
                    <div className="mx-3">
                      <div className="d-flex flex-column right-hopw">
                        <span className="font-weight-bold text-uppercase">
                          CRISTINA DELA CRUZ
                        </span>
                        <small className="py-2">Operations Manager</small>
                        <div className="d-flex align-items-center">
                          <div className="colorbg-two text-white rounded-circle px-2">
                            <i className="fab fa-linkedin-in linkin"></i>
                          </div>
                          <small className="font-weight-bold p-1">
                            Linkedin Page
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
            </Tab>
            <Tab eventKey="companydetails" title="Company Details">
              <div className="text-center my-5">
                <div>
                  <i className="fas fa-user user-icn"></i>
                </div>
                <h2 className="font-weight-bold py-4">
                  Open an account to get access to more details
                  <br /> about the Round One campaign
                </h2>

                <Link
                  to="https://auth.v2.roundone.ph/registration"
                  style={{ textDecoration: 'none' }}
                >
                  <Button type="button" className="btn signbtn my-4">
                    Sign Up
                  </Button>
                </Link>
                <p>
                  Already have an account?
                  <Link
                    to="https://auth.v2.roundone.ph/"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="blu-t font-weight-bold px-2">Log In</span>
                  </Link>
                </p>
                <p>
                  To comply with financial regulations, we can only show full
                  campaign details to registered users.
                </p>
              </div>
            </Tab>
            <Tab eventKey="updates" title="Updates">
              <div className="text-center my-5">
                <div>
                  <i className="fas fa-user user-icn"></i>
                </div>
                <h2 className="font-weight-bold py-4">
                  Open an account to get access to more details
                  <br /> about the Round One campaign
                </h2>
                <Link
                  to="https://auth.v2.roundone.ph/registration"
                  style={{ textDecoration: 'none' }}
                >
                  <Button type="button" className="btn signbtn my-4">
                    Sign Up
                  </Button>
                </Link>
                <p>
                  Already have an account?
                  <Link
                    to="https://auth.v2.roundone.ph/"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="blu-t font-weight-bold px-2">Log In</span>
                  </Link>
                </p>
                <p>
                  To comply with financial regulations, we can only show full
                  campaign details to registered users.
                </p>
              </div>
              {/* <div className='my-5'>
                                <h5 className='left-alignbx font-weight-bold'>
                                    Company Updates
                                </h5>
                                <div className='small-brdrds brdrlightc p-4 my-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex flex-row'>
                                            <div>
                                                <i className="fas fa-user user-small"></i>
                                            </div>
                                            <div className='mx-2'>
                                                <h6 className='font-weight-bold text-left'>
                                                    B.L
                                                </h6>
                                                <div className='d-flex flex-row align-items-center flex-wrap'>
                                                    <small className='d-flex flex-nowrap'>
                                                        (Round One) (Unit Lead)
                                                    </small>
                                                    <small className='listline'>Aug 08, 2023</small>
                                                    <small className='listline'>1:00pm</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
                                        </div>
                                    </div>
                                    <div className='left-alignbx mt-3'>
                                        <h5 className='font-weight-bold'>
                                            Oversubscription open
                                        </h5>
                                        <p className='m-0 py3'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sapien est, porta quis commodo a, blandit eget lectus. Proin tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit, tempor quis sollicitudin et, suscipit nec ligula. Fusce ullamcorper tortor commodo sodales faucibus. Fusce commodo consectetur sapien, eget faucibus justo ultricies eget. Proin venenatis dictum elit, ac consectetur nisi vehicula non. Donec id finibus ante.
                                        </p>
                                        <div className='d-flex cnterflxm'>
                                            <Button variant="link" className='btn-text py-2 px-0' onClick={() => setLgShow(true)}>Read More</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='small-brdrds brdrlightc p-4 my-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex flex-row'>
                                            <div>
                                                <i className="fas fa-user user-small"></i>
                                            </div>
                                            <div className='mx-2'>
                                                <h6 className='font-weight-bold text-left'>
                                                    B.L
                                                </h6>
                                                <div className='d-flex flex-row align-items-center flex-wrap'>
                                                    <small className='d-flex flex-nowrap'>
                                                        (Round One) (Unit Lead)
                                                    </small>
                                                    <small className='listline'>Aug 08, 2023</small>
                                                    <small className='listline'>1:00pm</small>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
                                        </div>
                                    </div>
                                    <div className='left-alignbx mt-3'>
                                        <h5 className='font-weight-bold'>
                                            Nearing campaign amount target
                                        </h5>
                                        <p className='m-0 py3'>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sapien est, porta quis commodo a, blandit eget lectus. Proin tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,
                                        </p>
                                        <div className='d-flex cnterflxm'>
                                            <Button variant="link" className='btn-text py-2 px-0'>Read More</Button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </Tab>
            <Tab eventKey="documents" title="Documents">
              <div className="text-center my-5">
                <div>
                  <i className="fas fa-user user-icn"></i>
                </div>
                <h2 className="font-weight-bold py-4">
                  Open an account to get access to more details
                  <br /> about the Round One campaign
                </h2>
                <Link
                  to="https://auth.v2.roundone.ph/registration"
                  style={{ textDecoration: 'none' }}
                >
                  <Button type="button" className="btn signbtn my-4">
                    Sign Up
                  </Button>
                </Link>
                <p>
                  Already have an account?
                  <Link
                    to="https://auth.v2.roundone.ph/"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="blu-t font-weight-bold px-2">Log In</span>
                  </Link>
                </p>
                <p>
                  To comply with financial regulations, we can only show full
                  campaign details to registered users.
                </p>
              </div>
              {/* <div className='iacbox my-5'>
                                <Row>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='d-flex align-items-center'>
                                            <i className="fas fa-file-alt files-icn"></i>
                                            <h6 className='font-weight-bold m-0 px-2'>AFS 2021</h6>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='d-flex align-items-center'>
                                            <i className="fas fa-file-alt files-icn"></i>
                                            <h6 className='font-weight-bold m-0 px-2'>Round One Pitch Deck</h6>
                                        </div>
                                    </Col>
                                    <Col lg={4} md={4} sm={12}>
                                        <div className='d-flex align-items-center'>
                                            <i className="fas fa-file-alt files-icn"></i>
                                            <h6 className='font-weight-bold m-0 px-2'>SAFE Agreement</h6>
                                        </div>
                                    </Col>
                                </Row>
                            </div> */}
            </Tab>
            <Tab eventKey="discussion" title="Discussion">
              <div className="text-center my-5">
                <div>
                  <i className="fas fa-user user-icn"></i>
                </div>
                <h2 className="font-weight-bold py-4">
                  Open an account to get access to more details
                  <br /> about the Round One campaign
                </h2>
                <Link
                  to="https://auth.v2.roundone.ph/registration"
                  style={{ textDecoration: 'none' }}
                >
                  <Button type="button" className="btn signbtn my-4">
                    Sign Up
                  </Button>
                </Link>
                <p>
                  Already have an account?
                  <Link
                    to="https://auth.v2.roundone.ph/"
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="blu-t font-weight-bold px-2">Log In</span>
                  </Link>
                </p>
                <p>
                  To comply with financial regulations, we can only show full
                  campaign details to registered users.
                </p>
              </div>
              {/* <div className='mt-5'>
                                <Button type="button" className="btn signbtn"><i className="fas fa-plus-circle px-1"></i>Create Discussion</Button>
                            </div>
                            <div className='small-brdrds brdrlightc p-4 my-5'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <h5 className='font-weight-bold text-left'>
                                        Copyright Granted to IOL Inc. for ARCS Intellectual Property
                                    </h5>
                                    <div>
                                        <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
                                    </div>
                                </div>
                                <div className='d-flex flex-row'>
                                    <div>
                                        <i className="fas fa-user user-small"></i>
                                    </div>
                                    <div className='mx-2'>
                                        <h6 className='font-weight-bold text-left'>
                                            B.L
                                        </h6>
                                        <div className='d-flex flex-row align-items-center flex-wrap'>
                                            <small className='d-flex flex-nowrap'>
                                                (Round One) (Unit Lead)
                                            </small>
                                            <small className='listline'>Aug 08, 2023</small>
                                            <small className='listline'>1:00pm</small>
                                            <div className='text-left mt-3'>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sapien est, porta quis commodo a, blandit eget lectus. Proin tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,</p>
                                            </div>
                                            <div className='d-flex flex-row justify-content-between align-items-center full-w'>
                                                <div className='d-flex flex-row'>
                                                    <div>
                                                        <small><i className="fas fa-thumbs-up blu-t"></i></small>
                                                        <small className='px-1'>1 Like</small>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <small><i className="fas fa-comment-alt blu-t"></i></small>
                                                        <small className='px-1'>5 Comments</small>
                                                    </div>
                                                </div>
                                                <Link href="#" className='text-small'>View Comments</Link>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap mt-2">
                                            <span className="smalltabs-green">Investment</span>
                                        </div>
                                        <div className='bg-light small-brdrds my-4 p-3'>
                                            <div className='d-flex justify-content-between'>
                                                <div className='d-flex flex-row'>
                                                    <div>
                                                        <i className="fas fa-user user-small"></i>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <h6 className='font-weight-bold text-left'>
                                                            E.Y (YOU)
                                                        </h6>
                                                        <div className='d-flex flex-row align-items-center flex-wrap'>
                                                            <small className='d-flex flex-nowrap'>
                                                                (Qualified Investor)
                                                            </small>
                                                            <small className='listline'>Aug 08, 2023</small>
                                                            <small className='listline'>3:00pm</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row'>
                                                <div className='mx-2'>
                                                    <div className='d-flex flex-row align-items-center flex-wrap'>
                                                        <div className='text-left mt-3'>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sapien est, porta quis commodo a, blandit eget lectus. Proin tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,</p>
                                                        </div>
                                                        <div className='d-flex flex-row justify-content-between align-items-center full-w'>
                                                            <div className='d-flex flex-row'>
                                                                <div>
                                                                    <small><i className="far fa-thumbs-up blu-t"></i></small>
                                                                    <small className='px-1'>1 Like</small>
                                                                </div>
                                                                <div className='mx-2'>
                                                                    <small><i className="far fa-comment-alt blu-t"></i></small>
                                                                    <small className='px-1'>Reply</small>
                                                                </div>
                                                            </div>
                                                            <Link href="#" className='text-small'>3 Replies</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-row align-items-center replybx'>
                                                <div className='mx-2'>
                                                    <i className="fas fa-user user-smaller"></i>
                                                </div>
                                                <Form.Control type="email" placeholder="Reply here" className='semi-bradius' />
                                            </div>
                                            <Button variant="link" className='text-small my-4'>View All</Button>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                    <div className='mx-2'>
                                        <i className="fas fa-user user-smaller"></i>
                                    </div>
                                    <Form.Control type="email" placeholder="Comment here" className='semi-bradius' />
                                </div>
                            </div>
                            <div className='small-brdrds brdrlightc p-4 my-5'>
                                <div className='d-flex justify-content-between mb-3'>
                                    <h5 className='font-weight-bold text-left'>
                                        Copyright Granted to IOL Inc. for ARCS Intellectual Property
                                    </h5>
                                    <div>
                                        <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
                                    </div>
                                </div>
                                <div className='d-flex flex-row'>
                                    <div>
                                        <i className="fas fa-user user-small"></i>
                                    </div>
                                    <div className='mx-2'>
                                        <h6 className='font-weight-bold text-left'>
                                            B.L
                                        </h6>
                                        <div className='d-flex flex-row align-items-center flex-wrap'>
                                            <small className='d-flex flex-nowrap'>
                                                (Round One) (Unit Lead)
                                            </small>
                                            <small className='listline'>Aug 08, 2023</small>
                                            <small className='listline'>1:00pm</small>
                                            <div className='text-left mt-3'>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer sapien est, porta quis commodo a, blandit eget lectus. Proin tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,</p>
                                            </div>
                                            <div className='d-flex flex-row justify-content-between align-items-center full-w'>
                                                <div className='d-flex flex-row'>
                                                    <div>
                                                        <small><i className="fas fa-thumbs-up blu-t"></i></small>
                                                        <small className='px-1'>1 Like</small>
                                                    </div>
                                                    <div className='mx-2'>
                                                        <small><i className="fas fa-comment-alt blu-t"></i></small>
                                                        <small className='px-1'>5 Comments</small>
                                                    </div>
                                                </div>
                                                <Link href="#" className='text-small'>View Comments</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center mt-4'>
                                    <div className='mx-2'>
                                        <i className="fas fa-user user-smaller"></i>
                                    </div>
                                    <Form.Control type="email" placeholder="Comment here" className='semi-bradius' />
                                </div>
                            </div> */}
            </Tab>
          </Tabs>
        </div>
      </Container>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="border-0 mdl-clsebtn" closeButton>
          {/* <Modal.Title id="example-modal-sizes-title-lg">
                        Large Modal
                    </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className="pt-0 pb-3">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-row">
              <div>
                <i className="fas fa-user user-small"></i>
              </div>
              <div className="mx-2">
                <h6 className="font-weight-bold text-left">B.L</h6>
                <div className="d-flex flex-row align-items-center flex-wrap">
                  <small className="d-flex flex-nowrap">
                    (Round One) (Unit Lead)
                  </small>
                  <small className="listline">Aug 08, 2023</small>
                  <small className="listline">1:00pm</small>
                </div>
              </div>
            </div>
            <div>
              <i className="fas fa-ellipsis-h rounded-circle colorbg-two text-white p-1"></i>
            </div>
          </div>
          <div className="left-alignbx mt-3">
            <h5 className="font-weight-bold">Oversubscription open</h5>
            <p className="m-0 py3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              sapien est, porta quis commodo a, blandit eget lectus. Proin
              tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,
              tempor quis sollicitudin et, suscipit nec ligula. Fusce
              ullamcorper tortor commodo sodales faucibus. Fusce commodo
              consectetur sapien, eget faucibus justo ultricies eget. Proin
              venenatis dictum elit, ac consectetur nisi vehicula non. Donec id
              finibus ante.
            </p>
            <div>Image Here</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
              sapien est, porta quis commodo a, blandit eget lectus. Proin
              tincidunt turpis diam, id fermentum risus sodales a. Ut ex velit,
              tempor quis sollicitudin et, suscipit nec ligula. Fusce
              ullamcorper tortor commodo sodales faucibus. Fusce commodo
              consectetur sapien, eget faucibus justo ultricies eget. Proin
              venenatis dictum elit, ac consectetur nisi vehicula non. Donec id
              finibus ante.
            </p>
          </div>
          <Button type="button" className="vmblu-btn d-block mx-auto">
            View More
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IntroBusiness;

import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import esdc from '../assets/images/esdc-logo.svg';

const Footer = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <div className="sfbg py-5">
      <Container>
        <Row className="text-left">
          <Col lg={8} md={6} sm={12}>
            <Image src={logo} alt="" className="footlogo mb-3" />
            <div className="d-flex p-2">
              <div className="left-alignbx">
                <i className="fas fa-map-marker-alt m-1"></i>
                <span className="font-weight-bold">
                  <b>Head Office</b>
                </span>
                <p className="m-0">
                  <small>
                    U-1701 Tytana Bldg, Plaza San Lorenzo Ruiz, Binondo Manila
                    1006
                  </small>
                </p>
              </div>
            </div>
            <div className="d-flex p-2">
              <div className="left-alignbx">
                <i className="fas fa-map-marker-alt m-1"></i>
                <span className="font-weight-bold">
                  <b>Branch Office</b>
                </span>
                <p className="m-0">
                  <small>
                    U-1603-1605 PSE Tower, 28th St. cor. 5th Ave., BGC, Taguig
                    1630
                  </small>
                </p>
              </div>
            </div>
            <div className="d-flex cnterflxm left-alignbx p-2">
              <i className="fas fa-mobile-alt m-1"></i>
              <div className="left-alignbx">
                <p className="m-0">
                  <small>+63 2 8820-8018</small>
                </p>
                <p className="m-0">
                  <small>+63 2 8245-6041</small>
                </p>
                <p className="m-0">
                  <small>+63 2 8251-4651</small>
                </p>
              </div>
            </div>
            <div className="d-flex cnterflxm p-2">
              <i className="far fa-envelope m-1"></i>
              <div className="left-alignbx">
                <p className="m-0">
                  <small>info@roundone.ph</small>
                </p>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12}>
            <div className="left-alignbx">
              <span className="font-weight-bold p-2">
                <b>Sitemap</b>
              </span>
              <div className="d-flex cnterflxm">
                <div className="d-flex flex-column m-2">
                  {path === '/' ? (
                    <small className="p-2">Home</small>
                  ) : (
                    <Link
                      to="/"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Home</small>
                    </Link>
                  )}

                  {path === '/Invest' ? (
                    <small className="p-2">Invest</small>
                  ) : (
                    <Link
                      to="/Invest"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Invest</small>
                    </Link>
                  )}

                  <Link
                    to="https://auth.v2.roundone.ph/"
                    style={{ textDecoration: 'none' }}
                    className="p-2"
                  >
                    <small>Login</small>
                  </Link>

                  {path === '/contactus' ? (
                    <small className="p-2">Contact Us</small>
                  ) : (
                    <Link
                      to="/contactus"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Contact Us</small>
                    </Link>
                  )}
                  {path === '/privacypolicy' ? (
                    <small className="p-2">Privacy Policy</small>
                  ) : (
                    <Link
                      to="/privacypolicy"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Privacy Policy</small>
                    </Link>
                  )}
                </div>
                <div className="d-flex flex-column m-2">
                  {path === '/Raise' ? (
                    <small className="p-2">Raise</small>
                  ) : (
                    <Link
                      to="/Raise"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Raise</small>
                    </Link>
                  )}

                  {path === '/learn' ? (
                    <small className="p-2">Learn</small>
                  ) : (
                    <Link
                      to="/learn"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Learn</small>
                    </Link>
                  )}

                  <Link
                    to="https://auth.v2.roundone.ph/registration"
                    style={{ textDecoration: 'none' }}
                    className="p-2"
                  >
                    <small>Sign Up</small>
                  </Link>
                  {path === '/riskwarning' ? (
                    <small className="p-2">Risk Warning</small>
                  ) : (
                    <Link
                      to="/riskwarning"
                      style={{ textDecoration: 'none' }}
                      className="p-2"
                    >
                      <small>Risk Warning</small>
                    </Link>
                  )}
                </div>
              </div>
              <div className="left-alignbx mt-2">
                <span className="font-weight-bold p-2">
                  <b>Connect with us</b>
                </span>
                <div className="d-flex flex-row cnterflxm m-2">
                  <Link
                    to="https://www.facebook.com/roundoneph"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="circle-icons m-1">
                      <i className="fab fa-facebook"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.instagram.com/roundoneph/"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="circle-icons m-1">
                      <i className="fab fa-instagram-square"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/roundoneph/"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="circle-icons m-1">
                      <i className="fab fa-linkedin"></i>
                    </div>
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UCM1jgfa26zIxclzQT6lpbcQ"
                    target="_blank"
                    style={{ textDecoration: 'none' }}
                  >
                    <div className="circle-icons m-1">
                      <i className="fab fa-youtube"></i>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="left-alignbx mt-2">
                <span className="font-weight-bold p-2">
                  <b>Round One is a business unit of</b>
                </span>
                <Image src={esdc} alt="" className="footlogo mb-3 p-2" />
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-3">
          <h4>
            <b>Risk Statement</b>
          </h4>
          <small>
            Crowdfunded investments are alternative investments and are thus
            generally considered high risk and speculative in nature. You are
            likely not to receive dividends or regular income from such
            investments. There is a substantial chance that you may lose the
            entirety of your investment. Fundraiser and campaigns listed or
            eligible for listing on the Round One platform are not endorsements
            by Round One regarding investability and may not be construed as
            financial advice. We caution you to speak to your financial advisor
            on how to best allocate your portfolio based on your needs and
            objectives. For additional information, check out our Risk Warnings.
          </small>
          <div className="d-flex mt-5 justify-content-center ft-sub">
            <Link to="/privacypolicy">
              <small className="p-2">Privacy Policy</small>
            </Link>
            <Link to="/riskwarning">
              <small className="p-2">Risk Warning</small>
            </Link>
            <Link to="/termsofservice">
              <small className="p-2">Terms of Service</small>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

import MainLayout from 'layout/MainLayout'
import React from 'react'
import AllNewsBanner from './AllNewsBanner'
import FiltersAllNews from './FiltersAllNews'
import SubscribeNewsletter from 'components/homepage/subscribe-newsletter'
import CardNews from './CardNews'

const AllNewsPage = () => {
    return (
        <>
             
                <AllNewsBanner />
                <FiltersAllNews />
                <CardNews />
                <SubscribeNewsletter />

        </>
    )
}

export default AllNewsPage
import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import pig from '../../assets/images/piggybank.svg';

const WantToKnowMore = () => {
  return (
    <>
      <div className="flowbx"></div>
      <div
        className="numcbgdrk wtkm-bx py-5"
        style={{ backgroundImage: `url(${pig})` }}
      >
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12}></Col>
            <Col lg={6} md={6} sm={12}>
              <div className="text-white relbox py-5">
                <h2 className="hd-titles text-white">Want to know more?</h2>
                <p>
                  <Link
                    to="https://auth.v2.roundone.ph/registration"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <b>Sign up</b>{' '}
                  </Link>
                  and explore our{' '}
                  <Link
                    to="/learn"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <b>Round One Academy</b>
                  </Link>
                  , and other content to discover more about our platform.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WantToKnowMore;

import React from 'react';
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import hpen from '../../assets/images/iw-ca-icon1.svg';
import cloudup from '../../assets/images/iw-ca-icon2.svg';
import checktg from '../../assets/images/iw-ca-icon3.svg';
import compro from '../../assets/images/raise/icons_profile.svg';
import bplan from '../../assets/images/raise/icons_business plan.svg';
import secreg from '../../assets/images/raise/icons_sec registration.svg';
import fpt from '../../assets/images/raise/icons_financial terms.svg';
import pitd from '../../assets/images/raise/icons_pitch deck.svg';
import gp1 from '../../assets/images/raise/icons_target.svg';
import gp2 from '../../assets/images/raise/icons_key.svg';
import gp3 from '../../assets/images/raise/icons_time.svg';
import gp4 from '../../assets/images/raise/icons_oversubscription.svg';
import rocket from '../../assets/images/raise/icons_rocket walkthrough.svg';
import itl from '../../assets/images/issuer-transactionlist.svg';
import updates from '../../assets/images/company-updates.svg';
import undersubs from '../../assets/images/raise/undersubscribed_april_4_2024.svg';
import oversubs from '../../assets/images/raise/oversubscribed_april_4_2024.svg';
import cc1 from '../../assets/images/raise/icons_closing documents.svg';
import cc2 from '../../assets/images/raise/raise_icons_stock_certificates.svg';
import cc3 from '../../assets/images/raise/icons_receive.svg';
import cc4 from '../../assets/images/raise/icons_message bubble.svg';
import dashboard2 from '../../assets/images/raise/issuer_campaign_status_edit_april_4_2024.svg';
// import endphto from '../../../assets/images/iw-end-photo.jpg';

const FundraiserWalkthrough = () => {
  return (
    <>
      <div className="flowbx text-center text-white py-5">
        <Container>
          <h2 className="hd-titles-whte">Fundraiser Walkthrough</h2>
          <small>
            A step-by-step guide to get started for your first campaign.
          </small>
        </Container>
      </div>
      <Container>
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">1</div>
            <h4 className="hd-colr font-weight-bold m-2">CREATE AN ACCOUNT</h4>
          </div>
          <div className="d-flex align-items-center cnterflxm mb-3">
            <small className="blu-t blu-t m-2">www.roundone.ph</small>
            {/* <Link
              to="https://auth.v2.roundone.ph/registration"
              style={{ textDecoration: 'none' }}
            > */}
            {/* <div className="static-btn  btn signbtn">Sign Up</div> */}
            <div className="shp-butn static-btn text-white">Sign Up</div>
            {/* </Link> */}
          </div>
          <div className="text-small text-center font-weight-bold">
            Unlock features that enable you to launch live offerings on our
            platform.
          </div>
          <Row className="align-items-center justify-content-between iacbox my-4">
            <Col lg={4} md={4} sm={12} className="mb-3">
              <div className="d-flex flex-row align-items-center">
                <div className="num-ylw">1</div>
                <Image src={hpen} alt="" className="hdw-sz mx-3" />
                <span className='text-left'>Fill up company profile</span>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="mb-3">
              <div className="d-flex flex-row align-items-center">
                <div className="num-ylw">2</div>
                <Image src={cloudup} alt="" className="hdw-sz mx-3" />
                <span className='text-left'>Upload documentary requirements</span>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className="mb-3">
              <div className="d-flex flex-row align-items-center">
                <div className="num-ylw">3</div>
                <Image src={checktg} alt="" className="hdw-sz mx-3" />
                <span className='text-left'>Await account approval</span>
              </div>
            </Col>
          </Row>
          <div className="req-box">
            <div className="text-small text-center font-weight-bold">
              {/* Documentary requirements, includes the following: */}
              <h3 className="font-weight-bold hd-colr">
                Requirements:{' '}
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      Priority submission for company profile and business plan,
                      while the rest may follow
                    </Tooltip>
                  }
                >
                  <i
                    className="fas fa-info-circle hd-colr hvr-info px-2 info-icon"
                    // data-toggle="tooltip"
                    // title="Priority submission for company profile and business plan, while the rest may follow"
                  ></i>
                </OverlayTrigger>
              </h3>
            </div>
            <div className="iacbox">
              <div className="d-flex justify-content-center flex-wrap my-4">
                <div className="smllw mb-4">
                  <div className="d-flex flex-column align-items-center fulheight text-center">
                    <Image src={compro} alt="" className="env-sz mb-2" />
                    <span className="font-weight-bold">Company Profile</span>
                  </div>
                </div>
                <div className="smllw mb-4">
                  <div className="d-flex flex-column align-items-center fulheight text-center">
                    <Image src={bplan} alt="" className="env-sz mb-2" />
                    <span className="font-weight-bold">Business Plan</span>
                  </div>
                </div>
                <div className="smllw mb-4">
                  <div className="d-flex flex-column align-items-center fulheight text-center">
                    <Image src={secreg} alt="" className="env-sz mb-2" />
                    <span className="font-weight-bold">Company Registration</span>
                  </div>
                </div>
                <div className="smllw mb-4">
                  <div className="d-flex flex-column align-items-center fulheight text-center">
                    <Image src={fpt} alt="" className="env-sz mb-2" />
                    <span className="font-weight-bold">
                      Financial Plans & Term Sheets
                    </span>
                  </div>
                </div>
                <div className="smllw mb-4">
                  <div className="d-flex flex-column align-items-center fulheight text-center">
                    <Image src={pitd} alt="" className="env-sz mb-2" />
                    <span className="font-weight-bold">Pitch Deck</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">2</div>
            <h4 className="hd-colr font-weight-bold m-2">PREPARE A GAMEPLAN</h4>
          </div>
          <div className="text-small text-center font-weight-bold">
            Before you fundraise, think about the following:
          </div>
          <Row className="iacbox my-4">
            <Col lg="3" md="3" sm="12">
              <div className="d-flex flex-column align-items-center text-center m-2">
                <Image src={gp1} alt="" className="env-sz mb-2" />
                <span className="font-weight-bold">
                  What's my
                  <br /> funding target?
                </span>
              </div>
            </Col>
            <Col lg="3" md="3" sm="12">
              <div className="d-flex flex-column align-items-center text-center m-3">
                <Image src={gp2} alt="" className="env-sz mb-2" />
                <span className="font-weight-bold">
                  How much equity
                  <br /> will I offer?
                </span>
              </div>
            </Col>
            <Col lg="3" md="3" sm="12">
              <div className="d-flex flex-column align-items-center text-center m-2">
                <Image src={gp3} alt="" className="env-sz mb-2" />
                <span className="font-weight-bold">
                  How long will I set
                  <br /> the offer period?
                </span>
              </div>
            </Col>
            <Col lg="3" md="3" sm="12">
              <div className="d-flex flex-column align-items-center text-center m-2">
                <Image src={gp4} alt="" className="env-sz mb-2" />
                <span className="font-weight-bold">
                  Will you allow
                  <br /> oversubscriptions?
                </span>
              </div>
            </Col>
          </Row>
          <div className="text-center">
            Work with us on developing your campaign offering, strategy and
            materials.
          </div>
        </div>
        <hr />
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">3</div>
            <h4 className="hd-colr font-weight-bold m-2">LAUNCH A CAMPAIGN</h4>
          </div>
          <Image src={rocket} alt="" className="wdth-mxmble mb-2" />
          <h4 className="blu-t iacbox font-weight-bold text-center">
            When you're all set, launch your campaign and start pitching,
            promoting, and interacting with potential investors.
          </h4>
        </div>
        <hr />
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">4</div>
            <h4 className="hd-colr font-weight-bold m-2">RECEIVE PLEDGES</h4>
          </div>
          <Row className="align-items-center">
            <Col lg={6} md={6} sm={12} className="mb-3">
              <div className="d-flex align-items-center blu-t cnterflxm my-3">
                <small>In Dashboard</small>
                <small className="lessthn"></small>
              </div>
              <Image src={itl} alt="" className="full-w" />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <h4 className="hd-colr left-alignbx font-weight-bold">
                Track investor commitments as they come in.
              </h4>
            </Col>
          </Row>
        </div>
        <hr />
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">5</div>
            <h4 className="hd-colr font-weight-bold m-2">MONITOR PROGRESS</h4>
          </div>
          <div className="d-flex align-items-center blu-t cnterflxm my-3">
            <small>In Dashboard</small>
            <small className="lessthn"></small>
          </div>
          <Image src={dashboard2} alt="" className="full-w mrgn-cntr my-3" />
          <div className="d-flex flex-column justify-content-center text-center">
            <p className="pt-3">
              Follow your campaign status and regularly participate in your
              discussion threads.
            </p>
            <Image
              src={updates}
              alt=""
              className="grn-exclmtry mrgn-cntr my-3"
            />
            <p>Update investors on your company’s progress.</p>
          </div>
        </div>
        <hr />
        <div className="py-3">
          <div className="d-flex flex-row align-items-center cnterflxm">
            <div className="num-stp flowbx text-white">6</div>
            <h4 className="hd-colr font-weight-bold m-2">
              CAMPAIGN CONCLUSION
            </h4>
          </div>
          <Row className="align-items-center my-3">
            <Col lg={6} md={6} sm={12} className="mb-3">
              <div className="d-flex align-items-center blu-t cnterflxm my-3">
                <small>In Dashboard</small>
                <small className="lessthn"></small>
              </div>
              <Image
                src={undersubs}
                alt=""
                className="d-block grn-exclmtry mrgn-cntr"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="text-small left-alignbx font-weight-bold  hd-colr pb-3">
                <h4 className="font-weight-bold hd-colr">
                  Undersubscribed Campaigns
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        For more information on relaunching your campaign, visit
                        Learn page
                      </Tooltip>
                    }
                  >
                    <i
                      className="fas fa-info-circle hd-colr hvr-info px-2 info-icon"
                      //data-toggle="tooltip"
                      //title="For more information on relaunching your campaign, visit <link to Learn page>"
                    ></i>
                  </OverlayTrigger>
                </h4>
              </div>
              <p className="left-alignbx">
                Fundraisers that are unable to complete their target fundraise
                amount will be notified that the campaign has ended, and
                subscribe amounts will be reverted to the investor’s respective
                wallets.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center my-3">
            <Col lg={6} md={6} sm={12} className="mb-3">
              <div className="d-flex align-items-center blu-t cnterflxm my-3">
                <small>In Dashboard</small>
                <small className="lessthn"></small>
              </div>
              <Image
                src={oversubs}
                alt=""
                className="d-block grn-exclmtry mrgn-cntr"
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div className="text-small left-alignbx font-weight-bold  hd-colr pb-3">
                <h4 className="font-weight-bold hd-colr">
                  Oversubscribed Campaigns
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        For more information on receiving your funds, visit
                        Learn page
                      </Tooltip>
                    }
                  >
                    <i
                      className="fas fa-info-circle hd-colr hvr-info px-2 info-icon"
                      //data-toggle="tooltip"
                      //title="For more information on receiving your funds, visit <link to Learn page>"
                    ></i>
                  </OverlayTrigger>
                </h4>
              </div>
              <p className="left-alignbx">
                100% funded or oversubscribed campaigns can close out their
                offering upon the conclusion of their deadlines.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center my-3">
            <Col lg={6} md={6} sm={12}>
              <h3 className="text-danger text-uppercase font-weight-bold left-alignbx">
                But wait! don’t forget to....
              </h3>
              <p className="left-alignbx">
                Complete closing documentation, issue stock certificates, and
                receive your campaign funds.
              </p>
              <p className="left-alignbx">
                Most importantly, maintain good investor relations by keeping
                your committed investors up-to-date on your company’s progress.
              </p>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column align-items-center text-center m-3">
                    <Image src={cc1} alt="" className="hdw-sz mb-2" />
                    <span className="font-weight-bold">
                      Complete closing
                      <br /> documentation
                    </span>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column align-items-center text-center m-3">
                    <Image src={cc2} alt="" className="hdw-sz mb-2" />
                    <span className="font-weight-bold">
                      Complete investment 
                      <br /> agreement
                    </span>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column align-items-center text-center m-3">
                    <Image src={cc3} alt="" className="hdw-sz mb-2" />
                    <span className="font-weight-bold">
                      Receive your
                      <br /> campaign funds
                    </span>
                  </div>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <div className="d-flex flex-column align-items-center text-center m-3">
                    <Image src={cc4} alt="" className="hdw-sz mb-2" />
                    <span className="font-weight-bold">
                      Maintain <br /> investor relations
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default FundraiserWalkthrough;

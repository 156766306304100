import { Container, Image, Stack } from 'react-bootstrap';
import MainLayout from 'layout/MainLayout';
import banner from 'assets/images/termsofservice/banner.png';

const TermsOfService = () => {
  return (
    <>
       
        <div className="mrgntp-hdr"></div>
        <div className="text-center py-4">
          <h1 className="font-weight-bold py-2">
            Round One - User Terms of Service
          </h1>
        </div>
        <Container>
          <div className="iacbox">
            <div className="overflow-hidden small-brdrds mb-4">
              <Image
                src={banner}
                alt=""
                height={'540px'}
                width={'1280px'}
                className="rounded-4"
              />
            </div>
            <div className="text-start">
              <div>
                <p>
                  These User Terms of Service (the “Terms”) constitute a legal
                  agreement between You, as the user of the Round One website
                  (the “Website”), whether as a guest or a registered member, in
                  any capacity (General Member, Authorized-Investor Member,
                  Issuer Member) (each, a “User”), and Round One, as the owner
                  of the platform that is accessible through the web (the
                  “Website”) and the various features of the crowdfunding
                  platform as made available on the Website (collectively, along
                  with the Website, these shall be referred to as the
                  “Services”). Throughout these Terms we will refer to you as
                  “you”, and we will refer to Round One Limited as “we”, “us” or
                  “Round One”. Round One is owned and operated by Eastern
                  Securities Development Corporation.
                </p>
                <p>
                  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING
                  THE SERVICES. BY ACCESSING THE WEBSITE AND/OR USING THE
                  SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND
                  AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS AND ANY OTHER
                  APPLICABLE LAWS AND REGULATIONS. IF YOU DO NOT ACCEPT THESE
                  TERMS AND CONDITIONS, PLEASE REFRAIN FROM USING THIS SERVICES
                  IMMEDIATELY.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  1.1.1. Acknowledgements refers to the act that you understand
                  and agree with each of the statements and descriptions set out
                  in this agreement;
                </p>
                <p>
                  1.1.2. Account means the Round One Crowdfunding account of the
                  member that is maintained with ESDC.
                </p>
                <p>
                  1.1.3. Activity refers to the campaign and investment and
                  other activities related to it;
                </p>
                <p>
                  1.1.4. Additional information refers to the information we
                  provide on the platform other than information contained in a
                  Campaign;
                </p>
                <p>
                  1.1.5. Agent means, in the case of a juridical member, that
                  natural person acting on such member's behalf;
                </p>
                <p>
                  1.1.6. Agreement refers to these Terms of Service and the
                  Platform Agreements (including the Privacy Statement) between
                  you and us;
                </p>
                <p>
                  1.1.7. Applicable Laws refers to all laws and regulations in
                  the Philippines including rules and regulations issued by
                  government agencies and regulators.
                </p>
                <p>
                  1.1.8. Break Fee refers to an early fee equal to (a) 5%, or
                  (b) 7.5%, respectively, of the targeted offering amount for
                  the consideration of the work we have performed and the value
                  we have created for your campaign.
                </p>
                <p>
                  1.1.9. Campaign or CF Campaign refers to the campaign summary
                  and detailed campaign initiated by the Issue as an online
                  fundraising event, taken together, created and made available
                  on the Platform;
                </p>
                <p>
                  1.1.10. Campaign Engagement Letter refers to an agreement by
                  which the Issuer engages Round One in relation to the
                  Campaign, and of which the Campaign Terms and Conditions form
                  a part;
                </p>
                <p>
                  1.1.11. Campaign summary refers to the summary information
                  about Issuer’s CF Campaign through the platform;
                </p>
                <p>
                  1.1.12. Change refers to the changes of any fact,
                  circumstance, event or information about a campaign.
                </p>
                <p>
                  1.1.13. Client refers to registered Issuer and Investor of
                  Round One Crowdfunding Platform.
                </p>
                <p>
                  1.1.14. Committed refers to the amount invested by an Investor
                  member in a Campaign on the Platform;
                </p>
                <p>
                  1.1.15. Crowdfunding or CF Intermediary refers to Round One as
                  a registered Crowdfunding Intermediary;
                </p>
                <p>
                  1.1.16. CF Rules refers to SEC Memorandum Circular no. 14
                  series of 2019 which governs the Crowdfunding activities in
                  the Philippines;
                </p>
                <p>
                  1.1.17. CF Securities means the securities issued by the
                  registered Issuer of Round One as a Crowdfunding platform to
                  be sold and offered to the registered investors on the
                  Platform.
                </p>
                <p>
                  1.1.18. Detailed Summary refers to the detailed information
                  about Issuer’s CF Campaign through the platform that is
                  displayed when clicking through a campaign summary;
                </p>
                <p>
                  1.1.19. ESDC means Eastern Securities Development Corporation
                  managing the Round One Crowdfunding platform;
                </p>
                <p>
                  1.1.20. Execute refers to this Agreement, any Platform
                  Agreement or any other agreement you enter with us, express
                  your assent to be bound by its terms through the electronic
                  means provided on the platform or otherwise sign or execute in
                  a manner approved by us;
                </p>
                <p>
                  1.1.21. Foreign member refers to Round One members who are
                  situated in territories outside the Philippines;
                </p>
                <p>
                  1.1.22. Issuer refers to the member who uses the platform to
                  raise funds by conducting a CF Campaign;
                </p>
                <p>
                  1.1.23. Investor refers to a Round One member whom we have
                  authorized to act as an investor;
                </p>
                <p>
                  1.1.24. Investment Account refers to the account created by
                  the investor where he/she will receive, hold and distribute
                  money invested or distributed in connection with an
                  investment;
                </p>
                <p>
                  1.1.25. Investment Agreement refers to an Investment Agreement
                  you enter into when making an investment through the platform;
                </p>
                <p>
                  1.1.26. Nominee and Proxy Services (“Nominee Services”) refers
                  to arrangements whereby the Investor authorizes us to hold
                  securities on his/her behalf and/or to exercise rights
                  pertaining to such securities upon his/her instructions and as
                  provided in the Terms and written agreements;
                </p>
                <p>
                  1.1.27. Overfunding Period refers to the period that
                  authorized-investor members can continue to invest in a
                  campaign which has reached the targeted offering amount until
                  the Campaign has been closed;
                </p>
                <p>1.1.28. Person refers to a natural or juridical person</p>
                <p>
                  1.1.29. Personal data has the meaning given in the Privacy
                  Statement
                </p>
                <p>
                  1.1.30. Platform means, the Round One platform, which includes
                  the website currently hosted at the domain
                  http://www.roundone.ph
                </p>
                <p>
                  1.1.31. Platform Agreements refers to any other agreement you
                  enter into in connection with your use of the Platform;
                </p>
                <p>
                  1.1.32. Portfolio refers to the section on the Platform where
                  your investments are listed;
                </p>
                <p>
                  1.1.33. Privacy Statement refers to the privacy statement of
                  Round One which is published on the Website and of which you
                  are notified when creating an Account;
                </p>
                <p>
                  1.1.34. Profile refers to the profile you complete as part of
                  joining Round One;
                </p>
                <p>
                  1.1.35. Round One member or Member refers to a person who has
                  joined the Round One platform as a member, including affirming
                  assent to the relevant Membership Agreement and whose
                  membership has not been terminated or suspended;
                </p>
                <p>
                  1.1.36. SEC refers to the Securities and Exchange Commission
                  in the Philippines;
                </p>
                <p>
                  1.1.37. Securities refers to securities as defined in the
                  Securities Regulation Code and its implementing rules and
                  regulations;
                </p>
                <p>
                  1.1.38. SRC refers to the Securities Regulation Code of the
                  Philippines;
                </p>
                <p>
                  1.1.39. Success Fee refers to the charge for our service if
                  the Issuer raises the investment it is seeking;
                </p>
                <p>
                  1.1.40. Targeted offering amount refers to the minimum
                  investment that the Issuer states it wishes to raise by way of
                  the Campaign;
                </p>
                <p>
                  1.1.41. Total Invested Amount refers to the amount invested by
                  an authorized-investment member in your campaign/offer.
                </p>
                <p>
                  1.1.42. We, us and our refers to Round One, any of our
                  subsidiaries and any holding company and where the context
                  permits, our permitted assignees, transferees and delegates;
                </p>
                <p>
                  1.1.43. Working day or Business day refers to any day other
                  than a Saturday, Sunday, national holiday; and
                </p>
                <p>
                  1.1.44. You or your refers to you, as the Round One member,
                  who is a party to this Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  2. Application 2.1. These Terms of Service set out the general
                  terms governing your use of the Website or the platform and
                  the terms of the relationship between Round One and you as a
                  Round One member. Unless otherwise provided, these provisions
                  apply to all Round One members. They apply to you as soon as
                  you first use the Website, and you are deemed to have agreed,
                  without restriction, to be bound by them upon your first use
                  of the Website. If you do not wish to continue to be bound by
                  these Terms and the documents constituting the Agreement,
                  please stop accessing or using the Services now.
                </p>
                <p>
                  2.2. This Agreement is a binding contract between you and us,
                  and it sets out your and our rights and obligations with
                  respect to your membership of Round One and use of the
                  platform. In executing this Agreement, you are indicating that
                  you agree to adhere to, and be bound by, all of its terms.
                </p>
                <p>
                  2.3. The Services are only made available to persons with the
                  legal capacity to enter into contracts under Philippine law.
                  By using the Services, you warrant and represent that you are
                  at least 18 years old and possess the necessary legal capacity
                  to be bound by a contract.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  3. Membership and Account Details 3.1. If you would like to
                  see and/or use any of our services and products on the
                  Website, you will need to register for an account on the
                  Website (“Account”) through which you will be able to access
                  the restricted features of the Website. These features can be
                  accessed only if you are a member.In order to join Round One
                  as a member, you must be either (a) a natural person who is
                  eighteen (18) years of age or over ("individual member"); or
                  (b) a juridical or legal entity, including Single Proprietors,
                  Cooperatives, Partnerships, or Corporations ("juridical entity
                  member"); and in all cases (c) possess all the necessary
                  juridical capacity to enter into contracts in the Philippines.
                  In executing this Agreement, you warrant to us that you meet
                  the criteria of an individual member or a juridical entity
                  member and that you are not subject to the laws of any
                  territory which would make your participation on the Platform
                  unlawful.
                </p>
                <p>
                  3.2. You need to fill out the registration form in the Signup
                  section(s), and give your name, username, and email address,
                  and elect a password. In doing so, you also need to agree to
                  accept all the terms in the Platform Agreements that may be
                  applicable to you. You will then be treated by us as a Member
                  and you will be given restricted access to the Platform,
                  whereby you will not have right to make investments via the
                  Platform yet. Access to other features may be subject to
                  additional terms. That means that you will only be given a
                  restricted right to review certain details of the currently
                  available Campaigns or offers. You warrant that all the
                  information contained in your Account and that you submit to
                  the Platform is complete, true and correct. We are entitled to
                  rely on such information until we have received written notice
                  from you of any change regarding such information. By creating
                  an Account, you hereby authorize ESDC to conduct a credit
                  inquiry or check on your financial situation and investment
                  objectives.
                </p>
                <p>
                  3.3. You must inform us as soon as reasonably practicable if
                  you cease to be eligible to be a Member. If you inform us, or
                  if we otherwise discover without your notification, that you
                  have ceased to be eligible as a Member, your membership will
                  be suspended. If you later become eligible to be a Member
                  again and inform us of this, your Round One membership may be
                  reinstated at our discretion.
                </p>
                <p>
                  3.4. If you are using the Services on behalf of a juridical
                  person or any other entity, you represent and warrant that you
                  are duly authorized by such third person to represent it and
                  bind it to these Terms and other transactions and activities
                  you make through the Services. You further warrant that you
                  have procured all necessary corporate or group approvals and
                  authorizations to (i) authorize and effectuate the performance
                  of your obligations under the Terms and other supplemental
                  agreements between you and u and (ii) ensure your compliance
                  with said obligations for while you are using our Platform and
                  services. If you are a juridical entity member, you must
                  designate at least one (1) natural person to take action on
                  your behalf. We refer to this person as your "Agent". Your
                  Agent is the person who provided his or her name as your
                  appointed representative in the application form on the
                  platform. Should you wish to replace your Agent at any time,
                  you may do so by having either the outgoing Agent or one of
                  your directors or officers notify us. Your Agent is not an
                  individual member, unless that he or she joins separately as
                  such, and only has rights and obligations under this Agreement
                  to the extent that he or she is, or purports to be, acting for
                  you. Throughout this Agreement, any reference to "you" which
                  relates to taking a particular action through the platform or
                  doing anything else that only a natural person can physically
                  do, shall be read as a reference to your Agent doing so on
                  your behalf, while any other reference to "you" shall be read
                  as a reference to you as a juridical entity member.
                </p>
                <p>
                  3.5. You are required to create a username for, and allocate a
                  password to, your Account. You are personally responsible for
                  maintaining the confidentiality of your password and other
                  details in relation to your Account at all times. You agree
                  that any person to whom your username or password is disclosed
                  is authorized to act as your agent for the purposes of using
                  (and/or transacting via) your Account. You agree to accept
                  responsibility for all activities that occur under your
                  account and notify us immediately of any unauthorized use or
                  any other breach of security in relation to your account.
                  Please note that you are entirely responsible if you do not
                  maintain the confidentiality of your password. At no time
                  should you respond to an online request for a password of your
                  Account. We will not ask for your password offline or online,
                  except that you will be required to enter your password as
                  part of the log-in process.
                </p>
                <p>
                  3.6. Your Account must be registered with a valid personal
                  email address that you access regularly, so that, among other
                  things, administration emails and communications regarding
                  your activities on the Website can be sent to you. You may
                  also be required to submit additional information and
                  documents prior to the creation of your Account pursuant to
                  our internal policies and applicable laws and regulations. Any
                  Account which has been registered with someone else's email
                  address, temporary email addresses, fictitious identities, or
                  with the use of inaccurate information, may be suspended or
                  closed without notice. We may require you to validate Accounts
                  at registration or if we believe you have been using an
                  invalid or inaccurate information.
                </p>
                <p>
                  3.7. You may only become a Member of Round One once and you
                  may only have one Account. You may not attempt to create
                  multiple or fictional memberships for yourself by using
                  different email addresses or other identifying information. If
                  you attempt to do so, your membership may be terminated. You
                  also undertake not to create fictitious memberships.
                </p>
                <p>
                  3.8. We may from time to time require you to provide us with
                  such information or documentary proof in respect of the
                  matters set out in the Application Form you have submitted and
                  in respect of your Account, and if so required, you shall
                  provide such information and/or documentary proof as may be
                  required by us.
                </p>
                <p>
                  3.9. We reserve the right to take any action we deem
                  appropriate upon receiving notification from you, other users,
                  law enforcement agencies, or other government regulations
                  regarding any unauthorized or fraudulent use of your Account
                  or any intentions or actions that disrupts the Website or
                  harms other Website users in any way.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  4. Our Relationship With You 4.1. Upon execution of this
                  Agreement, you will become a Round One member, and you will
                  remain a Round One member until and unless your membership is
                  terminated or suspended. Our obligations to you as a Round One
                  member are as set forth in this Agreement and other Platform
                  Agreements. You are not our customer or client.
                </p>
                <p>
                  4.2. Client. If and to the extent that you make an investment
                  through the Platform or conduct a Crowdfunding Campaign
                  through the Platform, you are our client, and we will treat
                  you as our client for all purposes related to such investment
                  or such crowdfunding.
                </p>
                <p>
                  4.3. No Advice. We do not provide advice or recommendations
                  with respect to any aspect of transactions conducted through
                  the Platform, other than advice on the technical use of the
                  platform. We cannot give you any investment, legal, taxation
                  or other advice in connection with your membership or any
                  investments you make through the Platform, and nothing on the
                  Platform or in any communications we send to you is intended
                  to constitute advice or a recommendation. If you need or want
                  advice, you should consult an appropriate professional
                  financial, legal, taxation, or other advisor.
                </p>
                <p>
                  4.4. Location of Activities. In executing this Agreement, you
                  agree that all of the activities that we conduct with you take
                  place within the Philippines, regardless of where you may be
                  physically located at the time you use the Platform or
                  otherwise engage with us. This means that you agree that our
                  activities are subject only to the laws of the Philippines and
                  not to the laws of any other country in which you may be
                  physically present at any given time, and therefore that any
                  redress you may seek from us must be sought under Philippines
                  law. However, you agree to comply with all relevant laws and
                  regulations that may apply to your use of the Platform in any
                  jurisdiction in which you may be located.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  5. The Platform 5.1. Role. Your interactions with us and with
                  other Round One members will take place entirely through the
                  Platform. If you act as an Issuer, you will use the Platform
                  both for the process of conducting Crowdfunding Campaigns and
                  for offering and selling securities. If you act as an
                  Investor, you will use the Platform to view Campaigns and make
                  investments. Most communications between us and you, and
                  between you and other Round One members, will take place
                  through the Platform. In general, you should expect that you
                  will not be able to communicate with us or with other Round
                  One members via any means other than the Platform. If you are
                  uncomfortable using the Platform for these interactions, you
                  should not execute this Agreement and become a Round One
                  member.
                </p>
                <p>
                  5.2. Transactions Regarding the Campaigns. You acknowledge
                  that pursuant to our exertion of efforts and investment of
                  resources towards any and all Campaigns on the Platform
                  (including but not limited the exercise of due diligence,
                  review of qualifications of issuers and investors, etc.) as
                  well as the CF Rules, all securities that are contemplated and
                  transacted by any Round One member under any of the Campaigns
                  created and registered on the Platform shall be exclusively
                  offered or sold through the Platform. Any issuance of
                  securities outside the Platform shall be deemed a violation of
                  this Agreement and an unauthorized offering and sale of
                  securities under the CF Rules and Applicable Law.
                </p>
                <p>
                  5.3. Security. Your security tokens are the methods used by us
                  to identify you and so you must keep them secure at all times.
                  You must notify us immediately if you learn or suspect that
                  the security of your username or password, or of the account
                  or accounts you use to sign in to the Platform, may have been
                  breached. If we receive such a notification from you or
                  determine ourselves that the security of one of your security
                  tokens may have been breached, you will not be able to access
                  the Platform until measures have been taken to verify your
                  identity.
                </p>
                <p>
                  5.4. Profile. At some stage after you log onto the Platform,
                  you will be asked to complete a profile. The information you
                  provide in your profile is the basis on which we identify you
                  for purposes of communicating with you, fulfil any reporting
                  obligations that we have to any regulatory or governmental
                  authorities, and conduct any anti-money laundering or other
                  checks we run on you, which you may change at any time. To the
                  extent that you choose to make your profile "public" for
                  certain purposes, it will also be the basis on which other
                  Round One members identify you. You must complete the profile
                  truthfully, and you must keep the information in your profile
                  up to date.
                </p>
                <p>
                  5.5. Content. You are solely responsible for any content you
                  post on the Platform, including your profile, information
                  about Crowdfunding Campaign and any other information you
                  post. You warrant that you have all necessary rights and
                  clearances over the materials you post. You may not post,
                  transmit or share information on the Platform that you do not
                  own or have permission to display, publish or post. Neither we
                  nor other Round One members shall be liable for your content,
                  and you agree to compensate us and other Round One members for
                  any loss, damages or costs arising from or in connection with
                  any content you post on the Platform.
                </p>
                <p>
                  5.6. Interactions with Other Round One Members. You are solely
                  responsible for your interactions with other Round One
                  members. We reserve the right but are not obliged, to monitor
                  actions and disputes between you and other Round One member,
                  and we reserve the right to remove any communications that you
                  post that we consider to be violative of the Platform
                  Agreements, abusive, trolling, hateful, spam, or otherwise
                  inappropriate (including any discussion of commencing legal
                  action).
                </p>
                <p>
                  5.7. Sole Communication. All communications that you make to
                  any other Round One member regarding a Campaign or the
                  securities subject of or intended to be subject of a Campaign
                  or any communication between you and other Round One members
                  must be made solely through the communication channels made
                  available on the Platform.. Making communications pertaining
                  to Campaigns through means outside of the Platform will be
                  deemed as a violation of this document.
                </p>
                <p>
                  5.8. Public Access. By using the communication channels made
                  available on the Platform, you agree and acknowledge that
                  RoundOne shall permit public access to view all discussions
                  made in such communication channels.
                </p>
                <p>
                  5.9. Anti-Money Laundering. You hereby warrant and undertake
                  that you are not and have not been engaged, directly or
                  indirectly, in any transaction that involves proceeds of any
                  unlawful activity and always in compliance with the
                  Philippines Anti-Money Laundering Acts and other related laws.
                  You shall not use the Platform to further acts that violate
                  anti-money laundering rules and other Applicable Laws.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  6. Investor Members 6.1. Restriction. Not all Round One
                  members are allowed to become Investors. In order to become
                  what we call an "Investor member", you will be classified
                  either as a "retail investor" or a "qualified buyer or
                  investor". You will be subject to investment limitations
                  provided under the Investment Agreement. As an Investor
                  member, the terms of the Investor Agreement shall govern our
                  relationship with you in addition to this Agreement.
                </p>
                <p>
                  6.2. As an Investor member, you are required to be aware of
                  the risk of investing in a Campaign on this Platform. Without
                  prejudice to the Investor Agreement, You warrant and represent
                  that you are aware or acquainted with the risk in investing.
                  By executing this Agreement you warrant to us that, in your
                  view, you have the experience, expertise, and knowledge to
                  understand the risks involved in and make your own investment
                  decisions about, investments in early-stage and other
                  businesses of the type displayed on the Platform.
                </p>
                <p>
                  6.3. Investment Questionnaire. You are required to answer a
                  questionnaire that completely demonstrates that you understand
                  (i) that there are restrictions on your ability to cancel an
                  investment commitment and you may or may not obtain a return
                  of your investment, (ii) that it may be difficult for you to
                  resell the securities you acquire, (iii) that you are aware
                  that investing in securities offered and sold in the Platform
                  involves risk, and (iv) that you should not invest any funds
                  in an offering unless you can afford to lose the entire amount
                  of your investment.
                </p>
                <p>
                  6.4. Entirely Our Decision; No Liability. The decision of
                  whether to authorize you to act as an Investor is entirely
                  ours and we may decide for any reason or no reason not to
                  authorize you to participate in the Platform as an Investor.
                  We will not be liable to you for any losses, damages or costs
                  arising from our decision not to authorize you.
                </p>
                <p>
                  6.5. Revoking Authorization. If we have authorized you to act
                  as an Investor, we will not normally revoke that
                  authorization, except in accordance with termination or
                  suspension of your membership, but we reserve the right to do
                  so if facts come to our attention that leads us to believe
                  that you may not have the experience, expertise and knowledge
                  required to understand the risks involved in, and make your
                  own investment decisions about, the investments available
                  through the Platform or if we consider there may be legal
                  restrictions on you making investments through the Platform.
                  Separately, if you no longer wish to be an Investor member,
                  you may notify us of this, and we will revoke your
                  authorization on that basis. In the event that your
                  authorization is revoked, you will no longer be able to act as
                  an investor.
                </p>
                <p>
                  6.6. Truthfulness and Non-Circumvention. You must complete the
                  investment application process truthfully, honestly, and
                  completely.
                </p>
                <p>
                  6.7. Type of Investments. If you become an Investor member,
                  you will have the opportunity to invest in Crowdfunding
                  Campaigns available from time to time on the Platform. Each of
                  these investments consists of securities in one or more
                  Campaigns.
                </p>
                <p>
                  6.8. Cancellation Rights. You may have the right to cancel
                  your investment for a specified period of time. Any such
                  exercise of cancellation rights will be circumscribed in the
                  relevant Investment Agreement.
                </p>
                <p>
                  6.9. Taxation. Depending on your individual tax position, you
                  may be liable to pay taxes on any dividends or gains you
                  receive from your investments. Payment of these taxes is
                  entirely your responsibility, and as required by Philippine
                  law we will not deduct or withhold any taxes for you or
                  provide you with any statements or information with respect to
                  your tax liability.
                </p>
                <p>
                  6.10. Acting as Issuer. Acting as an Investor does not
                  preclude you from also acting as an Issuer, either
                  contemporaneously or at a different time. In the event that
                  you choose to act both as an Investor and as an Issuer, the
                  provisions of this Agreement relevant to Investors will apply
                  to you to the extent that you are acting as an Investor, and
                  the provisions of this Agreement relevant to Issuers will
                  apply to you to the extent that you are acting as an Issuer.
                </p>
                <p>
                  6.11. Fees. We will only charge you a fee for acting as an
                  Investor if you make a profit from an investment or if you
                  receive proceeds from an investment that in aggregate exceeds
                  the amount of capital you invested. This means that, once you
                  have received your capital back from a given investment, any
                  future proceeds will be subject to a deduction for our fee.
                  Our rights to fees in respect of any given investment will be
                  as set forth in the relevant Investment Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  7. Issuer Members 7.1. Restriction on Issuers. To become an
                  Issuer, you must be an entity organized under the laws of the
                  Philippines or a Filipino natural person. Offers of securities
                  are subject to the limitations provided under the CF Rules. As
                  an Issuer member, the terms of the Issuer Agreement shall
                  govern our relationship with you in addition to this Agreement
                </p>
                <p>
                  7.2. Eligibility of Businesses. The determination as to
                  whether an entity or natural person is eligible to conduct a
                  Crowdfunding Campaign through the platform is ours and ours
                  alone, and we may determine that an entity or a person is not
                  eligible for any reason or no reason. We are not required to
                  communicate the reason for any such determination to you.
                </p>
                <p>
                  7.3. Creating a Campaign. In order to conduct a Campaign using
                  the Platform, you will need to follow the process set forth in
                  the relevant section of the Platform. This process requires
                  you to answer the application forms and questionnaires, along
                  with evidence to support certain of those answers. The
                  Campaign will stay active for a specified period of time, as
                  set forth in the Issuer Agreement.
                </p>
                <p>
                  7.4. Multiple Campaigns. You may create more than one
                  Campaign, and you may have multiple Campaigns active at one
                  time as long as it is within the limits of securities to be
                  offered and sold within twelve (12) months prescribed under
                  the Issuer Agreement and other agreements. In order to create
                  an additional Campaign, you will need to repeat the process
                  described in the applicable agreements.
                </p>
                <p>
                  7.5. Taxation. Depending on your individual tax position, you
                  may be liable to pay taxes on any dividends or gains you
                  receive from your investments. Payment of these taxes is
                  entirely your responsibility, and as required by Philippine
                  law we will not deduct or withhold any taxes for you or
                  provide you with any statements or information with respect to
                  your tax liability.
                </p>
                <p>
                  7.6. Acting as Investor. Acting as an Issuer does not preclude
                  you from also acting as an Investor, either contemporaneously
                  or at a different time. In the event that you choose to act
                  both as an Investor and as an Issuer, the provisions of this
                  Agreement apply to you to the extent that you are acting as an
                  Investor, and the provisions of the Membership Agreement for
                  Issuer will apply to you to the extent that you are acting as
                  an Issuer.
                </p>
                <p>
                  7.7. Fees. We will charge you a fee for acting as an Issuer if
                  you successfully raise capital through the platform, as set
                  forth in the Issuer Agreement or otherwise agreed with us.
                  Additional fees related to breakage may also apply, as set
                  forth in the Issuer Agreement and other agreements between us.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  8. Use of Account 8.1. You may maintain funds in your Account,
                  and those funds shall be considered as your balance, minus any
                  money owed to us. Funds will be deposited in your Account by
                  transferring funds from certain payment instruments we accept
                  (including debit cards, checking and savings accounts or other
                  types of prepaid payment we authorize, collateral, etc.)
                </p>
                <p>
                  We make no representation that any of your Payment
                  Instrument(s) is in good standing or that the issuer of your
                  Payment Instruments will sanction or allow such transfer for
                  any transfer from a Payment Instrument to your Account.
                </p>
                <p>
                  8.2. You may use your Account on the Round One website to
                  purchase securities. We may restrict the amount or number of
                  transactions you may make with your Account for regulatory
                  purposes. We may ask you to submit additional identity
                  documents at our absolute discretion before you make a
                  transaction.
                </p>
                <p>
                  You allow us to reduce the value available in your Account by
                  the cumulative sum of the transaction and, where applicable,
                  any costs, including applicable fees and taxes, each time you
                  use your Account and/or make transactions.
                </p>
                <p>
                  You can only make payments using your Account to the extent
                  that you have sufficient balance to support those
                  transactions. You are not allowed to make purchases or
                  transactions in excess of your Account balance.
                </p>
                <p>
                  You are liable for all initiated purchases, transactions, and
                  fees incurred using your Account. If another person uses your
                  Account, we will consider this as if you have allowed such use
                  and, according to the provisions below, you will be liable for
                  any purchases made and fees incurred by such use.
                </p>
                <p>
                  You must immediately inform us of any unauthorized use of your
                  Account or any other violation of security with respect to the
                  Account that you are aware of.
                </p>
                <p>
                  ESDC shall furnish account statements and confirmations of all
                  executed transactions on the day these are made by electronic
                  means or sent to the email address appearing in our records.
                  These shall be binding if no written objection is made by the
                  next business day. You acknowledge that the validity of your
                  objection to any such confirmation of transaction or account
                  statement shall be reasonably determined solely by ESDC.
                </p>
                <p>
                  8.3. Fees. We reserve the right to charge fees, including
                  those associated with the set-up and management of your
                  Account, to cover the related costs of providing you with
                  Account services.
                </p>
                <p>
                  8.4. Set-Off. ESDC shall be entitled, with notice to you, to
                  set off any of your debts to ESDC against any amount in the
                  Account or any amount payable to you by ESDC whether the debts
                  are incurred pursuant to the account or otherwise, are actual
                  or in the a currency different from the currency in the
                  account or the currency of the amount payable to you by ESDC.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  9. Purchase of Securities 9.1. Certificates.Subject to and
                  without prejudice to the availment of our Nominee Services,
                  The Securities purchased by Investors shall be registered
                  under their name after payment and upon the completion of the
                  campaign of the Issuer where the Investor purchased
                  securities.
                </p>
                <p>
                  The transfer of certificates corresponding to the Securities
                  purchased by you, as an Investor, shall be subject to the
                  regular course of an Issuer's issuance of stock certificates
                  or another certificate of ownership of securities with the
                  assistance of the Intermediary.
                </p>
                <p>
                  9.2. Safekeeping. Securities indicated on the platform’s
                  portfolio and statement of account features are not held by
                  the ESDC as the Intermediary. The Platform displays these
                  securities for the benefit of Issuers and Investors but is not
                  responsible for any discrepancies between displayed and actual
                  shareholdings if such discrepancy is due to a lack of or
                  inaccuracy in the disclosure by Issuers, software errors or
                  failure, delays and failures by service providers, or force
                  majeure. ESDC and the Intermediary are not responsible for any
                  loss, missing, damaged or defective stock certificates issued
                  by Issuers
                </p>
                <p>
                  9.3. Issuers are aware that registration with the Platform
                  requires disclosure. It is the Issuer’s duty to maintain
                  accurately and up-to-date stock book and stock record
                  information. Likewise, it is the Issuer’s duty to promptly
                  inform the Platform of any changes to its capital structure,
                  stock inventory, or otherwise.
                </p>
                <p>
                  9.4. It is agreed that ESDC has no responsibility for the
                  collection of coupons, interest, or dividends on the
                  Securities acquired by an Investor. ESDC also accepts no
                  responsibility for the receipt and/or forwarding of any
                  communications or documents relative to the Securities.
                </p>
                <p>
                  9.5. ESDC shall comply with all laws, writs or juridical or
                  administrative orders, processes or regulations without the
                  Client’s obligation to confirm or question the legality or
                  constitutionality of such order, process or regulation. In the
                  case of orders and processes, ESDC shall be authorized to act
                  on the basis of documents or copies which purport and which
                  ESDC at its discretion reasonably believes to be genuine
                  without any duty to ascertain their authenticity.
                </p>
                <p>
                  9.6. In all cases where ESDC is of the opinion that it will be
                  subjected to any claim or demand for taxes, or be required to
                  comply with any law, process or regulation, it may withhold
                  payment of any sums due to the Client, if any, until ESDC
                  shall have received an order or a satisfactory ruling from the
                  administrative authority or from the courts which would allow
                  the release thereof under circumstances which, in ESDC's
                  opinion, forecloses the possibility of liability attaching to
                  ESDC. For this purpose, ESDC may, for the Client’s account,
                  seek the assistance of any lawyer, accountant or other
                  experts.
                </p>
                <p>
                  9.7. The Client agrees to reimburse ESDC for all expenses,
                  including attorney's fees, and for all charges and taxes
                  incurred or paid in good faith by it, or because of the
                  Client’s failure to comply with his obligations hereunder. The
                  Client further holds ESDC harmless from all claims, demands
                  and liabilities which may be made against ESDC. All sums due
                  to ESDC shall be payable on the date due without need of
                  demand.
                </p>
                <p>
                  9.8. Promoters. You acknowledge that any person who promotes
                  an Issuer’s offering for compensation, whether past or
                  prospective or who is a founder or an employee of an issuer
                  that engages in promotional activities on behalf of the issuer
                  on the Platform, shall clearly disclose in all communications
                  on the intermediary’s Platform, respectively, the receipt of
                  the compensation and that he or she is engaging in promotional
                  activities on behalf of the Issuer.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  10. Disclosure 10.1. ESDC is authorized to disclose such
                  information regarding you or concerning your Account for
                  purposes provided in the Privacy Policy and in instances when
                  ESDC shall be legally obligated to do so pursuant to any
                  Applicable Law including but not limited to rules or customs
                  of any exchange or market and/or clearinghouse and/or the
                  regulations governing the Agreement.
                </p>
                <p>
                  10.2. You hereby authorize and consent to the disclosure by
                  ESDC of any information relating to your Account upon demand,
                  order or request by the Securities and Exchange Commission of
                  the Philippines, Anti-Money Laundering Council, Philippine
                  Stock Exchange or any government authority and/or law
                  enforcement agency.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  11. Termination 11.1. Termination by You. You may terminate
                  this Agreement or any service of the Platform covered by
                  different agreements, by written notification to us not less
                  than three (3) Business Days prior to the intended date of
                  termination. You may also cease using the Platform at any
                  time.
                </p>
                <p>
                  11.2. Termination or Suspension by Us. We may suspend or
                  terminate your Round One membership or access to any service
                  (and/or any and all types of memberships) immediately, and we
                  shall inform you of the suspension and/or termination
                  immediately, if:
                </p>
                <p>
                  A. You have breached the terms of this Agreement or any other
                  Platform Agreement;
                </p>
                <p>
                  B. You have broken or attempted to break the law, or put Round
                  One in a position where we might break the law;
                </p>
                <p>
                  C. You are using the Platform in a way that is harmful to
                  Round One, other members, or the public, such as but not
                  limited to instances where your use causes harm to our
                  Platform or our reputation;
                </p>
                <p>D. You have given us false information;</p>
                <p>
                  E. You have been abusive to anyone at Round One or another
                  Round One member;
                </p>
                <p>
                  F. There is doubt as to the accuracy or completeness of any
                  information or document submitted or provided by you;
                </p>
                <p>
                  G. Your use of the Platform creates concerns related to the
                  Anti-Money laundering act or possible violation of Applicable
                  Law;
                </p>
                <p>
                  H. You have committed acts, or events have occurred, that
                  warrant termination and/or suspension of your membership under
                  the Agreement; and/or
                </p>
                <p>
                  I. If we are required to do so under any law, regulation, or
                  by a governmental or regulatory authority.
                </p>
                <p>
                  We may also terminate your Round One membership for other
                  reasons or for no reason by providing at least two weeks’
                  notice beforehand.
                </p>
                <p>
                  11.3. Suspension. If you send us information that states or
                  leads us to believe that you are no longer eligible to be a
                  Round One member, we may suspend your Round One membership
                  until we determine that you are again eligible to become a
                  Round One member. The Suspension of your membership may also
                  include the freezing or suspension of your right to access
                  your funds that are in our possession and/or control.
                </p>
                <p>
                  11.4. Suspension of Campaign pending Investigation. If Round
                  One deems it necessary, it may issue an order suspending a
                  Campaign pending any investigation, whether commenced by Round
                  One, a government agency and/or regulator, or law enforcement.
                  The order shall state the grounds for taking such action, but
                  such order of suspension, although binding upon the persons
                  notified thereof, shall be deemed confidential, and shall not
                  be published. Upon the issuance of the suspension order, no
                  further offer or sale of such security shall be made until the
                  same is lifted or set aside by. Otherwise, such a sale shall
                  be void. The notice of the issuance of such order shall be
                  given to the issuer and every broker who shall have notified
                  RoundOne of an intention to sell such security.
                </p>
                <p>
                  11.5. Termination Upon Conclusion of Activities. If your
                  membership is terminated, your access to the Platform may be
                  terminated at such time as you no longer have any investments
                  listed in the &quot;Portfolio&quot; section of the Platform
                  nor any Campaign listed in the &quot;Your Campaigns&quot;
                  section.
                </p>
                <p>
                  11.6. Termination for Malfeasance. There are circumstances in
                  which we may terminate your access to the platform even if you
                  still have investments listed in the &quot;Portfolio&quot;
                  section or listed in the &quot;Your Campaigns&quot; section.
                  These circumstances may include your using the platform in any
                  way (1) that causes, or is likely to cause, the Website, any
                  service, or access to it to be interrupted or damaged in any
                  way, (2) for fraudulent purposes, or in connection with a
                  criminal offense, (3) to send, use or reuse any material that
                  is illegal, offensive, abusive, indecent, defamatory, obscene
                  or menacing, is otherwise injurious to third parties or which
                  consists of or contains software viruses, political
                  campaigning, commercial solicitation, chain letters, mass
                  mailings or any spam or (4) to cause annoyance, inconvenience
                  or needless anxiety or (5) which gives rise or may give rise
                  to criminal or other liability on our part. If we terminate
                  your access to the Platform pursuant to this clause and you
                  still have an investment listed in the &quot;Portfolio&quot;
                  section, we will follow the process set forth in the relevant
                  Investment Agreement with respect to how that investment is
                  handled. If we terminate your access to the Platform pursuant
                  to this clause and you still have CF Campaigns in which
                  investment has been completed listed in the &quot;Your
                  Campaigns&quot; section, you will be replaced as the agent of
                  the Issuer.
                </p>
                <p>
                  11.7. Consequences of Termination or Suspension. Upon
                  termination of your access to the platform, this Agreement and
                  other Platform Agreements shall terminate, and neither you nor
                  we shall continue to be bound by its provisions other than
                  those provisions that survive termination. If your Round One
                  membership is terminated or is suspended, you will no longer
                  be able to use the Platform or access its services. If you
                  have created a Campaign that is still active at the time of
                  such termination or suspension, it will be terminated in
                  accordance with the Campaign Ts &amp;amp; Cs. Other effects
                  may be provided in other Platform Agreements.
                </p>
                <p>
                  11.8. If, at the time of the termination of this Agreement,
                  you still have a balance in your Account, this balance shall
                  be returned to your nominated bank account, subject to the
                  deduction of any fees, expenses, costs, and other charges you
                  owe to us, if any.
                </p>
                <p>
                  11.9. Any fees, expenses, costs and other charges we have
                  accrued incurred to the effective date of termination shall be
                  paid by you.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  12. Communications and Information 12.1. Information on the
                  Platform. In addition to the information set forth in each
                  Campaign, we may provide on the platform information about
                  investing in or operating businesses generally or other
                  matters that we believe may be relevant or of interest to you.
                  We refer to this as &quot;additional information&quot;. NONE
                  OF THE ADDITIONAL INFORMATION, INCLUDING INFORMATION ABOUT
                  HISTORICAL RETURNS AND CONTENT IN NEWSFEEDS OR IN ANY PORTION
                  OF THE PLATFORM, CAN BE RELIED ON AS A GUARANTEE OR INDICATION
                  OF ANY PARTICULAR RESULT, AND THE ADDITIONAL INFORMATION DOES
                  NOT CONSTITUTE ANY FORM OF ADVICE, RECOMMENDATION OR
                  ENDORSEMENT BY US. WE CANNOT ASSURE YOU THAT ANY ADDITIONAL
                  INFORMATION IS COMPLETE, ACCURATE, UP-TO-DATE OR ERROR FREE,
                  AND WE WILL NOT BE LIABLE TO YOU FOR ANY LOSS, DAMAGES OR
                  COSTS IF IT IS NOT. TO THE EXTENT THAT ANY ADDITIONAL
                  INFORMATION CONSTITUTES LINKS TO OTHER WEBSITES OR THIRD-PARTY
                  CONTACT DETAILS, WE TAKE NO RESPONSIBILITY FOR THE
                  AVAILABILITY OR ACCURACY OF SUCH WEBSITES OR CONTACT DETAILS
                  OR THE ACTS OF SUCH THIRD PARTIES.
                </p>
                <p>
                  12.2. Communications About Platform Activity. From time to
                  time we may send you information about activity on the
                  platform, including new Campaigns and the progress of existing
                  ones. ANY MESSAGE WE SEND YOU ABOUT ACTIVITY ON THE PLATFORM
                  DO NOT CONSTITUTE ADVICE OR A RECOMMENDATION TO INVEST. From
                  time to time we may also send you information with respect to
                  your membership, Campaigns or investments, our business, this
                  Agreement or other matters that we reasonably believe are
                  relevant to you. We will do our best to ensure that these
                  emails are relatively infrequent, but we may send you such
                  emails at our discretion.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  13. Complaints 13.1. Complaints. If you have a complaint with
                  respect to any aspect of the platform, you should report it to
                  us immediately by sending an email, with the word "complaint"
                  in the subject line, from the email address in which your
                  Round One membership is registered to complaints@Round
                  One.com.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  14. Records 14.1. Period of Retention. In accordance with
                  legal and regulatory requirements, we will retain the records
                  relevant to your Round One membership and any activity you
                  conducted on the Platform for a period required by the laws in
                  the Philippines following the termination of your access to
                  the platform. This period may be extended by force of law,
                  regulatory requirement or by the mutual consent of you and us.
                </p>
                <p>
                  14.2. No Request for Deletion. You will only be able to
                  request the destruction or deletion of any of the records
                  relevant to your Round One membership unless we are required
                  to destroy or delete them by force of law or other regulatory
                  requirements.
                </p>
                <p>
                  14.3. Data and Data Protection. For more information about the
                  records and personal information we keep and process about you
                  and how we use your personal data please see our Privacy
                  Statement, available at
                  https://www.RoundOne.com/privacy_notice.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  15. Conflict of Interest 15.1. We do our best to ensure that
                  our interests do not conflict with yours, and we have
                  deliberately designed our fee structure so that we primarily
                  make money when you are profiting from an investment.
                  Nevertheless, as a platform provider we are not your
                  representative or agent, unless otherwise agreed, and you
                  understand that by using the Platform, at times our interests
                  may conflict with yours.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  16. Our Ceasing to Trade 16.1. In the event that we cease to
                  operate, any money in your account will be protected. We will
                  notify you as soon as possible after we have taken a decision
                  to cease to operate, and at that stage all active Campaigns
                  will be terminated. Any amounts due to you will be returned to
                  your nominated account without any interest and after
                  deduction of any transaction fee(s) and/ or exchange rate
                  loss.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  17. Availability of the Website, Security and Accuracy 17.1.
                  Whilst we endeavour to make the Website or of the Services
                  available twenty-four (24) hours a day, we shall not be liable
                  if for any reason any Service is unavailable for any time or
                  for any period. WE MAKE NO WARRANTY THAT YOUR ACCESS TO THE
                  SERVICES WILL BE UNINTERRUPTED, TIMELY, OR ERROR-FREE. DUE TO
                  THE NATURE OF THE INTERNET, THIS CANNOT BE GUARANTEED. IN
                  ADDITION, WE MAY OCCASIONALLY NEED TO CARRY OUT REPAIRS,
                  MAINTENANCE, OR INTRODUCE NEW FACILITIES AND FUNCTIONS.
                </p>
                <p>
                  17.2. Access to the Services may be suspended or withdrawn to
                  or from you personally or all users temporarily or permanently
                  at any time with or without notice. We may also impose
                  restrictions on the length and manner of usage of any part of
                  the Website for any reason. If we impose restrictions on you
                  personally, you must not attempt to use the Website under any
                  other name or user.
                </p>
                <p>
                  17.3. We do not warrant that the Website will be compatible
                  with all hardware and software which you may use. We shall not
                  be liable for losses or damage suffered by you that are
                  attributable to (i) viruses or other code that may affect, any
                  computer equipment, software, data or other property as a
                  result of your access to or use of the Website or your
                  obtaining any material from, or as a result of using the
                  Website; and (ii) actions of third parties including other
                  users of the Website unless such damage is materially
                  attributable to our gross negligence or willful misconduct.
                </p>
                <p>
                  17.4. WHILST WE ENDEAVOUR TO ENSURE THAT INFORMATION AND
                  MATERIALS ON THE WEBSITE ARE CORRECT, UNLESS OTHERWISE
                  PROVIDED, NO WARRANTY OR REPRESENTATION, EXPRESS OR IMPLIED,
                  IS GIVEN THAT THEY ARE COMPLETE, ACCURATE, UP-TO-DATE, FIT FOR
                  A PARTICULAR PURPOSE. TO THE EXTENT PERMITTED BY LAW, WE DO
                  NOT ACCEPT ANY LIABILITY FOR ANY ERRORS OR OMISSIONS IN SUCH
                  INFORMATION. THIS SHALL NOT AFFECT ANY OBLIGATION WHICH WE MAY
                  HAVE UNDER ANY CONTRACT THAT WE MAY HAVE WITH YOU, INCLUDING
                  ANY OBLIGATION THAT WE MAY HAVE UNDER THAT CONTRACT TO PROVIDE
                  YOU WITH ACCURATE INFORMATION, AND WHICH WE MAY DO THROUGH A
                  SECURE PART OF THE WEBSITE AVAILABLE TO PEOPLE WHO USE THE
                  APPROPRIATE PASSWORD AND HAS THE AUTHORITY TO ACCESS THOSE
                  PIECES OF INFORMATION.
                </p>
                <p>
                  17.5. The material contained on the Website is provided for
                  informational purposes only and it shall not give rise to any
                  commitment or obligation by us. Any information on the Website
                  shall not constitute any part of an offer or contract from
                  and/or with us, unless otherwise explicitly stated.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  18. Liability 18.1. Your Liability to Us. You shall be liable
                  to us for any loss or damage suffered by us as a result of or
                  in connection any breach of this Agreement or any other
                  agreement that you enter into with us; any use of the Platform
                  that is fraudulent or represents willful misconduct; or any
                  act or omission by you.
                </p>
                <p>
                  18.2. Our Liability to You. We shall be liable to you for any
                  loss or damage which you may suffer as a result of being a
                  Member under this Platform or using the Platform to the extent
                  that such loss or damage is materially attributable to our
                  breach of this Agreement or was the direct result of fraud by
                  us.
                </p>
                <p>
                  Round One shall not be liable to you for any partial
                  performance or non-performance of our obligations by reason of
                  any cause beyond our control, including but not limited to any
                  breakdown or failure of transmission, communication or
                  computer facilities and network providers, industrial action,
                  and the failure of any relevant correspondent or other agent
                  or principal of Round One, depository, dealer, exchange,
                  clearing house or regulatory or self-regulatory organization
                  for any reason to perform its obligation.
                </p>
                <p>
                  Under no circumstance shall Round One or any of its directors,
                  stockholders, officers, affiliates, employees,
                  representatives, contractors, or agents be held liable for any
                  direct or indirect losses or damages:
                </p>
                <p>
                  a) for acts, omissions, or the failure to execute or perform
                  any transaction which it is authorized to do or pursuant to
                  any requests, instructions or orders by you;
                </p>
                <p>
                  b) we shall not be liable to you for any loss or damage in
                  respect of any matter for which liability is expressly
                  excluded under this or any other Platform Agreement;
                </p>
                <p>
                  c) arising out of or in connection with any error or
                  inaccuracy in the data entered by you or another Round One
                  member or any misrepresentation or willful misconduct or any
                  other act of another Round One member;
                </p>
                <p>
                  d) for any indirect, consequential, special or punitive loss,
                  damage, cost or expense, unforeseeable losses or damages, loss
                  of profit, loss of business, lost or wasted management time or
                  time of other employees, loss of reputation, depletion of
                  goodwill, or loss, damage or corruption of data. You agree
                  that any legal action against us is permitted only on an
                  individual basis, and that you will not initiate or join any
                  purported or actual className or consolidated actions against us.
                </p>
                <p>
                  Unless otherwise provided, our liability to you for any loss
                  or damage arising in connection with your investment in a
                  particular business shall be limited to no more than the
                  aggregate amount of the fees we earned from you in the twelve
                  months immediately preceding your claim.
                </p>
                <p>
                  18.3. Disclaimer. ROUND ONE DISCLAIMS ALL WARRANTIES
                  PERTAINING TO SERVICES OFFERED BY THIRD PARTIES AND
                  THIRD-PARTY WEBSITES WHICH MAY BE ACCESSIBLE THROUGH THE
                  PLATFORM. SHOULD YOU CHOOSE TO TRANSACT WITH THIRD PARTIES,
                  YOUR TRANSACTIONS WITH THEM SHALL BE GOVERNED BY THE THIRD
                  PARTY’S TERMS AND CONDITIONS. YOU UNDERSTAND THAT ROUND ONE IS
                  NOT A PARTY TO SAID TRANSACTIONS.
                </p>
                <p>
                  18.4. With the exception of the specific and explicit promises
                  we make in the relevant portions of this Agreement or
                  agreements to which you are a party, you need to be aware
                  that:
                </p>
                <p>
                  18.4.1. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAWS, WE
                  MAKE NO WARRANTY OR REPRESENTATION (EXPRESS OR IMPLIED) THAT:
                  ANY PORTION OF THE SERVICES WILL MEET YOUR REQUIREMENTS, THAT
                  IT WILL BE OF SATISFACTORY QUALITY, THAT IT WILL BE FIT FOR A
                  PARTICULAR PURPOSE, THAT IT WILL NOT INFRINGE THE RIGHTS OF
                  THIRD PARTIES, THAT IT WILL BE COMPATIBLE WITH ALL SYSTEMS,
                  THAT IT WILL BE SECURE, OR THAT ALL INFORMATION PROVIDED OR
                  MADE AVAILABLE THROUGH THE SERVICES WILL BE ACCURATE.
                </p>
                <p>
                  18.4.2. WE MAKE NO GUARANTEE OF ANY SPECIFIC RESULTS FROM THE
                  USE OF THE SERVICES.
                </p>
                <p>
                  18.4.3. NO PART OF THE SERVICES IS INTENDED TO CONSTITUTE
                  ADVICE, AND THE CONTENT OF THIS WEBSITE SHOULD NOT BE RELIED
                  UPON WHEN MAKING ANY DECISIONS OR TAKING ANY ACTION OF ANY
                  KIND.
                </p>
                <p>
                  18.4.4. THE PLATFORM AND THE WEBSITE IS PROVIDED “AS IS” AND
                  ON AN “AS AVAILABLE” BASIS, AND WE GIVE NO WARRANTY THAT IT
                  WILL BE FREE OF ERRORS, DEFECTS AND / OR FAULTS. YOU
                  UNDERSTAND THAT NO SOFTWARE IS ERROR-FREE OR BUG-FREE.
                </p>
                <p>
                  18.4.5. WE ACCEPT NO LIABILITY FOR ANY DISRUPTION OR
                  NON-AVAILABILITY OF THE SERVICES RESULTING FROM EXTERNAL
                  CAUSES INCLUDING, BUT NOT LIMITED TO, INTERNET SERVICE
                  PROVIDER (“ISP”) EQUIPMENT FAILURE, HOST EQUIPMENT FAILURE,
                  COMMUNICATIONS NETWORK FAILURE, POWER FAILURE, HACKING,
                  NATURAL EVENTS, ACTS OF WAR, OR LEGAL RESTRICTIONS AND
                  CENSORSHIP.
                </p>
                <p>
                  18.5. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE ACCEPT NO
                  LIABILITY FOR ANY DIRECT OR INDIRECT LOSS OR DAMAGE,
                  FORESEEABLE OR OTHERWISE, INCLUDING ANY INDIRECT,
                  CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES ARISING FROM YOUR
                  USE OF THE WEBSITE OR ANY INFORMATION CONTAINED IN IT, AND YOU
                  USE THE WEBSITE AND ITS CONTENT AT YOUR OWN RISK. THIS IS NOT
                  INTENDED TO EXCLUDE OR RESTRICT OUR LIABILITY FOR WILLFUL
                  MISCONDUCT MATERIALLY ATTRIBUTABLE TO US.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  19. Relationship to Other Agreements 19.1. In addition to
                  these Terms, certain Services and functionalities may require
                  you to agree to additional terms. Our Referral Fee Terms may
                  also apply to you and everyone else who uses the Website if
                  you act as a referrer. A Privacy Statement also sets out
                  details of how your personal data will be used.
                </p>
                <p>
                  19.2. During the course of your use of the Services, you may
                  choose to agree to be bound by certain other agreements: for
                  example, if you wish to invest in a Campaign offered on the
                  Round One platform, you will need to agree to the relevant
                  Investment Agreement. We refer to all of these other
                  agreements, and the services in the immediately preceding
                  paragraph as the “Platform Agreements”, because rather than
                  just governing your technical use of the Services, they set
                  out the substantive terms that govern your relationship with
                  us when you make use of our platform.
                </p>
                <p>
                  19.3. If it turns out that there is a conflict between the
                  Terms and a Platform Agreement that you agree to, the Platform
                  Agreement will take precedence.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  20. Intellectual Property 20.1. You acknowledge and agree that
                  all rights to the Website and all the contents displayed on
                  the Website, including but not limited to, our logo, domain
                  names, documents, images, clips, graphics, data, design,
                  source code, software, and other information are our (or our
                  licensors’) sole property or of the entities affiliated with
                  us or parties with whom we have contracted.
                </p>
                <p>
                  20.2. You may print off one copy and may download extracts, of
                  any page(s) from the Website for your personal reference and
                  you may draw the attention of others within your organization
                  to material posted on the Website.
                </p>
                <p>
                  20.3. You must not modify the paper or digital copies of any
                  materials you have printed off or downloaded in any way, and
                  you must not use any illustrations, photographs, video or
                  audio sequences or any graphics separately from any
                  accompanying text. You shall not remove or modify any marks or
                  brands on the Website.
                </p>
                <p>
                  20.4. You must not use any part of the materials on the
                  Website for commercial purposes and/or public circulation
                  without obtaining a license to do so from us or our licensors.
                </p>
                <p>
                  20.5. If you print off, copy or download any part of the
                  Website in breach of these Terms, your right to use the
                  Website will cease immediately and you must, at our option,
                  return or destroy any copies of the materials you have made.
                  You are prohibited from creating a link to the Website in any
                  other webpage without our prior written consent. Any consent
                  we may give in relation to or in connection with the Website
                  Content or Website may be withdrawn at any time without prior
                  notice.
                </p>
                <p>
                  20.6. Should you provide any information or content through
                  the Services, including the Website, (the “User-Submitted
                  Content”), you grant to Round One a royalty-free, worldwide,
                  perpetual and sublicensable license to display, reproduce,
                  distribute, modify, prepare derivative works of, perform, and
                  otherwise use and exploit all such User-Submitted Content in
                  connection with the delivery of the Services, and the
                  promotion and marketing of the same.
                </p>
                <p>
                  20.7. You warrant and represent that the User-Submitted
                  Content are your original works or such works over which you
                  have all the licenses, authorizations, permits, and clearances
                  which are necessary in order to perfect the rights granted to
                  Round One under these Terms. User-Submitted Content shall
                  include, but not be limited to, text, graphics, logos, media
                  files, and such other forms of content.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  21. Links to Other Websites 21.1. The Website may frame or
                  contain references or links to other websites. Unless we
                  expressly say otherwise, these references and/or links are
                  provided for your reference and convenience only and do not
                  imply any review or endorsement of the material on these
                  websites or any association with their operators. Round One
                  cannot and does not guarantee or verify the contents of said
                  websites, which may contain information that is inaccurate,
                  incomplete, or outdated. Your access and use of such websites
                  (and the websites to which they are linked to) are solely at
                  your own risk. We disclaim liability for any and all forms of
                  loss or damage arising out of the use of them.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  22. Restricted Activities 22.1. You agree to use the Website
                  and all Services responsibly and legally and not to use the
                  Services for any purpose that is prohibited by the Terms. You
                  are responsible for all of your activity in connection with
                  the Website and Services. If we have a reasonable belief that
                  you have violated any provision of these Terms, including any
                  Platform Agreements that you have agreed to, we shall have the
                  right to suspend, modify, or cancel your use of the Services
                  without prior notice.
                </p>
                <p>
                  22.2. You are prohibited from taking any action in relation to
                  your use of the Website and Services which:
                </p>
                <p>
                  22.2.1. infringes any patent, trademark, copyright, right of
                  publicity or other such rights of any other person or entity
                  or violates any law or contractual duty;
                </p>
                <p>22.2.2. is false, misleading or inaccurate;</p>
                <p>22.2.3. is obscene, offensive, or unlawful in any way;</p>
                <p>
                  22.2.4. constitutes spam, junk mail, or advertising which has
                  not been authorized in writing by us;
                </p>
                <p>
                  22.2.5. results in software viruses, trojans, worms, logic
                  bombs, or other material which is malicious or technologically
                  harmful interfering with, damaging, or destroying the proper
                  function of the Services; and/or impersonates any person or
                  entity, including any employee or representative of Round One.
                </p>
                <p>22.3. Additionally, you agree not to:</p>
                <p>
                  22.3.1. Take any action that imposes or may impose (as
                  determined by Round One in its sole discretion) an
                  unreasonable or disproportionately large load on Round One (or
                  its third-party providers’) infrastructure;
                </p>
                <p>
                  22.3.2. Engage in any activity that interferes with or
                  disrupts the Services or the servers and networks which are
                  connected to the Services;
                </p>
                <p>
                  22.3.3. Access, or attempt to access, any of the Services by
                  any means other than through the interface that is provided by
                  Round One, unless you have been specifically allowed to do so
                  in a separate agreement with Round One. You specifically agree
                  not to access or attempt to access, any of the Services
                  through any unauthorized automated means. You agree not to run
                  any form of spam or auto-responder on the Services;
                </p>
                <p>
                  22.3.4. Engage in any action that may be in violation of
                  applicable law and regulations including but not limited to,
                  the Securities Regulation Code, Anti-Money Laundering Act,
                  Cybercrime Prevention Act, and Data Privacy Act.
                </p>
                <p>
                  22.4. If you breach this provision, you may be committing a
                  criminal offense. Round One will report any such breach to the
                  relevant law enforcement authorities or government agencies
                  and Round One will cooperate with those authorities by
                  disclosing your identity to them.
                </p>
                <p>
                  22.5. Round One does not guarantee that any content will be
                  made available on or through the Services. Round One has no
                  obligation to monitor the Services or content.
                </p>
                <p>
                  22.6. You are responsible for configuring your information
                  technology, security systems, and tools, computer programs and
                  platform in order to access the Website. You should use your
                  own virus protection software.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  23. Disclaimers 23.1. If you choose to agree to the Terms, you
                  will see that in these Terms – and in other Platform
                  Agreements – we make certain promises to you about what parts
                  of the Website and its content (“Content”) we are responsible
                  for and what parts we are not. This means that, if you are a
                  party to one of those agreements and something goes wrong with
                  your use of the Website, you may be able to hold us
                  responsible depending on the circumstances.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  24. Confidentiality 24.1. Confidential Information. Each Party
                  shall (a) treat as confidential all Confidential Information
                  of the other Party, (b) not use such Confidential Information
                  except as set forth in this Agreement, and (c) use best
                  efforts not to disclose such Confidential Information to any
                  third party. Without limiting the foregoing, each of the
                  Parties shall use at least the same degree of care that it
                  uses to prevent the disclosure of its own Confidential
                  Information of like importance to prevent the disclosure of
                  Confidential Information disclosed to it by the other Party
                  under this Agreement. Each Party shall promptly notify the
                  other Party of any actual or suspected misuse or unauthorized
                  disclosure of the other Party’s Confidential Information.
                </p>
                <p>
                  24.2. However, neither Party shall have liability to the other
                  with regard to any Confidential Information of the other that
                  the receiving Party can prove: (a) was generally publicly
                  available; (b) was in the public domain at the time it was
                  disclosed or has since entered the public domain through no
                  fault of the receiving Party; (c) was known to the receiving
                  Party, without restriction, at the time of disclosure, as
                  demonstrated by files in existence at the time of disclosure;
                  (d) is disclosed with the prior written approval of the
                  disclosing Party; (e) was independently developed by the
                  receiving Party without any use of the Confidential
                  Information, as demonstrated by files created at the time of
                  such independent development; or (f) becomes known to the
                  receiving Party, without restriction, from a source other than
                  the disclosing Party without breach of Agreement by the
                  receiving Party and otherwise not in violation of the
                  disclosing Party’s right
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  25. Indemnification You shall indemnify and hold harmless
                  Round One (including its directors, officers, affiliates,
                  employees, and personnel) from, any loss or expense Round One
                  may sustain or incur as a consequence of (i) a breach by you
                  of the Agreement; or (ii) a breach of applicable law and
                  regulations.
                </p>
                <p>
                  You agree to pay us all costs including legal, on an indemnity
                  basis, as may be incurred by us for the purpose of recovering
                  any monies from you.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  26. Severability We have made every effort to ensure that the
                  Agreement adheres strictly to the relevant applicable laws.
                  However, in the event that any of these provisions are found
                  to be unlawful, invalid, or otherwise unenforceable, that
                  provision is to be deemed severed from the relevant portion of
                  the Agreement and shall not affect the validity and
                  enforceability of the remaining provisions of the Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  27. Assignment, Transfer, and Delegation 27.1. Assignment,
                  Transfer, and Delegation By Us. Round One may assign, transfer
                  or delegate any of our obligations or rights under this
                  Agreement to any person, provided that we are satisfied that
                  such person is competent to perform or exercise the
                  obligations or rights so delegated. We may provide information
                  about you and your activities on the platform to any person to
                  whom we assign, transfer or delegate our obligations or
                  rights.
                </p>
                <p>
                  27.2. Assignment, Transfer, and Delegation By You. Your Round
                  One membership is personal to you, and therefore none of your
                  rights or obligations in connection with your Round One
                  membership or your activities on the platform can be assigned,
                  transferred or delegated to any other person. This prohibition
                  on assignment and delegation does not affect your right to
                  make certain transfers as described in this Agreement and
                  other agreements you enter with us. Any attempt to, transfer
                  assign, or delegate any of your rights or obligations shall be
                  null and void.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  28. No Partnership or Agency This Agreement shall not
                  constitute or be deemed a creation of a partnership, joint
                  revenue, or principal and agent relationship between you and
                  us. Except as expressly provided herein to the contrary, no
                  term or provision hereof shall be construed in any way to
                  grant, convey or create any rights or interests to or in any
                  Person not a Party to this Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  29. Entire Agreement Except otherwise provided in this
                  Agreement, this Agreement supersedes any previous agreements,
                  arrangements, or representations between the Parties, whether
                  oral or written, in respect of the subject matter thereof and
                  shall constitute the entire agreement between the Parties in
                  respect of the subject matter hereof. This shall be without
                  prejudice to other agreements which may be entered by you
                  should you use other services of the platform, which shall be
                  covered by other Platform Agreements. No prior drafts of this
                  Agreement and no words or phrases from any such prior drafts
                  shall be admissible into evidence in any dispute or proceeding
                  involving this Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  30. Further Assurances You and we shall from time to time
                  (both during the continuance of this Agreement and after its
                  termination) do all such acts and execute all such documents
                  as may be reasonably necessary in order to give effect to the
                  provisions of this Agreement.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  31. Survival All sections of the Agreement (and any of the
                  Platform Agreements) which by their nature should survive
                  termination will survive termination, including, without
                  limitation, accrued rights to payment, confidentiality,
                  warranty and disclaimers, and limitations of liability.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  32. Force Majeure Except for your obligations to pay us,
                  neither Party shall be liable to the other Party for any
                  failure or delay in performance caused by reasons beyond its
                  reasonable control or if such delay or failure is caused, in
                  whole or in part, by events, occurrences or forces beyond the
                  reasonable control and without negligence or other faults of
                  such party, including any Internet or electronic communication
                  failures.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  33. No Waiver The failure of any Party to enforce, and delay
                  in exercising, any provision of this Terms, shall not be
                  construed as a waiver of its rights to enforce such provision,
                  or as a waiver of any continuing, succeeding breach of any
                  such provision or other provision of this Agreement, nor in
                  any way affect the validity of this Agreement or any part
                  hereof. No single or partial exercise of any such right shall
                  preclude any other or further exercise or such right or the
                  exercise in any other right. The rights available to Round One
                  under these Terms shall be cumulative
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  34. Revisions We are constantly changing and improving our
                  Website and/or Services. As a result, it may be necessary that
                  we may update and add or remove certain functionalities or
                  features in the Website and Services and reflect changes in
                  the law or changes to our services. If we update these Terms,
                  we will post a new version on the Website, and (to the extent
                  permissible under law) as soon as you use the Website after
                  they are posted, you will be deemed to have agreed to the
                  updated version, but you will still be bound by the terms of
                  any previous versions to which you have agreed or been deemed
                  to agree. If there is a conflict between two versions of the
                  Terms of Service to which you have agreed or been deemed to
                  agree, the more recent version shall take precedence unless it
                  is expressly stated otherwise.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  35. Communications 35.1. If you wish to communicate with us
                  about anything related to these Terms, you may do so by
                  sending an email to support@Round One.com. Please note we only
                  respond to questions about these Terms and any technical
                  issues with the use of the Website, and that if you have any
                  other questions about the Website you should email the
                  appropriate address set out in the “Contact Us” section of the
                  Website.
                </p>
                <p>
                  35.2. Any notice and confirmation from us to you in respect of
                  this Agreement, your Round One membership or your activities
                  on the Platform shall be given through electronic means,
                  either by email, social media messages, instant messages or
                  other electronic media messages.
                </p>
                <p>
                  35.3. You agree to receive educational materials on
                  crowdfunding, notices, confirmations, and all other
                  notifications or communications from RoundOne through
                  electronic means.
                </p>
                <p>
                  35.4. Notices given pursuant to this clause through the
                  platform or by email shall be deemed to have been duly
                  received on the same day that said notice has been sent.
                </p>
                <p>
                  35.5. All notices given under this Agreement shall be in the
                  English language.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  36. Choice of Law These Terms of Service, and the relationship
                  between you and Round One created by it, shall be governed by
                  and construed in accordance with the laws of the Philippines
                  without regard to its conflict of laws provisions.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  37. Jurisdiction and Dispute Claims In case of a claim or
                  dispute initiated by us,you agree to submit to the exclusive
                  jurisdiction of the courts of Manila City.
                </p>
                <p>
                  In the event of a claim or dispute, you agree to observe good
                  faith and exert best efforts towards an amicable resolution of
                  any claim or dispute.
                </p>
                <p>
                  Any claim/dispute initiated by you that is not resolved
                  amicably 3 (three) months after the initiation of the claim or
                  dispute shall be finally resolved by arbitration in accordance
                  with the rules of the Philippine Dispute Resolution Center
                  (PDRCI). The place of arbitration shall be the City of Taguig,
                  Metro Manila, Philippines, or another city in the Philippines
                  if the parties so agree. Unless the parties agree to
                  arbitration by a sole arbitrator, the dispute shall be
                  resolved by three (3) arbitrators.
                </p>
              </div>
              <hr className="border-2 border-top border-black" />
              <div>
                <p>
                  38. About Us. Corporate Form and Office. Round One is a
                  Crowdfunding Platform managed by Eastern Securities
                  Development Corporation or ESDC, a licensed broker-dealer with
                  a business address at Rm. 1701, Tytana Building, Oriente St.
                  Binondo, Manila. Round One is registered, authorized, and
                  regulated by the Securities and Exchange Commission.
                </p>
              </div>
              <hr className="border-2 border-top border-black pb-5" />
            </div>
          </div>
        </Container>
       
    </>
  );
};

export default TermsOfService;

import React from 'react';
import { useState } from 'react';
import { Button, Collapse, Container, Row, Col, Form } from 'react-bootstrap';

const FiltersComapanies = () => {

    const [open, setOpen] = useState(false);

    return (
        <>
            <Container>
                <div className='text-left mt-3'>
                    <Button
                        variant="link"
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        className='text-decoration-none font-weight-bold p-0'
                    >
                        Filters
                        <i className="fas fa-chevron-down px-1"></i>
                    </Button>
                    <Collapse in={open}>
                        <div id="example-collapse-text">
                            <Row md={8} className='mt-3'>
                                <Col>
                                    <div>
                                        <small className='font-weight-bold'>
                                            Browse by Industry
                                        </small>
                                        <div className="d-flex flex-wrap mt-2">
                                            <span className="smalltabs">Travel & Leisure</span>
                                            <span className="smalltabs">Equity</span>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <small className='font-weight-bold'>
                                            Browse byMarket Location
                                        </small>
                                        <div className="d-flex flex-wrap mt-2">
                                            <span className='smalltabs'>Advertising & Marketing</span>
                                            <span className='smalltabs'>Automative & Transport</span>
                                            <span className='smalltabs'>Clothing & Accessories</span>
                                            <span className='smalltabs'>Energy</span>
                                            <div className="form-group has-search m-1">
                                                {/* <i class="fas fa-plus-circle"></i> */}
                                                <span className="fas fa-plus-circle form-control-feedback plus-icon"></span>
                                                <input type="text" className="form-control semi-bradius bg-light frm-indstry" placeholder="Add Industry" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <small className='font-weight-bold'>
                                            Market Location
                                        </small>
                                        <div className='d-flex flex-wrap'>
                                            <div className='m-1'>
                                                <Form.Select size="sm" className='semi-bradius bgselect-drp'>
                                                    <option>Industry</option>
                                                </Form.Select>
                                            </div>
                                            <div className='m-1'>
                                                <Form.Select size="sm" className='semi-bradius bgselect-drp'>
                                                    <option>Region</option>
                                                </Form.Select>
                                            </div>
                                            <div className='m-1'>
                                                <Form.Select size="sm" className='semi-bradius' disabled>
                                                    <option>Province</option>
                                                </Form.Select>
                                            </div>
                                            <div className='m-1'>
                                                <Form.Select size="sm" className='semi-bradius' disabled>
                                                    <option>City</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <small className='font-weight-bold'>
                                            Browse by instrument type
                                        </small>
                                        <div className="d-flex flex-wrap mt-2">
                                            <span className="smalltabs">Travel & Leisure</span>
                                            <span className="smalltabs">Equity</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </div>
            </Container>
        </>
    )
}

export default FiltersComapanies
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
// import backgroundwhite from '../../assets/images/homebannerbg.png';
import youtube from '../../assets/images/youtube.jpg';
// import dot from '../../assets/images/dottedblue.png';
import zzbg from '../../assets/images/zizag.png';
import { Link } from 'react-router-dom';

const WhyRoundOne = () => {
  return (
    <div className="relbox">
      <div className="bgthirdrow bfr-whtebgclr whte-colorblur">
        {/* style={{ backgroundImage: `url(${backgroundwhite})` }} */}
        {/* <Image src={dot} alt="" className="dotblue" /> */}
        <Container className="py-5 relbox">
          <h2 className="hd-titles text-center">Why Round One?</h2>
          <Row className="mt-5">
            <Col lg={6} md={12} sm={12}>
              <div className="mb-5">
                {/* <img src={youtube} alt="" className="nfull-w" /> */}
                <iframe
                  className="yth"
                  src="https://www.youtube.com/embed/uqvUQh1kH-k?si=M5qq_EtA5YKyzRW_"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </Col>
            <Col lg={3} md={12} sm={12}>
              <div className="left-alignbx px-3 mb-5 fulheight">
                <h4 className="hd-colr tbold">
                  Your end-to-end Investments Platform
                </h4>
                <p className="">
                  A portal for alternative assets in the Philippines. Search and
                  screen prospects, interact with founders and teams and
                  managing your due diligence process
                </p>
                <Link to="/Invest">
                  <Button type="button" className="blu-btn">
                    Invest
                  </Button>
                </Link>
              </div>
            </Col>
            <Col lg={3} md={12} sm={12}>
              <div className="left-alignbx px-3 mb-5 fulheight">
                <h4 className="hd-colr tbold">
                  An Investor Relations Tool for Fundraisers
                </h4>
                <p className="">
                  Launch and manage your fundraising campaign from your very own
                  online dashboard. Reach out to an unlimited investor audience
                </p>
                <Link to="/Raise">
                  <Button type="button" className="blu-btn">
                    Fundraise
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="relbox">
        <div
          className="btmblue"
          style={{ backgroundImage: `url(${zzbg})` }}
        ></div>
      </div>
    </div>
  );
};

export default WhyRoundOne;

import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import investimg from '../../assets/images/investbanner.jpg';

const AllEventsBanner = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const [year, setYear] = useState(searchParams.get('year') || '');
  const handleSearchPage = () => {
    setSearchParams({ page: 1, search: search });
  };

  useEffect(() => {
    setYear(searchParams.get('year') || '');
  }, [searchParams]);

  const handleYear = (value) => {
    setYear(value);
    setSearchParams({ page: 1, year: value });
  };

  return (
    <>
      <div className="mrgntp-hdr"></div>
      <div className="relbox">
        <div
          className="mrgntp-hdr evntsbnr"
          style={{ backgroundImage: `url(${investimg})` }}
        >
          <Container
            className="pb-5"
            style={{ top: '25%', position: 'relative' }}
          >
            <div className="widhdr text-center relbox">
              <h2 className="text-white">Calendar of Events</h2>
              <p>
                Here are the exciting events we have lined up. Check them out to
                expand your knowledge and network!
              </p>
            </div>
            <div className="srchboxlrn relbox">
              <div className="input-group">
                <input
                  type="text"
                  value={search}
                  onChange={(event) => setSearch(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      handleSearchPage();
                    }
                  }}
                  className="form-control semi-bradius"
                  placeholder="Serch your event here.."
                />
                <div className="input-group-append mx-2">
                  <Button
                    type="button"
                    onClick={handleSearchPage}
                    className="srchbtn semi-bradius"
                  >
                    Search
                  </Button>
                </div>
              </div>
              <div className="d-flex slctbox flex-row mt-1">
                <span className="text-info p-2">Filter</span>
                <Form.Select
                  size="sm"
                  onChange={(e) => handleYear(e.target.value)}
                  value={year}
                >
                  <option value="">Year</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                </Form.Select>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AllEventsBanner;

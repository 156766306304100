import React, { useEffect } from 'react';
import { getCampaign, getEducation, getFaqs } from 'store/reducers/home';
import { useDispatch } from 'react-redux';
import MainLayout from '../../layout/MainLayout';
import BannerRaise from './BannerRaise';
import HowDoesItWork from './HDW';
import RaisingFunds from './RaisingFunds';
import FundraiserWalkthrough from './FundraiserWalkthrough';
import Blues from '../../components/Blues';
import HelpSucceed from './HelpSucceed';
import SubscribeNewsletter from '../../components/homepage/subscribe-newsletter';
import CampaignWorks from './CampaignWorks';

const RaisePage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(getEducation());
    }, []);
  return (
    <>
       
        <BannerRaise />
        <HowDoesItWork />
        <RaisingFunds />
        <FundraiserWalkthrough />
        <Blues />
        <CampaignWorks />
        <HelpSucceed />
        <SubscribeNewsletter />
       
    </>
  )
}

export default RaisePage